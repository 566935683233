const pageNumberToI18nKey = (pageNumber, pages) => {
  switch (pageNumber) {
    case 1:
      return 'personalInformation';
    case 2:
      return 'passportInformation';
    case 3:
      return 'visaInformation';
    case 4:
      return 'familyMembersInformation';
    case 5:
      return 'hostInformation';
    case 6:
      return 'addressAndContact';
    case 7:
      return pages.length === 9 ? 'employmentInformation': 'uploadDocuments';
    case 8:
      return 'fillOfApplication';
    case 9:
      return 'uploadDocuments';
  }
};

export default pageNumberToI18nKey;
