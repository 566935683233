<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section
      class="px-4"
      :class="{ 'py-32': isShowingMessage, 'py-12': !isShowingMessage }"
    >
      <article
        class="border border-gray-400 rounded-md p-4 md:p-8 flex flex-wrap items-start bg-white flex justify-center items-center"
      >
        <button
          :disabled="disabled"
          @click.prevent="downloadGeneratedVisa"
          class="w-32 button button-secondary"
        >
          {{ $t(`${$options.tPfx}.download`) }}
        </button>
      </article>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { Visa, client } from "@/api";
import downloadFile, { getFileNameFromHeaders } from "@/utils/downloadFile";

import { mapActions, mapGetters, mapState } from "vuex";
import { actions, module as flashModule } from "@/store/flash";
import { module as authModule } from "@/store/auth";

export const translationPrefix = "view.downloadVisa";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      download: "Download",
    },
    al: {
      download: "Shkarko",
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    visaUuid: {
      type: [String],
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  computed: {
    ...mapGetters(flashModule, ["isShowingMessage"]),
    ...mapGetters(authModule, ["isLoggedIn"]),
    ...mapState(authModule, ["user"]),
  },
  methods: {
    ...mapActions(flashModule, {
      dispatchDismissFlash: actions.dismiss,
      dispatchShowFlash: actions.show,
    }),
    async downloadGeneratedVisa() {
      try {
        this.disabled = true;
        const {
          data: temporarySignedUrlResponse,
        } = await Visa.getTemporarySignedUrl(this.visaUuid);

        let temporarySignedUrl =
          temporarySignedUrlResponse.data.temporarySignedUrl;
        temporarySignedUrl = temporarySignedUrl.replace('cms.', '');
        temporarySignedUrl = temporarySignedUrl.replace('http:', 'https:');

        const downloadFileResponse = await client.get(temporarySignedUrl, {
          responseType: "blob",
        });

        downloadFile(
          downloadFileResponse.data,
          getFileNameFromHeaders(downloadFileResponse.headers)
        );
      } catch (error) {
        this.$router.push({ name: "SomethingWentWrong" });
      } finally {
        this.disabled = false;
      }
    },
  },
};
</script>
