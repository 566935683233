<template>
  <tapered-section class="px-4">
    <section class="flex flex-wrap pb-8" id="visa-types">
      <loader
        v-if="isLoadingCategoryGroups"
        :loading="isLoadingCategoryGroups"
        size="1.5rem"
        margin="4px"
      />
      <div v-else>
        <div class="w-full flex flex-wrap">
          <header class="w-full text-center my-4">
            <h2>
              {{ $t(`${$options.tPfx}.selectVisaCategoryGroup`) }}
            </h2>
          </header>
          <visa-category-group
              hideRemoveButton
              class="mx-auto lg:ml-8 xl:ml-12 category-c"
              @click="selectOrElectronicVisa(true)"
              :isSelected="isElectronic"
              :title="$t(`${$options.tPfx}.electronicVisa`)"
              :subtitle="$t(`${$options.tPfx}.electronicVisaSubtitle`)"
              :description="$t(`${$options.tPfx}.electronicVisaDescription`)"
          />
          <visa-category-group
              hideRemoveButton
              class="mx-auto lg:mr-8 xl:mr-12 category-d"
              @click="selectOrElectronicVisa(false)"
              :isSelected="!isElectronic"
              :title="$t(`${$options.tPfx}.stampedVisa`)"
              :subtitle="$t(`${$options.tPfx}.stampedVisaSubtitle`)"
              :description="$t(`${$options.tPfx}.stampedVisaDescription`)"
          />
        </div>
        <div class="w-full flex flex-wrap">
          <header class="w-full text-center my-4">
            <h2>
              {{ $t(`${$options.tPfx}.selectVisaCategoryGroup`) }}
            </h2>
          </header>
          <visa-category-group
              class="mx-auto lg:ml-8 xl:ml-12 category-a"
              @click="selectOrDeselectCategoryGroup('A')"
              :isSelected="aCategoryIsSelected"
              :title="$t(`${$options.tPfx}.typeATitle`)"
              :subtitle="$t(`${$options.tPfx}.typeASubtitle`)"
              :description="$t(`${$options.tPfx}.typeADescription`)"
              :info="$t(`${$options.tPfx}.typeAInfo`)"
          />
          <visa-category-group
              class="mx-auto lg:ml-8 xl:mr-12 category-c"
              @click="selectOrDeselectCategoryGroup('C')"
              :isSelected="cCategoryIsSelected"
              :title="$t(`${$options.tPfx}.typeCTitle`)"
              :subtitle="$t(`${$options.tPfx}.typeCSubtitle`)"
              :description="$t(`${$options.tPfx}.typeCDescription`)"
              :info="$t(`${$options.tPfx}.typeCInfo`)"
          />
          <visa-category-group
              class="mx-auto xl:ml-12 w-full category-d"
              @click="selectOrDeselectCategoryGroup('D')"
              :isSelected="dCategoryIsSelected"
              :title="$t(`${$options.tPfx}.typeDTitle`)"
              :subtitle="$t(`${$options.tPfx}.typeDSubtitle`)"
              :description="$t(`${$options.tPfx}.typeDDescription`)"
          />
        </div>
      </div>
    </section>
    <section
      class="flex flex-wrap mx-auto lg:mx-8 xl:mx-12"
      id="visa-type-list"
    >
      <header class="w-full text-center my-4">
        <h2 v-if="hasSelectedCategory">
          {{
            $t(`${$options.tPfx}.browseVisasOfCategory`, {
              selectedCategoryGroup,
            })
          }}
        </h2>
        <h2
          v-else-if="!isLoadingCategoryGroups"
          class="border-t-4 border-b-4 border-dashed border-secondary-500 text-secondary-500"
        >
          {{ $t(`${$options.tPfx}.pleaseSelecteCategory`) }}
        </h2>
      </header>
      <visa-types v-if="hasSelectedCategory" :visa-types="visaTypes" :is-electronic="isElectronic" />
    </section>
  </tapered-section>
</template>

<script>
import { VisaType as VisaTypeApi } from "@/api";
import VisaCategoryGroup from "./Visas/VisaCategoryGroup.vue";
import VisaTypes from "./Visas/VisaTypes.vue";

export const translationPfx = "view.apply.visas";
export const translations = {
  pfx: translationPfx,
  t: {
    en: {
      selectVisaCategoryGroup: "Select the desired visa type",
      typeATitle: "Visa Type “A”",
      typeASubtitle: "Airport transit visa",
      typeADescription:
          "It is issued in the form of a stamp visa or printed in electronic format, which gives to the holder the right to enter and stay in the international area of an airport circulation until necessary for the next flight to the country of destination. The validity of this visa corresponds to the time period and flight ticket reservation the visa applicant foresees for the transiting.",
      typeAInfo:
          "Type “A” visa may be issued with one, two or multiple entry.",
      typeCTitle: "Visa Type “C”",
      typeCSubtitle: "Short stay visa",
      typeCDescription:
        "It is issued in the form of a stamp visa or printed in electronic format, which entitles its holder to enter and stay in the Republic of Albania for up to 90 days within 180 days, starting from the date of first entry.",
      typeCInfo:
          "Type “C” visa can be issued with one, two or more entries.",
      typeDTitle: "Visa Type “D”",
      typeDSubtitle: "Long stay visa",
      typeDDescription:
        "It is issued in the form of a stamp visa or printed in electronic form for foreign citizens who intend to stay in the Republic of Albania for more than 90 days within 180 days, and when, to obtain a residence permit, these citizens are required to have a visa.",
      pleaseSelecteCategory:
        "Please select visa type to browse available visas",
      browseVisasOfCategory: "Browse type {selectedCategoryGroup} visas",
      electronicVisa: "Electronic Visa",
      electronicVisaSubtitle: "Electronic Visa",
      electronicVisaDescription: "It is an authorization printed in electronic form, generated with an electronic stamp and sent to the applicant by email, without the need to physically appear at the Diplomatic / Consular Representations of the Republic of Albania.",
      electronicVisaInfo: "Upon approval, the visa is generated , digitally signed and sent to the applicant via email. There is no need to present yourself to the consulate/embassy to receive the visa.\n",
      stampedVisa: "Stamped Visa",
      stampedVisaSubtitle: "Stamped Visa",
      stampedVisaDescription: "It is the authorization in the form of a stamp visa. In case of visa approval, the applicant physically presents for the withdrawal of the visa at the Diplomatic / Consular Representations of the Republic of Albania.",
      stampedVisaInfo: "Upon approval the applicant should present himself to the consulate /embassy where he did the application in order to receive the visa.",
    },
    al: {
      selectVisaCategoryGroup: "Zgjidhni llojin e dëshiruar të vizës",
      typeATitle: "Visa Type “A”",
      typeASubtitle: "Viza tranzit në aeroport",
      typeADescription:
          "Lëshohet në formën e një vize pullë ose e printuar në format elektronik, e cila i jep të drejtën mbajtësit të hyjë dhe të qëndrojë në zonën për qarkullim ndërkombëtar të aeroportit deri   në   nisjen   e   fluturimit   për   në   vendin   e destinacionit.  Vlefshmëria  e  kësaj  vize  jepet  në përputhje  me  kohën  dhe  rezervimet  e  biletave  për udhëtimin/et, në të cilin/at aplikuesi parashikon të kalojë transit.",
      typeAInfo:
          "Viza e tipit “A” lëshohet me një, dy ose shumë hyrje-dalje.",
      typeCTitle: "Viza e tipit “C”",
      typeCSubtitle: "Qëndrim afatshkurtër",
      typeCDescription:
        "Lëshohet në formën e një vize pullë ose e printuar në format elektronik, e cila i jep të drejtën mbajtësit të saj të hyjë dhe të qëndrojë në Republikën e Shqipërisë deri në 90 ditë brenda 180 ditëve, duke filluar nga data e hyrjes së parë.",
      typeCInfo:
        "Viza e tipit “C” mund të lëshohet me një, dy ose shumë hyrje.",
      typeDTitle: "Viza e tipit “D”",
      typeDSubtitle: "Qëndrim afatgjatë",
      typeDDescription:
        "Lëshohet në formën e një vize pullë ose e printuar në format elektronik për shtetasit e huaj që synojnë të qëndrojnë në Republikën e Shqipërisë më shumë se 90 ditë brenda 180 ditëve, dhe kur, për t’u pajisur me leje qëndrimi, për këta shtetas kërkohet vizë.",
      pleaseSelecteCategory:
        "Ju lutem zgjidhni llojin e vizës që të shfletoni vizat e vlefshme",
      browseVisasOfCategory: "Shfletoni visat e tipit {selectedCategoryGroup}",
      electronicVisa: "Viza Elektronike",
      electronicVisaSubtitle: "Viza Elektronike",
      electronicVisaDescription: "Është autorizim i printuar nga format elektronik, gjenerohet me vulë elektronike dhe aplikantit i dërgohet në email, pa patur nevojë te paraqitet fizikisht në Përfaqësitë diplomatike/konsullore të RSh-së.",
      electronicVisaInfo: "Në rast miratimi, viza gjenerohet me vulë elektronike dhe aplikantit i dërgohet në email, pa patur nevojë te paraqitet ne përfaqësi",
      stampedVisa: "Viza Pullë",
      stampedVisaSubtitle: "Viza Pullë",
      stampedVisaDescription: "Është autorizimi në formën e një vize pullë. Në rast miratimi të vizës, aplikanti paraqitet fizikisht për tërheqjen e vizës në Përfaqësitë diplomatike/konsullore të RSh-së.",
      stampedVisaInfo: "Në rast miratimi të vizës, qytetari paraqitet personalisht për tërheqjen e vizës pranë përfaqësisë.",
    },
  },
};

export default {
  tPfx: translationPfx,
  components: { VisaCategoryGroup, VisaTypes },
  data: () => ({
    isLoadingCategoryGroups: false,
    categoryGroups: {
      A: [],
      C: [],
      D: [],
    },
    selectedCategoryGroup: null,
    isElectronic: true,
  }),
  computed: {
    hasSelectedCategory() {
      return (
        this.selectedCategoryGroup !== null &&
        this.selectedCategoryGroup !== undefined
      );
    },
    aCategoryIsSelected() {
      return this.selectedCategoryGroup === "A";
    },
    cCategoryIsSelected() {
      return this.selectedCategoryGroup === "C";
    },
    dCategoryIsSelected() {
      return this.selectedCategoryGroup === "D";
    },
    visaTypes() {
      if (!this.hasSelectedCategory) {
        return [];
      }

      return this.categoryGroups[this.selectedCategoryGroup];
    },
  },
  methods: {
    async loadCategoryGroups() {
      this.isLoadingCategoryGroups = true;

      const { data: visaTypeResponse } = await VisaTypeApi.list();

      visaTypeResponse.data.visaTypes.forEach((visaType) => {
        if (visaType.categoryGroup === "C") {
          this.categoryGroups.C.push(visaType);
        } else if (visaType.categoryGroup === "D") {
          this.categoryGroups.D.push(visaType);
        } else if (visaType.categoryGroup === "A") {
          this.categoryGroups.A.push(visaType)
        }
      });

      this.isLoadingCategoryGroups = false;
    },
    selectOrDeselectCategoryGroup(group) {
      if (this.selectedCategoryGroup === group) {
        this.selectedCategoryGroup = null;
        return;
      }

      this.selectedCategoryGroup = group;
      this.$nextTick(() => {
        this.$scrollTo("#visa-type-list", 300);
      });
    },
    selectOrElectronicVisa(state) {
      this.isElectronic = state
    }
  },
  mounted() {
    this.loadCategoryGroups();
  },
};
</script>

<style>
@media screen and (min-width: 59rem) and (max-width: 64rem) {
  .category-a {
    margin-left: 0px !important;
  }
  .category-c {
    margin-left: 0px !important;
  }
  .category-d {
    margin-left: 0px !important;
  }
}
</style>
