const openLinkInNewPage = (url) => {
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('target', '_blank');
  link.click();

  link.remove();
}

export default openLinkInNewPage;
