<template>
  <div v-click-outside="hideMenu" class="ml-3 relative">
    <div class="flex items-center">
      <button
        class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
        id="user-menu"
        aria-label="User menu"
        aria-haspopup="true"
        @click="showProfileMenu = !showProfileMenu"
      >
        <img
          v-if="hasThumbnail"
          class="h-8 w-8 rounded-full"
          :src="user.photoThumbUrl"
          alt="thumbnail"
        />
        <default-thumbnail v-else class="text-gray-400" />
      </button>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="showProfileMenu"
        class="origin-top-right absolute z-20 right-0 mt-2 w-64 rounded-md shadow-lg"
      >
        <section
          class="py-1 rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <header class="block px-4 py-2 text-sm text-gray-700 border-b">
            <div class="bg-white">
              <div class="text-base font-medium leading-none">
                {{ fullName }}
              </div>
              <div class="mt-1 text-sm font-medium leading-none text-gray-500">
                {{ email }}
              </div>
            </div>
          </header>
          <router-link
            :to="{ name: 'Profile' }"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            role="menuitem"
          >
            {{ $t(`${$options.tPfx}.profile`) }}
          </router-link>
          <router-link
            :to="{ name: 'Applications' }"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            role="menuitem"
          >
            {{ $t(`${$options.tPfx}.applications`) }}
          </router-link>
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            role="menuitem"
            @click.prevent="$emit('sign-out')"
          >
            {{ $t(`${$options.tPfx}.signOut`) }}
          </a>
        </section>
      </div>
    </transition>
  </div>
</template>

<script>
import DefaultThumbnail from "./DefaultThumbnail.vue";

export const translationPrefix =
  "layout.default.navbar.userProfile.dropdownUserProfile";

export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      loading: "Loading...",
      profile: "My profile",
      applications: "Applications",
      signOut: "Sign out",
    },
    al: {
      loading: "Duku u ngarkuar...",
      profile: "Profili im",
      applications: "Aplikimet",
      signOut: "Dil",
    },
  },
};

export default {
  components: { DefaultThumbnail },
  tPfx: translationPrefix,
  props: {
    user: {
      type: [Object, null],
    },
  },
  data: () => ({
    showProfileMenu: false,
  }),
  computed: {
    hasThumbnail() {
      return this.user && this.user.photoThumbUrl;
    },
    email() {
      if (!this.user) {
        return this.$t(`${this.$options.tPfx}.loading`);
      }

      return this.user.email;
    },
    fullName() {
      if (!this.user) {
        return this.$t(`${this.$options.tPfx}.loading`);
      }

      if (this.user.middleName) {
        return `${this.user.firstName} ${this.user.middleName} ${this.user.lastName}`;
      }

      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
  methods: {
    hideMenu() {
      this.showProfileMenu = false;
    },
  },
};
</script>
