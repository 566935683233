<template functional>
  <div
    class="xl:mx-12 my-4 border rounded-md border-gray-400 w-full p-8 bg-white"
  >
    <header class="mb-4">
      <h2>
        {{ parent.$t(`${$options.tPfx}.notes`) }}
      </h2>
    </header>
    <ol class="list-outside list-decimal pl-6">
      <li class="mb-1">
        <i18n :path="`${$options.tPfx}.daysOfTheIntendedDateOfTravelInformation`">
          <template #daysOfTheIntendedDate>
            <strong>{{ parent.$t(`${$options.tPfx}.daysOfTheIntendedDate`) }}</strong>
          </template>
        </i18n>
      </li>
      <li class="mb-1">
      <i18n :path="`${$options.tPfx}.noRefund`">
        <template #noRefundOrReimbursed>
          <strong class="text-secondary-500">{{ parent.$t(`${$options.tPfx}.noRefundOrReimbursed`) }}</strong>
        </template>
      </i18n>
      </li>
      <li class="mb-1">
        {{ parent.$t(`${$options.tPfx}.documentationFromTheCountryOfOrigin`) }}
      </li>
      <li class="mb-1">
        {{ parent.$t(`${$options.tPfx}.entryNotGuaranteed`) }}
      </li>
      <li class="mb-1">
        <i18n :path="`${$options.tPfx}.outsideOfAlbanian`">
          <template #outsideOfTerritory>
            <strong class="text-secondary-500">{{ parent.$t(`${$options.tPfx}.outsideOfTerritory`) }}</strong>
          </template>
        </i18n>
      </li>
      <li class="mb-1">
          <i18n :path="`${$options.tPfx}.inviterDetails`">
              <template #invitationFormat>
                  <strong> <a href="/documents/format-ftese.docx">{{ parent.$t(`${$options.tPfx}.invitationFormat`) }} </a></strong>
              </template>
          </i18n>
      </li>
      <li class="mb-1">
        <i18n :path="`${$options.tPfx}.duringVerificationProcessRule`">
          <template #forbiddenCommunication>
            <strong class="text-secondary-500">{{ parent.$t(`${$options.tPfx}.forbiddenCommunication`) }}</strong>
          </template>
          <template #contactedByThem>
            <strong>{{ parent.$t(`${$options.tPfx}.contactedByThem`) }}</strong>
          </template>
        </i18n>
      </li>
      <li class="mb-1">
        {{ parent.$t(`${$options.tPfx}.durationAndDeadline`) }}
      </li>
      <li class="mb-1">
        <i18n :path="`${$options.tPfx}.exceedingTheFifteenDayPaymentPeriod`">
          <template #automaticallyRejected>
            <strong class="text-secondary-500">{{ parent.$t(`${$options.tPfx}.automaticallyRejected`) }}</strong>
          </template>
          <template #notPaid>
            <strong>{{ parent.$t(`${$options.tPfx}.notPaid`) }}</strong>
          </template>
        </i18n>
      </li>
      <li class="mb-1">
        {{ parent.$t(`${$options.tPfx}.forChildren`) }}
      </li>
      <li class="mb-1">
          {{ parent.$t(`${$options.tPfx}.reApplyRule`) }}
          <p class="card-for-email">
              <i class="text-secondary-500">*</i>
              {{ parent.$t(`${$options.tPfx}.appealForTheDecision`) }}
              <br>
              <strong> <a class="email-text" href ="mailto: visa@mfa.gov.al"> {{ parent.$t(`${$options.tPfx}.email`) }} </a> </strong>
              <br>
              <strong> {{ parent.$t(`${$options.tPfx}.responsibleAuthority`) }} </strong>
              <br>
              {{ parent.$t(`${$options.tPfx}.directorateOfConsular`) }}
          </p>
      </li>
      <li class="mb-1">
        {{ parent.$t(`${$options.tPfx}.travelDocuments`) }}
      </li>
    </ol>
  </div>
</template>

<script>
export const translationPrefix = "view.apply.processPhases.notes";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      notes: "Additional notes.",
      daysOfTheIntendedDateOfTravelInformation:
        "If you wish to travel to the Republic of Albania and are required to apply for a visa, {daysOfTheIntendedDate} . The application will be refused if your intended date of travel is longer than 90 days from the date of your visa application.",
      daysOfTheIntendedDate:
        "the application must be made within 90 days of the intended date of travel",
      durationAndDeadline:
        "The deadline for evaluating applications usually takes 15 working days from the day when the payment for the visa is made. For exceptional cases, the verification process can take up to 30 working days.",
      exceedingTheFifteenDayPaymentPeriod:
          "In  case  of  non-payment  of  the  visa  fee  within  15  days,  the  application  will  change  to “{notPaid}” status and will be {automaticallyRejected}.",
      notPaid:
        "Not paid",
      automaticallyRejected:
        "automatically rejected",
      appealForTheDecision:
          "Within 5 working days from the receipt of the decision, the interested foreigner has the right to appeal against the decision of refusal / annulment of the visa with the   relevant   arguments   and   evidence,   as   provided   for   in   Law   79/2021   \"On Foreigners\", only at the following email address:",
      email:
        "visa@mfa.gov.al",
      responsibleAuthority:
        "The only authority responsible for reviewing requests for appealing decisions is: ",
      directorateOfConsular:
        "The Directorate for Consular Affairs at the Ministry for Europe and Foreign Affairs",
      documentationFromTheCountryOfOrigin:
          "The documentation from the country of origin and/or from the country where the foreigner had a legal stay must be translated into the Albanian or English language, certified and legalized based on the obligations arising from international agreements.",
      duringVerificationProcessRule:
        "During  the  visa  application  verification  process,  it  is {forbiddenCommunication}, {contactedByThem}.",
      forbiddenCommunication:
        "strictly  forbidden  for  the  applicant, the invitee, or any other subject to communicate with the responsible institutions",
      contactedByThem:
        "except when contacted by them",
      forChildren:
        "The  visa  application  for  children  under  18  years  old  is  made  by  one  of  the  parents,  legal guardian or the person that is the legal carer.",
      applyAfterRefusal:
        "After the rejection of the stamp visa and e-visa, the foreigner has the right to reapply only after he has closed the appeal procedures, in case he has exercised this right or, if three months have passed from the date of receipt of the refusal decision.",
      entryNotGuaranteed:
        "Obtaining a visa is not a guarantee that the foreigner is allowed to enter the Republic of Albania",
      vaccinationCards:
        "Foreigners coming from countries affected by the epidemic or disease, at the Border Crossing Point can be requested to provide the original international vaccination card.",
      reApplyRule:
          "After the rejection of the visa sticker or electronic visa application. The foreign citizen has the right to reapply only after all appeal procedures have been exhausted, or after three months have passed from the date of receipt of rejection.",
      travelDocuments:
        "Applicants with dual citizenship must select the nationality of the passport they will use for travel.  If  the  country  in  the  travel  document  registered  with  e-Visa  is  different  from  the document the person has during the trip, the e-Visa will be invalid. By travel document we define passport and identity card (for residents of specific countries). Other documents are not acceptable for an e-Visa application.",
      noRefund:
          "Referring to point 5 of article 28, based on law No. 79/2021 \"For foreigners\": The payment for the visa sticker or electronic visa is {noRefundOrReimbursed} of the application",
      noRefundOrReimbursed:
          "not refunded / reimbursed in cases of rejection",
      outsideOfAlbanian:
          " Visa application process is only accepted if it is completed online, and ensuring that the applicant is {outsideOfTerritory} after payment of the visa fee.",
      outsideOfTerritory:
        "outside the territory of the Republic of Albania",
      inviterDetails:
        "The host is an Albanian citizen or a foreigner citizen, resident in the Republic of Albania, or\n" +
          "legal person who exercises activities under Albanian legislation, who declares in writing\n" +
          "that he assumes responsibilities for staying and leaving the foreigner in/from the Republic\n" +
          "of Albania, according to the legal provisions in force. {invitationFormat}",
      invitationFormat:
          "INVITATION FORMAT (download)",
    },
    al: {
      notes: "Shënime.",
      daysOfTheIntendedDateOfTravelInformation:
          "Aplikimi për vizë nga i huaji bëhet {daysOfTheIntendedDate} për të ardhur në Republikën e Shqipërisë.",
      daysOfTheIntendedDate:
          "jo më shumë se 90 ditë përpara datës së planifikuar",
      durationAndDeadline:
        "Afati i vlerësimit të kërkesës si rregull zgjat 15 ditë pune nga dita e kryerjes së pagesës për vizë. Për raste përjashtimore zgjat deri në 30 ditë pune.",
      exceedingTheFifteenDayPaymentPeriod:
        "Në rast të mos pagesës brenda 15 ditëve të tarifës së vizës, aplikimi do të kalojë në statusin “{notPaid}” dhe {automaticallyRejected}.",
      notPaid:
          "Nuk u pagua",
      automaticallyRejected:
          "do të refuzohet automatikisht",
      appealForTheDecision:
          "Brenda 5 ditë pune nga marrja e vendimit, i huaji i interesuar ka të drejtë të apelojë kundër vendimit të refuzimit/anulimit të vizës me argumentet dhe provat përkatëse, siç parashikohet në ligjin 79/2021 “Për të huajt”, vetëm në adresën elektronike (email): ",
      email:
          "visa@mfa.gov.al",
      responsibleAuthority:
          "Autoriteti i vetëm përgjegjës për shqyrtimin e kërkesave për apelimin e vendimeve është: ",
      directorateOfConsular:
          "Drejtoria e Çështjeve Konsullore pranë Ministrisë për Evropën dhe Punët e Jashtme",
      documentationFromTheCountryOfOrigin:
        "Dokumentacioni nga vendi i origjinës dhe/ose nga vendi ku i huaji ka pasur qëndrim të ligjshëm duhet të  jetë  i  përkthyer  në  shqip  ose anglisht,  i  vërtetuar  dhe  i  legalizuar  në  bazë  të  detyrimeve,  që rrjedhin nga marrëveshjet ndërkombëtare.",
      duringVerificationProcessRule:
          "Gjatë  procesit  të  verifikimit  të  aplikimit  për  vizë  {forbiddenCommunication} {contactedByThem}",
      forbiddenCommunication:
          "është rreptësisht i ndaluar komunikimi i aplikuesit, ftuesit, apo çdo subjekti tjetër me strukturat përgjegjëse",
      contactedByThem:
          "përveç rasteve kur kontaktoheni prej tyre",
      forChildren:
        "Aplikimi i vizës për fëmijët nën 18 vjeç bëhet nga njëri prind, ose kujdestari ligjor ose personi që e ka në ngarkim.",
      applyAfterRefusal:
        "Pas refuzimit të vizës pullë dhe elektronike, i huaji ka të drejtë të riaplikojë vetëm pasi të ketë mbyllur procedurat e ankimit, në rast se e ka ushtruar këtë të drejtë ose, në të kundërt, pasi të kenë kaluar tre muaj nga data e marrjes së vendimit për refuzim.",
      entryNotGuaranteed:
        "Marrja e vizës nuk është garanci që të huajit i lejohet hyrja në Republikën e Shqipërisë.",
      vaccinationCards:
        "Të huajit që vjen nga vende të prekura nga epidemi ose sëmundje, në Pikën e Kalimit Kufitar, i kërkohet kartela origjinale e vaksinimit ndërkombëtar.",
      reApplyRule:
        "Pas refuzimit të vizës pullë dhe elektronike, i huaji ka të drejtë të riaplikojë vetëm pasi të ketë mbyllur procedurat e ankimimit, në rast se e ka ushtruar këtë të drejtë ose, në të kundërt, pasi të kenë kaluar tre muaj nga data e marrjes së vendimit për refuzim.",
      travelDocuments:
        "Aplikantët me dyshtetësi duhet të zgjedhin nacionalitetin e pasaportës që do të përdorin në udhëtim. Në qoftë se shteti në dokumentin e udhëtimit të regjistruar tek e-Visa është i ndryshëm nga dokumenti që personi ka gjatë udhëtimit, e-Visa do të jetë e pavlefshme. Me dokument udhëtimi përcaktojmë, pasaportën dhe kartën e identitetit (për banorë të vendeve specifike). Dokumente të tjerë nuk janë të pranueshëm për një aplikim e-Visa.",
      noRefund:
        "Referuar pikës 5, neni 28, ligji 79/2021 “Për të huajt”: Pagesa për vizën pullë ose elektronike {noRefundOrReimbursed} të aplikimit.",
      noRefundOrReimbursed:
        "nuk kthehet / rimbursohet në rastet e refuzimit",
      outsideOfAlbanian:
        " Procesi  i  aplikimit  për  vizë  kryhet  vetëm  online  dhe  duke  u  siguruar  që  aplikanti  është {outsideOfTerritory} në momentin e pagesës për tarifën e vizës.",
      outsideOfTerritory:
          "jashtë territorit të Republikës së Shqipërisë",
      inviterDetails:
        "Ftues është shtetasi shqiptar ose i huaji, banor në Republikën e Shqipërisë, ose personi\n" +
          "juridik shqiptar apo i huaj që ushtron veprimtari sipas legjislacionit shqiptar, i cili deklaron\n" +
          "me shkrim se merr përsipër përgjegjësitë për qëndrimit dhe largimin e të huajit në/nga\n" +
          "Republika e Shqipërisë, sipas dispozitave ligjore në fuqi. {invitationFormat}",
      invitationFormat:
          "FORMATI I FTESËS (shkarko)",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>