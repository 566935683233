var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-fill-height',{staticClass:"bg-gray-200"},[_c('tapered-section',{staticClass:"px-4 py-12"},[(_vm.isLoadingApplications)?_c('loader',{attrs:{"loading":_vm.isLoadingApplications,"size":"1.5rem","margin":"4px"}}):_vm._e(),(!_vm.isLoadingApplications && !_vm.applications.length)?_c('article',{staticClass:"border border-gray-400 rounded-md p-4 md:p-8 bg-white"},[_c('div',[_vm._v(_vm._s(_vm.$t(((_vm.$options.tPfx) + ".noApplications"))))])]):(!_vm.isLoadingApplications && _vm.applications.length)?_c('article',{staticClass:"border border-gray-400 rounded-md p-2 sm:p-4 md:p-8 bg-white"},[_c('t-table',{attrs:{"headers":[
          { text: _vm.$t(((_vm.$options.tPfx) + ".trackingNumber")) },
          { text: _vm.$t(((_vm.$options.tPfx) + ".visaType")) },
          { text: _vm.$t(((_vm.$options.tPfx) + ".status")) },
          { text: _vm.$t(((_vm.$options.tPfx) + ".actions")), className: 'text-center' } ],"data":_vm.applications,"responsive":true,"responsive-breakpoint":768},scopedSlots:_vm._u([{key:"tbody",fn:function(ref){
        var tbodyClass = ref.tbodyClass;
        var trClass = ref.trClass;
        var tdClass = ref.tdClass;
        var renderResponsive = ref.renderResponsive;
        var data = ref.data;
return [(renderResponsive)?[_vm._l((data),function(row){return _c('tbody',{key:row.id,class:tbodyClass,attrs:{"id":("application-" + (row.trackingNumber))}},[_c('tr',{class:trClass},[_c('th',{staticClass:"text-sm font-semibold text-gray-600 uppercase"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".trackingNumber")))+" ")]),_c('td',{class:[tdClass, 'relative']},[_c('router-link',{staticClass:"text-secondary-500 hover:underline",attrs:{"to":{
                      name: 'Questionary',
                      params: { applicationId: row.id },
                    }}},[_vm._v(" "+_vm._s(row.trackingNumber)+" ")])],1),_c('td',{staticClass:"p-2",attrs:{"rowspan":"3","colspan":"2"}},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"mb-2 text-sm font-semibold text-gray-600 uppercase"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".actions")))+" ")]),_c('application-actions',{staticClass:"mx-auto",attrs:{"application":row}})],1)])]),_c('tr',{class:trClass},[_c('th',{staticClass:"text-sm font-semibold text-gray-600 uppercase"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".visaType")))+" ")]),_c('td',{class:[tdClass, 'td-overflow']},[_vm._v(" "+_vm._s(row.visaType.title[_vm.$i18n.locale])+" ")])]),_c('tr',{class:trClass},[_c('th',{staticClass:"p-2 text-sm font-semibold text-gray-600 uppercase"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".status")))+" ")]),_c('td',{staticClass:"px-3 py-4"},[_c('span',{staticClass:"rounded-full py-2 px-4",class:_vm.statusToTailwindClasses(
                        row.status.code,
                        row.status.name[_vm.$i18n.locale]
                      )},[_vm._v(" "+_vm._s(row.status.name[_vm.$i18n.locale].replace("/", " / "))+" ")])])])])}),_c('tfoot',{staticClass:"h-4"})]:_vm._e()]}},{key:"row",fn:function(ref){
                      var trClass = ref.trClass;
                      var tdClass = ref.tdClass;
                      var row = ref.row;
return [_c('tr',{class:trClass,attrs:{"id":("application-" + (row.trackingNumber))}},[_c('td',{class:tdClass},[_c('router-link',{staticClass:"text-secondary-500 hover:underline",attrs:{"to":{
                  name: 'Questionary',
                  params: { applicationId: row.id },
                }}},[_vm._v(" "+_vm._s(row.trackingNumber)+" ")])],1),_c('td',{class:tdClass},[_vm._v(" "+_vm._s(row.visaType.title[_vm.$i18n.locale])+" ")]),_c('td',{class:tdClass},[_c('span',{staticClass:"rounded-full whitespace-no-wrap",class:_vm.statusToTailwindClasses(
                    row.status.code,
                    row.status.name[_vm.$i18n.locale]
                  )},[_vm._v(" "+_vm._s(row.status.name[_vm.$i18n.locale].replace("/", " / "))+" ")])]),_c('td',{staticClass:"p-2"},[_c('application-actions',{attrs:{"application":row}})],1)])]}}])})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }