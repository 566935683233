<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section class="px-4 py-32">
      <article
          class="border border-gray-400 rounded-md p-2 sm:p-4 md:p-8 bg-white"
      >
        <form @submit.prevent="submit" class="mt-8 space-y-6">
          <section>
            <header class="mb-4">
              <h3>
                {{ $t(`${$options.tPfx}.personalInformation`) }}
              </h3>
            </header>
            <div class="flex flex-wrap">
              <text-input
                  v-model="form.firstName"
                  @input="clearErrors('firstName')"
                  :label="$t(`${$options.tPfx}.firstName`)"
                  :placeholder="$t(`${$options.tPfx}.firstName`)"
                  :error="
                  errors.firstName && errors.firstName.length
                    ? errors.firstName[0]
                    : null
                "
                  id="first-name"
                  name="first-name"
                  required
                  class="mb-4 w-full lg:w-1/3 lg:pr-2"
              />
              <text-input
                  v-model="form.middleName"
                  @input="clearErrors('middleName')"
                  :label="$t(`${$options.tPfx}.middleName`)"
                  :placeholder="$t(`${$options.tPfx}.middleName`)"
                  :error="
                  errors.middleName && errors.middleName.length
                    ? errors.middleName[0]
                    : null
                "
                  id="middle-name"
                  name="middle-name"
                  class="mb-4 w-full lg:w-1/3 lg:px-1"
              />
              <text-input
                  v-model="form.lastName"
                  @input="clearErrors('lastName')"
                  :label="$t(`${$options.tPfx}.lastName`)"
                  :placeholder="$t(`${$options.tPfx}.lastName`)"
                  :error="
                  errors.lastName && errors.lastName.length
                    ? errors.lastName[0]
                    : null
                "
                  id="last-name"
                  name="last-name"
                  required
                  class="mb-4 w-full lg:w-1/3 lg:pl-2"
              />
            </div>
            <div>
              <select-input
                  id="nationality"
                  name="nationality"
                  type="text"
                  v-model="form.nationality"
                  @input="clearErrors('nationalityId')"
                  @search="loadNationalities"
                  :loading="isLoadingNationalityOptions"
                  :options="nationalityOptions"
                  :get-option-label="(option) => option.name[$i18n.locale]"
                  :error="
                  errors.nationalityId && errors.nationalityId.length
                    ? errors.nationalityId[0]
                    : null
                "
                  :label="$t(`${$options.tPfx}.nationality`)"
                  :placeholder="$t(`${$options.tPfx}.nationality`)"
                  required
              >
                <template #no-options="{ search }">
                  <i18n :path="`${$options.tPfx}.noMatchingNationalities`">
                    <template #search>
                      <em>"{{ search }}"</em>
                    </template>
                  </i18n>
                </template>
              </select-input>
            </div>
          </section>
          <section>
            <header class="mb-4">
              <h3>
                {{ $t(`${$options.tPfx}.loginInformation`) }}
              </h3>
            </header>
            <text-input
                v-model="form.email"
                @input="clearErrors('email')"
                :label="$t(`${$options.tPfx}.email`)"
                :placeholder="$t(`${$options.tPfx}.email`)"
                :error="
                errors.email && errors.email.length ? errors.email[0] : null
              "
                id="email"
                name="email"
                type="email"
                class="mb-4 w-full"
                required
            />
            <div class="flex flex-wrap">
              <text-input
                  v-model="form.password"
                  @input="clearErrors('password')"
                  :label="$t(`${$options.tPfx}.password`)"
                  :placeholder="$t(`${$options.tPfx}.password`)"
                  :error="
                  errors.password && errors.password.length
                    ? errors.password[0]
                    : null
                "
                  id="password"
                  name="password"
                  type="password"
                  class="mb-4 w-full lg:w-1/2 lg:pr-2"
              />
              <text-input
                  v-model="form.passwordConfirmation"
                  @input="clearErrors('passwordConfirmation')"
                  :label="$t(`${$options.tPfx}.passwordConfirmation`)"
                  :placeholder="$t(`${$options.tPfx}.passwordConfirmation`)"
                  :error="
                  errors.passwordConfirmation &&
                  errors.passwordConfirmation.length
                    ? errors.passwordConfirmation[0]
                    : null
                "
                  id="password-confirmation"
                  name="password-confirmation"
                  type="password"
                  class="mb-4 w-full lg:w-1/2 lg:pl-2"
              />
            </div>
          </section>
          <section>
            <div class="mt-6">
              <button class="w-full button button-secondary">
                {{ $t(`${$options.tPfx}.save`) }}
              </button>
            </div>
          </section>
        </form>
      </article>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import pusherAuthMixin from "@/mixins/pusherAuthMixin";
import {mapActions} from "vuex";
import {actions as flashActions, module as flashModule} from "@/store/flash";
import {debounce} from "lodash";
import {Country, User} from "@/api";

export const translationPrefix = "view.profile";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      personalInformation: "Personal Information",
      firstName: "First name",
      middleName: "Middle name",
      lastName: "Last name",
      nationality: "Nationality",
      noMatchingNationalities: "No matches for {search}",
      loginInformation: "Login Information",
      email: "Email",
      password: "Password",
      passwordConfirmation: "Repeat password",
      allowSubmit:
          "I allow the website to collect and store the data I submit through this form.",
      save: "Save",
      updateSuccessLine1: "Profile was updated successfully.",
    },
    al: {
      personalInformation: "Informacioni Personal",
      firstName: "Emri",
      middleName: "Emri i mesit",
      lastName: "Mbiemri",
      nationality: "Nacionaliteti",
      noMatchingNationalities: "Asnjë rezultat për {search}",
      loginInformation: "Informacion i kyçjes",
      email: "Email",
      password: "Fjalëkalimi",
      passwordConfirmation: "Përsërit fjalëkalimin",
      allowSubmit:
          "Unë lejoj që faqja web të mbledhë dhe të ruajë informacionin që unë dërgoj në këtë formular.",
      save: "Ruaj",
      updateSuccessLine1: "Profili u përditësua.",
    },
  },
};

export default {
  tPfx: translationPrefix,
  mixins: [pusherAuthMixin],
  data: () => ({
    form: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      nationality: null,
      password: "",
      passwordConfirmation: "",
    },
    isLoadingNationalityOptions: false,
    nationalityOptions: [],
    errors: {},
  }),
  methods: {
    ...mapActions(flashModule, {
      dispatchShowFlash: flashActions.show,
    }),
    loadNationalities(searchString) {
      this.isLoadingNationalityOptions = true;
      this.debouncedLoadNationalities(searchString);
    },
    debouncedLoadNationalities: debounce(async function (searchString) {
      const { data: countriesResponse } = await Country.list(searchString);

      this.isLoadingNationalityOptions = false;
      this.nationalityOptions = countriesResponse.data.countries;
    }, 300),
    clearErrors(field) {
      this.errors[field] = undefined;
    },
    async getData() {
      const { data: userResponse } = await User.auth();
      const user = userResponse.data.user;
      this.form = {
        firstName: user.firstName,
        middleName: user.middleName,
        lastName: user.lastName,
        email: user.email,
        nationality: user.nationality,
        password: "",
        passwordConfirmation: "",
      }
    },
    async submit() {
      const { nationality, ...profileDetails } = this.form;
      profileDetails.nationalityId = nationality ? nationality.id : null;

      try {
        await User.editProfile(profileDetails);
        await this.getData();
        await this.dispatchShowFlash({
          class:
              "sm:block md:inline-block border-green-500 bg-green-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
          iconClass: "hover:text-green-500",
          text: [
            `${this.$options.tPfx}.updateSuccessLine1`,
          ],
          textClass: ["avoid-warp", "avoid-wrap"],
        });
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }
    },
  },
  async mounted() {
    await this.getData()
    this.loadNationalities();
  },
};
</script>


<style>
</style>
