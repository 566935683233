<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section class="flex justify-center py-32 px-4">
      <article
        class="rounded-md border border-gray-400 p-12 max-w-lg lg:max-w-2xl w-full space-y-8 bg-white"
      >
        <header>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            <span class="title-line-1">
              {{ $t(`${$options.tPfx}.registerTitleLine1`) }}
            </span>
            <span class="title-line-2">
              {{ $t(`${$options.tPfx}.registerTitleLine2`) }}
            </span>
          </h2>
        </header>
        <form @submit.prevent="register" class="mt-8 space-y-6">
          <section>
            <header class="mb-4">
              <h3>
                {{ $t(`${$options.tPfx}.personalInformation`) }}
              </h3>
            </header>
            <div class="flex flex-wrap">
              <text-input
                v-model="form.firstName"
                @input="clearErrors('firstName')"
                :label="$t(`${$options.tPfx}.firstName`)"
                :placeholder="$t(`${$options.tPfx}.firstName`)"
                :error="
                  errors.firstName && errors.firstName.length
                    ? errors.firstName[0]
                    : null
                "
                id="first-name"
                name="first-name"
                required
                class="mb-4 w-full lg:w-1/3 lg:pr-2"
              />
              <text-input
                v-model="form.middleName"
                @input="clearErrors('middleName')"
                :label="$t(`${$options.tPfx}.middleName`)"
                :placeholder="$t(`${$options.tPfx}.middleName`)"
                :error="
                  errors.middleName && errors.middleName.length
                    ? errors.middleName[0]
                    : null
                "
                id="middle-name"
                name="middle-name"
                class="mb-4 w-full lg:w-1/3 lg:px-1"
              />
              <text-input
                v-model="form.lastName"
                @input="clearErrors('lastName')"
                :label="$t(`${$options.tPfx}.lastName`)"
                :placeholder="$t(`${$options.tPfx}.lastName`)"
                :error="
                  errors.lastName && errors.lastName.length
                    ? errors.lastName[0]
                    : null
                "
                id="last-name"
                name="last-name"
                required
                class="mb-4 w-full lg:w-1/3 lg:pl-2"
              />
            </div>
            <div>
              <select-input
                id="nationality"
                name="nationality"
                type="text"
                v-model="form.nationality"
                @input="clearErrors('nationalityId')"
                @search="loadNationalities"
                :loading="isLoadingNationalityOptions"
                :options="nationalityOptions"
                :get-option-label="(option) => option.name[$i18n.locale]"
                :error="
                  errors.nationalityId && errors.nationalityId.length
                    ? errors.nationalityId[0]
                    : null
                "
                :label="$t(`${$options.tPfx}.nationality`)"
                :placeholder="$t(`${$options.tPfx}.nationality`)"
                required
              >
                <template #no-options="{ search }">
                  <i18n :path="`${$options.tPfx}.noMatchingNationalities`">
                    <template #search>
                      <em>"{{ search }}"</em>
                    </template>
                  </i18n>
                </template>
              </select-input>
            </div>
          </section>
          <section>
            <header class="mb-4">
              <h3>
                {{ $t(`${$options.tPfx}.loginInformation`) }}
              </h3>
            </header>
            <text-input
              v-model="form.email"
              @input="clearErrors('email')"
              :label="$t(`${$options.tPfx}.email`)"
              :placeholder="$t(`${$options.tPfx}.email`)"
              :error="
                errors.email && errors.email.length ? errors.email[0] : null
              "
              id="email"
              name="email"
              type="email"
              class="mb-4 w-full"
              required
            />
            <div class="flex flex-wrap">
              <text-input
                v-model="form.password"
                @input="clearErrors('password')"
                :label="$t(`${$options.tPfx}.password`)"
                :placeholder="$t(`${$options.tPfx}.password`)"
                :error="
                  errors.password && errors.password.length
                    ? errors.password[0]
                    : null
                "
                id="password"
                name="password"
                type="password"
                class="mb-4 w-full lg:w-1/2 lg:pr-2"
                required
              />
              <text-input
                v-model="form.passwordConfirmation"
                @input="clearErrors('passwordConfirmation')"
                :label="$t(`${$options.tPfx}.passwordConfirmation`)"
                :placeholder="$t(`${$options.tPfx}.passwordConfirmation`)"
                :error="
                  errors.passwordConfirmation &&
                  errors.passwordConfirmation.length
                    ? errors.passwordConfirmation[0]
                    : null
                "
                id="password-confirmation"
                name="password-confirmation"
                type="password"
                class="mb-4 w-full lg:w-1/2 lg:pl-2"
                required
              />
            </div>
          </section>
          <section>
            <div class="flex items-center">
              <input
                v-model="form.allowSubmit"
                @input="clearErrors('allowSubmit')"
                id="allow-submit"
                name="allow-submit"
                type="checkbox"
                class="mr-2 md:mr-4 text-secondary-600 focus:ring-secondary-500 border-gray-400 rounded"
                required
              />
              <label
                for="allow-submit"
                class="ml-2 block text-sm text-gray-900"
              >
                {{ $t(`${$options.tPfx}.allowSubmit`) }}
              </label>
            </div>
            <div class="mt-6">
              <button class="w-full button button-secondary">
                {{ $t(`${$options.tPfx}.register`) }}
              </button>
            </div>
          </section>
        </form>
      </article>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { mapActions } from "vuex";

import { debounce } from "lodash";
import { Country, User } from "@/api";
import { sortByStringKeyAsc } from "@/utils/sort";
import { actions as flashActions, module as flashModule } from "@/store/flash";
import DefaultFillHeight from "@/components/DefaultFillHeight.vue";

export const translationPrefix = "view.register";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      registerTitleLine1: "Create your",
      registerTitleLine2: "e-Visa account",
      personalInformation: "Personal Information",
      firstName: "First name",
      middleName: "Middle name",
      lastName: "Last name",
      nationality: "Nationality",
      noMatchingNationalities: "No matches for {search}",
      loginInformation: "Login Information",
      email: "Email",
      password: "Password",
      passwordConfirmation: "Repeat password",
      allowSubmit:
        "I allow the website to collect and store the data I submit through this form.",
      register: "Register",
      registrationSuccessLine1: "Registration was successful.",
      registrationSuccessLine2: "Please log in!",
    },
    al: {
      registerTitleLine1: "Krijo",
      registerTitleLine2: "llogarinë tënde e-Visa",
      personalInformation: "Informacioni Personal",
      firstName: "Emri",
      middleName: "Emri i mesit",
      lastName: "Mbiemri",
      nationality: "Nacionaliteti",
      noMatchingNationalities: "Asnjë rezultat për {search}",
      loginInformation: "Informacion i kyçjes",
      email: "Email",
      password: "Fjalëkalimi",
      passwordConfirmation: "Përsërit fjalëkalimin",
      allowSubmit:
        "Unë lejoj që faqja web të mbledhë dhe të ruajë informacionin që unë dërgoj në këtë formular.",
      register: "Regjistrohu",
      registrationSuccessLine1: "Regjistrimi u krye me sukses!",
      registrationSuccessLine2: "Ju lutem kyçuni!",
    },
  },
};

export default {
  components: { DefaultFillHeight },
  tPfx: translationPrefix,
  data: () => ({
    form: {
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      nationality: null,
      password: "",
      passwordConfirmation: "",
      allowSubmit: "",
    },
    isLoadingNationalityOptions: false,
    nationalityOptions: [],
    errors: {},
  }),
  watch: {
    "$i18n.locale"() {
      const bylocalizedNameAsc = sortByStringKeyAsc(
        (nationality) => nationality.name[this.$i18n.locale]
      );

      this.nationalityOptions.sort(bylocalizedNameAsc);
    },
  },
  methods: {
    ...mapActions(flashModule, {
      dispatchShowFlash: flashActions.show,
    }),
    loadNationalities(searchString) {
      this.isLoadingNationalityOptions = true;
      this.debouncedLoadNationalities(searchString);
    },
    debouncedLoadNationalities: debounce(async function (searchString) {
      const { data: countriesResponse } = await Country.list(searchString);

      this.isLoadingNationalityOptions = false;
      this.nationalityOptions = countriesResponse.data.countries;
    }, 300),
    clearErrors(field) {
      this.errors[field] = undefined;
    },
    async register() {
      const { nationality, ...registrationDetails } = this.form;
      registrationDetails.nationalityId = nationality ? nationality.id : null;

      try {
        await User.register(registrationDetails);

        await this.$router.replace({ name: "Login" });

        await this.dispatchShowFlash({
          class:
            "sm:block md:inline-block border-green-500 bg-green-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
          iconClass: "hover:text-green-500",
          text: [
            `${this.$options.tPfx}.registrationSuccessLine1`,
            `${this.$options.tPfx}.registrationSuccessLine2`,
          ],
          textClass: ["avoid-warp", "avoid-wrap"],
        });
      } catch (error) {
        if (error.response.status === 422) {
          this.errors = error.response.data.errors;
        }
      }
    },
  },
  async mounted() {
    this.loadNationalities();
  },
};
</script>

<style scoped>
.title-line-1,
.title-line-2 {
  @apply inline-block;
}

@media only screen and (min-width: 521px) {
  .title-line-1 {
    @apply pr-1;
  }

  .title-line-2 {
    @apply pl-1;
  }
}
</style>
