import client from './client';
import tokenInterceptor from './interceptors/requestTokenInterceptor';
import localeInterceptor from './interceptors/requestLocaleInterceptor';

export default function setup() {
	// Add bearer token if user is authenticated
	client.interceptors.request.use(
		tokenInterceptor.onFulfilled,
		tokenInterceptor.onRejected,
	)

	// Add locale if request has no specified locale
	client.interceptors.request.use(
		localeInterceptor.onFulfilled,
		localeInterceptor.onRejected,
	);
}