<template functional>
  <div
    class="absolute top-0 right-0 bottom-0 left-0 z-5 flex justify-center items-center"
  >
    <font-awesome-icon
      icon="file-upload"
      size="3x"
      class="mr-2"
      :class="{
        'text-gray-400': !props.isDraggingFiles && !props.hasError,
        'text-yellow-500': !props.isDraggingFiles && props.hasError,
        'text-secondary-500': props.isDraggingFiles,
      }"
    />
    <span
      class="ml-2"
      :class="{
        'text-gray-400': !props.isDraggingFiles && !props.hasError,
        'text-yellow-500': !props.isDraggingFiles && props.hasError,
        'text-secondary-500': props.isDraggingFiles,
      }"
    >
      {{ props.hint }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    isDraggingFiles: [Boolean],
    hint: [String],
    hasError: [Boolean],
  },
};
</script>

<style>
</style>