<template>
  <section class="bg-secondary-500">
    <div class="max-w-screen-xl mx-auto py-4 flex flex-wrap">
      <header class="w-full text-center text-white my-4">
        <h1 v-html="$t(`${$options.tPfx}.3steps`)"></h1>
      </header>
      <div
        v-for="(step, i) in steps"
        :key="i"
        class="w-full md:w-1/3 flex justify-center my-4"
      >
        <visa-application-step
          :to="step.to"
          :title="$t(`${$options.tPfx}.${step.title}`)"
          :imageSrc="step.imageSrc"
          :description="$t(`${$options.tPfx}.${step.description}`)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VisaApplicationStep from "./VisaApplicationProcess/VisaApplicationStep.vue";

export const translationPrefix = "view.home.visaApplicationProcess";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      "3steps": `
				3 Steps & <br />
				your e-Visa is ready!
			`,
      apply: "Apply",
      applyDescription: "Fill out your application",
      payment: "Payment",
      paymentDescription: "Verify your application information",
      result: "Result",
      resultDescription: "Wait for results & access conciege service",
    },
    al: {
      "3steps": `
				3 Hapa & <br />
				Viza juaj është gati!
			`,
      apply: "Apliko",
      applyDescription: "Plotëso aplikimin",
      payment: "Pagesa",
      paymentDescription: "Verifiko informacionin e aplikimit",
      result: "Rezultati",
      resultDescription: "Prit për rezultatin & akseso shërbimet",
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: {
    VisaApplicationStep,
  },
  props: {
    steps: {
      type: Array,
      default: () => [
        {
          title: "apply",
          imageSrc: "/img/apply.png",
          description: "applyDescription",
          to: { name: "Apply" },
        },
        {
          title: "payment",
          imageSrc: "/img/payment.png",
          description: "paymentDescription",
        },
        {
          title: "result",
          imageSrc: "/img/result.png",
          description: "resultDescription",
        },
      ],
      validator: (stepsToValidate) => {
        let isValid = true;

        for (const step of stepsToValidate) {
          if (
            !("title" in step) ||
            !("imageSrc" in step) ||
            !("description" in step)
          ) {
            isValid = false;
            break;
          }
        }

        return isValid;
      },
    },
  },
};
</script>
