<template>
  <article
    v-if="user"
    class="border border-gray-400 rounded-md p-4 md:p-8 items-start bg-white"
  >
    <application-links :application-id="applicationId" />
    <header>
      <h2>{{ $t(`${$options.tPfx}.instructions`) }}</h2>
    </header>
    <ol class="list-inside list-decimal mb-4">
      <li>
        <i18n :path="`${$options.tPfx}.redirected`">
          <template #proceed>
            <strong>{{ $t(`${$options.tPfx}.proceed`) }}</strong>
          </template>
        </i18n>
      </li>
      <li>
        <i18n :path="`${$options.tPfx}.onThePaymentSite`">
          <template #pay>
            <strong>{{ $t(`${$options.tPfx}.pay`) }}</strong>
          </template>
        </i18n>
      </li>
      <li>
        <i18n :path="`${$options.tPfx}.comeBack`">
          <template #return>
            <strong>{{ $t(`${$options.tPfx}.return`) }}</strong>
          </template>
        </i18n>
      </li>
    </ol>

    <button
      :disabled="buttonIsDisabled"
      @click="handlePayment"
      class="button button-secondary w-full md:w-auto capitalize"
    >
      {{ $t(`${$options.tPfx}.${buttonKey}`) }}
    </button>
  </article>
</template>

<script>
import { mapActions } from "vuex";
import pusherAuthMixin from "@/mixins/pusherAuthMixin";
import openLinkInNewPage from "@/utils/openLinkInNewPage";
import { Application } from "@/api";
import { module as flashModule, actions as flashActions } from "@/store/flash";
import ApplicationLinks from "./ApplicationLinks.vue";

export const translationPrefix = "view.payment.payOnline";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      instructions: "Instructions",
      proceed: "proceed",
      inProcess: "In process...",
      redirected:
        "Once you click the {proceed} button, a new site will be opened to the payment system",
      pay: "perform the payment",
      onThePaymentSite: "Follow the payment site instructions and {pay}",
      return: "Return",
      comeBack:
        "{return} to e-Visa to check on the status of your application and payment.",
      success: "Success!",
      tryAgain: "Try again",
    },
    al: {
      instructions: "Instruksione",
      proceed: "vazhdo",
      inProcess: "Në progress...",
      redirected:
        "Pasi të shtypni butonin {proceed}, nje faqe e re do hapet tek sistemi i pagesave",
      pay: "kryeni pagesën",
      onThePaymentSite: "Ndiqni udhëzimet e faqes së pagesave dhe {pay}",
      return: "Kthehuni",
      comeBack:
        "{return} tek e-Visa për të shqyrtuar statusin e pagesës dhe të aplikimit tuaj.",
      success: "Sukses!",
      tryAgain: "Try again",
    },
  },
};
export default {
  components: { ApplicationLinks },
  tPfx: translationPrefix,
  beforeRouteLeave(_to, _from, next) {
    this.dispatchDismissFlash();
    next();
  },
  mixins: [pusherAuthMixin],
  props: {
    applicationId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    buttonIsDisabled: false,
    buttonKey: "proceed",
  }),
  methods: {
    ...mapActions(flashModule, {
      dispatchDismissFlash: flashActions.dismiss,
      dispatchShowFlash: flashActions.show,
    }),
    async handlePayment() {
      this.buttonIsDisabled = true;
      this.buttonKey = "inProcess";

      const { data: urlReponse } = await Application.getPaymentURL(
        this.applicationId
      );

      const url = urlReponse.data.url;

      openLinkInNewPage(url);
    },
    listenToProcessedPaymentEvents() {
      const userPersonalChannel = this.channels[this.user.email];

      if (!userPersonalChannel) {
        return;
      }

      userPersonalChannel.bind("paymentProcessed", async (paymentProcessed) => {
        if (this.applicationId == paymentProcessed.applicationId) {
          if (paymentProcessed.isSuccesful) {
            this.buttonKey = "success";

            this.$emit("payment-success");

            await this.dispatchShowFlash({
              class:
                "sm:block md:inline-block border-green-500 bg-green-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
              iconClass: "hover:text-green-500",
              text: paymentProcessed.notification[this.$i18n.locale],
              textClass: "avoid-warp",
            });
          } else {
            this.buttonIsDisabled = false;
            this.buttonKey = "tryAgain";
          }
        }
      });
    },
  },
  watch: {
    user(newUser) {
      if (newUser) {
        this.listenToProcessedPaymentEvents();
      }
    },
  },
  mounted() {
    if (this.user) {
      this.listenToProcessedPaymentEvents();
    }
  },
};
</script>

<style>
</style>