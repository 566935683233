<template>
  <section>
    <template v-if="pages.length">
      <!-- Mobile -->
      <pages-mobile
        @input="$emit('input', $event)"
        :value="value"
        :pages="pagesWithNames"
        :selected-page-index="selectedPageIndex"
        :only-backwards="onlyBackwards"
        :translation-namespace="$options.tPfx"
        class="md:hidden"
      />
      <!-- Web -->
      <pages-web
        @input="$emit('input', $event)"
        :value="value"
        :pages="pagesWithNames"
        :selected-page-index="selectedPageIndex"
        :only-backwards="onlyBackwards"
        :translation-namespace="$options.tPfx"
        class="hidden md:flex"
      />
    </template>
  </section>
</template>

<script>
import pageNumberToI18nKey from "./helpers/pageNumberToI18nKey";
import PagesMobile from "./Pages/PagesMobile.vue";
import PagesWeb from "./Pages/PagesWeb.vue";

export const translationPrefix = "view.questionary.pages";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      personalInformation: "Personal information",
      passportInformation: "Passport information",
      visaInformation: "Visa information",
      familyMembersInformation: "Family members information",
      hostInformation: "Host information",
      addressAndContact: "Address & contact",
      employmentInformation: "Employment Information",
      fillOfApplication: "Application fill information",
      uploadDocuments: "Upload documents",
      close: "Close",
      pageOnlyBackwards:
        "You can only go backwards when creating an application",
    },
    al: {
      personalInformation: "Informacion personal",
      passportInformation: "Informacion i pasaportës",
      visaInformation: "Informacion i vizës",
      familyMembersInformation: "Informacion i anëtarve të familjes",
      hostInformation: "Informacion i pritësit",
      addressAndContact: "Adresa & Kontakt",
      employmentInformation: "Informacion mbi punësimin",
      fillOfApplication: "Plotësimi i aplikimit",
      uploadDocuments: "Ngarkimi i dokumentave",
      close: "Mbyll",
      pageOnlyBackwards:
        "Ju mund te shkoni vetëm mbrapa gjatë krijimit të aplikimit",
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: { PagesMobile, PagesWeb },
  props: {
    pages: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    onlyBackwards: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    pagesWithNames() {
      return this.pages.map((page) => ({
        page,
        title: pageNumberToI18nKey(page, this.pages),
      }));
    },
    selectedPageIndex() {
      return this.value - 1;
    },
  },
};
</script>

<style>
</style>