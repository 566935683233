import { differenceInYears, addDays, subDays, parseISO } from 'date-fns';
import QUESTION_CODE_ENUM from './questionCodeEnum';

// ! Only works in Questionary component
// -----------------------------------
// Only to be used to filter the options of other questions
// Or set some initial configuration like minDate, maxValue etc
export default {
  applyCountryOfResidenceSideEffects(question) {
    const countryQuestion = question;
    const consularOfficeQuestion = this.getQuestionByCode(
      QUESTION_CODE_ENUM.consularRepresentation
    );

    if (this.questionHasAnswer(countryQuestion)) {
      const selectedCountryByName = countryQuestion.answers[0].answer;
      const selectedCountryOption = countryQuestion.options.find(
        (countryOption) =>
          countryOption.name.al === selectedCountryByName.al
      );
      let prevSelectedConsularOffice;
      if (this.questionHasAnswer(consularOfficeQuestion)) {

        let answerAl = consularOfficeQuestion.answers[0].answer.al;
        let answerEn = consularOfficeQuestion.answers[0].answer.en;
        prevSelectedConsularOffice = consularOfficeQuestion.options.find(option => {
          return option.name.al === answerAl && option.name.en === answerEn;
        });

        this.addEmailOfConsularRepresentationToErrorMessage(answerEn)
      }

      const isCurrentConsularOfficeOnCountryOffices = selectedCountryOption.consularRepresentations
        .map(consularRep => JSON.stringify(consularRep))
        .includes(JSON.stringify(prevSelectedConsularOffice));

      if (!isCurrentConsularOfficeOnCountryOffices) {
        selectedCountryOption.consularRepresentations.push(prevSelectedConsularOffice)
      }

      consularOfficeQuestion.options = selectedCountryOption.consularRepresentations;
    }
  },
  applyCurrentNationalitySideEffects(question) {
    const currentNationalityQuestion = question;

    if (this.questionHasAnswer(currentNationalityQuestion)) {
      const selectedNationalityByName =
        currentNationalityQuestion.answers[0].answer;
      const selectedNationalityOption = currentNationalityQuestion.options.find(
        (nationality) =>
          nationality.name.al === selectedNationalityByName.al
      );

      if (!selectedNationalityOption.requiresVisa) {
        this.currentNationalityDoesNotRequireVisa = true;

        this.$swal(
          this.$t(`${this.$options.tPfx}.currentNationalyVisaNotRequired`)
        );
      }
    }
  },
  applyDateOfBirthSideEffects(question) {
    const dateOfBirthQuestion = question;

    question.initialView = 'year';
    question.maxDate = new Date();

    if (this.questionHasAnswer(dateOfBirthQuestion)) {
      // Date of birth is same for both al and en locales
      const dateOfBirth = new Date(dateOfBirthQuestion.answers[0].answer.en);
      const today = new Date();

      if (differenceInYears(today, dateOfBirth) < 18) {
        this.under18DocumentIsRequired = true;
      }
    }
  },

  applyResidencePermitSideEffects(question) {
    const residencePermitQuestion = question;

    if (this.questionHasAnswer(residencePermitQuestion)) {
      const answer = residencePermitQuestion.answers[0].answer.en;
      if (answer === 'Yes') {
        this.residencePermitOver12MonthsIsRequired = true
      }
    }

  },

  applyPassportDateOfIssueSideEffects(question) {
    const dateOfIssueQuestion = question;

    dateOfIssueQuestion.initialView = 'year';
    // date of expiration will be minDate today,
    // so it will always be expirationDate > issueDate
    dateOfIssueQuestion.maxDate = new Date();
  },
  applyPassportExpiryDateSideEffects(question) {
    const expiryDateQuestion = question;

    expiryDateQuestion.initialView = 'year';
    expiryDateQuestion.minDate = new Date();
  },
  applyDateOfArrivalSideEffects(question) {
    const dateOfArrivalQuestion = question;
    const dateOfDepartureQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.dateOfDeparture);

    dateOfArrivalQuestion.initialView = 'month';
    dateOfArrivalQuestion.minDate = new Date();

    if (this.questionHasAnswer(dateOfArrivalQuestion)) {
      const currentDateOfArrival = parseISO(dateOfArrivalQuestion.answers[0].answer.en);

      dateOfDepartureQuestion.minDate = addDays(currentDateOfArrival, 1);
    } else {
      dateOfDepartureQuestion.minDate = addDays(dateOfArrivalQuestion.minDate, 1);
    }
  },
  applyDateOfDepartureSideEffects(question) {
    const dateOfDepartureQuestion = question;

    dateOfDepartureQuestion.initialView = 'month';

    if (this.questionHasAnswer(dateOfDepartureQuestion)) {
      const currentDateOfDeparture = parseISO(dateOfDepartureQuestion.answers[0].answer.en);

      const dateOfArrivalQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.dateOfArrival);
      dateOfArrivalQuestion.maxDate = subDays(currentDateOfDeparture, 1);
    }
  },
}