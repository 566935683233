import QUESTION_TYPE_ENUM from "./questionTypeEnum";

// ! Do not use arrow functions to preserve this context for vue component
function _addAuxPropsToDateQuestion(question) {
  this.$set(question, 'minDate', null);
  this.$set(question, 'maxDate', null);
  this.$set(question, 'disabledDates', null);
  this.$set(question, 'initialView', 'day');
}

export default {
  addAuxPropsToDateQuestion: _addAuxPropsToDateQuestion,
  addAuxPropsToQuestion(question) {
    this.$set(question, "error", null);
    this.$set(question, "errorCount", null);
    this.$set(question, "errorParams", {});
    this.$set(question, "dirty", false);

    if (question.type === QUESTION_TYPE_ENUM.date) {
      this.addAuxPropsToDateQuestion(question);
    }
  }
}