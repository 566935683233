import QUESTION_TYPE_ENUM from "./questionTypeEnum";

const questionTypeToInputType = (questionType) => {
  switch (questionType) {
    case QUESTION_TYPE_ENUM.textarea:
    case QUESTION_TYPE_ENUM.input:
      return 'text';
    case QUESTION_TYPE_ENUM.select:
      return 'select';
    case QUESTION_TYPE_ENUM.date:
      return 'date';
    case QUESTION_TYPE_ENUM.radio:
      return 'radio';
  }
};

export default questionTypeToInputType;
