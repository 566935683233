<template>
  <div class="flex flex-row flex-wrap justify-center">
    <button
      class="outline-none focus:outline-none my-2 md:my-0 md:mx-4 w-full border-4 hover:border-secondary-500 rounded-md p-4 md:w-auto md:flex-1 md:max-w-sm bg-white text-center cursor-pointer"
      :class="{
        'border-solid border-secondary-500': isProofOfPayment,
        'border-dashed': !isProofOfPayment,
      }"
      @click="$emit('method-selected', 'proofOfPayment')"
    >
      <font-awesome-icon icon="file-upload" />
      {{ $t(`${this.$options.tPfx}.proofOfPayment`) }}
    </button>
    <button
      class="outline-none focus:outline-none my-2 md:my-0 md:mx-4 w-full border-4 hover:border-secondary-500 rounded-md p-4 md:w-auto md:flex-1 md:max-w-sm bg-white text-center cursor-pointer"
      :class="{
        'border-solid border-secondary-500': isPayOnline,
        'border-dashed': !isPayOnline,
      }"
      @click="$emit('method-selected', 'payOnline')"
    >
      <font-awesome-icon :icon="['far', 'credit-card']" />
      {{ $t(`${this.$options.tPfx}.payOnline`) }}
    </button>
  </div>
</template>

<script>
export const translationPrefix = "view.payment.methodSelect";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      proofOfPayment: "Wire transfer",
      payOnline: "Pay online",
    },
    al: {
      proofOfPayment: "Paguaj me bankë",
      payOnline: "Paguaj online",
    },
  },
};

export default {
  tPfx: translationPrefix,
  model: {
    prop: "selectedMethod",
    event: "method-selected",
  },
  props: {
    selectedMethod: {
      type: String,
      requested: true,
    },
  },
  computed: {
    isProofOfPayment() {
      return this.selectedMethod === "proofOfPayment";
    },
    isPayOnline() {
      return this.selectedMethod === "payOnline";
    },
  },
};
</script>

<style>
</style>