<template>
  <ul
    class="mx-auto border border-gray-400 rounded-md p-4 md:p-8 w-full bg-white"
  >
    <visa-type
      v-for="visaType in visaTypes"
      :key="visaType.id"
      :visa-type="visaType"
      :is-electronic="isElectronic"
    />
  </ul>
</template>

<script>
import VisaType from "./VisaTypes/VisaType.vue";

export default {
  components: { VisaType },
  props: {
    visaTypes: {
      type: Array,
      default: () => ({}),
      validator: () => true, // TODO: to implement
    },
    isElectronic: {
      type: Boolean,
      default: true,
    }
  },
};
</script>

<style>
</style>
