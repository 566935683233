import { differenceInYears, parseISO, toDate, addDays, subDays } from 'date-fns';
import QUESTION_CODE_ENUM from './questionCodeEnum';
import questionRequiredBasedOnCondition from './questionRequiredBasedOnCondition';

// ! Only works in Questionary component
// ! Methods like getQuestionByCode, questionHasAnswer are available there
export default {
  async applyCountryOfResidenceSideEffectsAfterServerSync(question) {
    const countryQuestion = question;
    const consularOfficeQuestion = this.getQuestionByCode(
      QUESTION_CODE_ENUM.consularRepresentation
    );

    const countryQuestionHasAnswer = this.questionHasAnswer(
      countryQuestion
    );
    const consularRepQuestionHasAnswer = this.questionHasAnswer(
      consularOfficeQuestion
    );

    if (countryQuestionHasAnswer && !consularRepQuestionHasAnswer) {
      const selectedCountryByName = countryQuestion.answers[0].answer;
      const selectedCountryOption = countryQuestion.options.find(
        (country) => country.name.al === selectedCountryByName.al
      );

      consularOfficeQuestion.options =
        selectedCountryOption.consularRepresentations;

      return;
    }

    if (countryQuestionHasAnswer && consularRepQuestionHasAnswer) {
      consularOfficeQuestion.answers[0].toDelete = true;
      consularOfficeQuestion.dirty = true;

      await this.onQuestionSynched({ question: consularOfficeQuestion });

      const selectedCountryByName = countryQuestion.answers[0].answer;
      const selectedCountryOption = countryQuestion.options.find(
        (country) => country.name.al === selectedCountryByName.al
      );

      consularOfficeQuestion.options =
        selectedCountryOption.consularRepresentations;

      return;
    }

    if (!countryQuestionHasAnswer && consularRepQuestionHasAnswer) {
      consularOfficeQuestion.answers[0].toDelete = true;
      consularOfficeQuestion.dirty = true;

      await this.onQuestionSynched({ question: consularOfficeQuestion });

      consularOfficeQuestion.options = [];
      return;
    }

    if (!countryQuestionHasAnswer && !consularRepQuestionHasAnswer) {
      consularOfficeQuestion.options = [];
    }
  },

  async applyConsularRepresentationSideEffectsAfterServerSync(question) {
    this.addEmailOfConsularRepresentationToErrorMessage(question.answers[0].answer.en)
  },
  async applyCurrentNationalitySideEffectsAfterServerSync(question) {
    const currentNationalityQuestion = question;

    if (this.questionHasAnswer(currentNationalityQuestion)) {
      const selectedNationalityByName =
        currentNationalityQuestion.answers[0].answer;
      const selectedNationalityOption = currentNationalityQuestion.options.find(
        (nationality) =>
          nationality.name.al === selectedNationalityByName.al
      );

      if (!selectedNationalityOption.requiresVisa) {
        this.currentNationalityDoesNotRequireVisa = true;

        this.$swal(
          this.$t(`${this.$options.tPfx}.currentNationalyVisaNotRequired`)
        );

        return;
      }
    }

    this.currentNationalityDoesNotRequireVisa = false;
    return;
  },
  applyDateOfBirthSideEffectsAfterServerSync(question) {
    const dateOfBirthQuestion = question;

    if (this.questionHasAnswer(dateOfBirthQuestion)) {
      // Date of birth is same for both al and en locales
      const dateOfBirth = new Date(dateOfBirthQuestion.answers[0].answer.en);
      const today = new Date();

      if (differenceInYears(today, dateOfBirth) < 18) {
        this.under18DocumentIsRequired = true;
      } else {
        this.under18DocumentIsRequired = false;
      }
    } else {
      this.under18DocumentIsRequired = false;
    }
  },
  applyResidencePermitSideEffectsAfterServerSync(question) {
    const residencePermitQuestion = question;

    if (this.questionHasAnswer(residencePermitQuestion)) {
      const answer = residencePermitQuestion.answers[0].answer.en;
      this.residencePermitOver12MonthsIsRequired = answer === 'Yes';
    } else {
      this.residencePermitOver12MonthsIsRequired = false;
    }
  },
  applyDateOfArrivalSideEffectsAfterServerSync(question) {
    const dateOfArrivalQuestion = question;
    const dateOfDepartureQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.dateOfDeparture);

    // No need to perform complex checks because minDate and maxDates are already bound to correct
    if (this.questionHasAnswer(dateOfArrivalQuestion)) {
      const currentDateOfArrival = parseISO(dateOfArrivalQuestion.answers[0].answer.en);
      dateOfDepartureQuestion.minDate = addDays(currentDateOfArrival, 1);
    } else {
      dateOfDepartureQuestion.minDate = toDate(dateOfArrivalQuestion.minDate, 1);
    }
  },
  applyDateOfDepartureSideEffectsAfterServerSync(question) {
    const dateOfDepartureQuestion = question;
    const dateOfArrivalQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.dateOfArrival);

    // No need to perform complex checks because minDate and maxDates are already bound to correct
    if (this.questionHasAnswer(dateOfDepartureQuestion)) {
      const currentDateOfDeparture = parseISO(dateOfDepartureQuestion.answers[0].answer.en);
      dateOfArrivalQuestion.maxDate = subDays(currentDateOfDeparture, 1);
    } else {
      dateOfArrivalQuestion.maxDate = null;
    }
  },
  applyHostSideEffectsAfterServerSync(question) {
    let hostBirthday = this.getQuestionByCode(QUESTION_CODE_ENUM.hostBirthday);
    let hostSex = this.getQuestionByCode(QUESTION_CODE_ENUM.hostSex);
    let hostNameIfCompany = this.getQuestionByCode(QUESTION_CODE_ENUM.hostNameIfCompany);
    questionRequiredBasedOnCondition.makeQuestionsNotRequired([hostBirthday, hostSex, hostNameIfCompany])

    let questionsRequired = []
    let answer = question?.answers[0]?.answer?.en

    if (answer === 'Person') {
      questionsRequired = [hostBirthday, hostSex]
    } else {
      questionsRequired = [hostNameIfCompany]
    }

    questionRequiredBasedOnCondition.makeQuestionsRequired(questionsRequired)
  }
}