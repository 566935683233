import querystring from 'querystring';
import i18n from '@i';

export default {
  onFulfilled: (config) => {
    const query = config.url.split('?')[1] || '';
    const queryParams = querystring.parse(query);

    if (!queryParams.locale) {
      config.params = config.params || {};
      config.params['locale'] = i18n.locale;
    }

    return config;
  },
  onRejected: (err) => Promise.reject(err),
}