const VueWiggle = {};

function _wiggle(target) {
  const element = document.querySelector(target);

  element.classList.add("animate-wiggle-once");
}

VueWiggle.install = function (Vue, options) {
  Vue.prototype.$wiggle = function (target, delay = 0) {
    if (!target) {
      return;
    }

    if (options && options.delay && !delay) {
      delay = options.delay
    }

    if (delay) {
      const delayTimeout = setTimeout(() => {
        _wiggle(target);
        clearTimeout(delayTimeout);
      }, delay);

      return;
    }

    _wiggle(target);
  }
};

export default VueWiggle;