<template functional>
  <router-link :to="{ name: 'Apply' }">
    <button
      class="rounded-md px-6 py-4 bg-secondary-500 font-bold text-white focus:outline-none focus:border focus:border-gray-700"
      :class="[data.class, data.staticClass]"
    >
      {{ parent.$t(`${$options.tPfx}.applyNow`) }}
    </button>
  </router-link>
</template>

<script>
export const translationPrefix = "component.apply_button";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      applyNow: "Apply Now",
    },
    al: {
      applyNow: "Apliko Tani",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>
