<template>
  <button
    v-on="$listeners"
    :disabled="isLoading"
    class="flex justify-center items-center"
  >
    <span v-if="isLoading" class="inline-block">
      <font-awesome-icon icon="spinner" class="fa-pulse mr-2" />
    </span>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>