<template>
  <div class="relative">
    <div class="absolute w-full flex justify-items-center">
      <tapered-section class="relative w-full">
        <div class="absolute z-10 top-4 right-4 sm:right-6 lg:right-8">
          <apply-button class="mx-2" />
          <track-button class="mx-2" />
        </div>
      </tapered-section>
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide class="h-auto" v-for="(slide, i) in slides" :key="i">
        <img
          :data-src="slide.src"
          :class="slide.class"
          class="swiper-lazy max-h-screen-2/3 min-h-128 w-full"
        />
        <div class="swiper-lazy-preloader swiper-lazy-preloader-black"></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import ApplyButton from "@c/ApplyButton.vue";
import "swiper/css/swiper.min.css";
import TaperedSection from "@c/TaperedSection.vue";
import TrackButton from "@c/TrackButton";

export default {
  components: {
    TrackButton,
    Swiper,
    SwiperSlide,
    ApplyButton,
    TaperedSection,
  },
  directives: {
    swiper: directive,
  },
  props: {
    slides: {
      type: Array,
      default: () => [
        { src: "/img/1.jpg", alt: "scanderbeg-museum", class: "object-cover" },
        { src: "/img/2.jpg", alt: "apollonia-theatre", class: "object-cover" },
        {
          src: "/img/3.jpg",
          alt: "kruja-castle",
          class: "object-cover object-top",
        },
        {
          src: "/img/4.jpg",
          alt: "apollonia-ruins",
          class: "object-cover object-top",
        },
        { src: "/img/5.jpg", alt: "ksamil-beach", class: "object-cover" },
        { src: "/img/6.jpg", alt: "gjirokastra-city", class: "object-cover" },
        { src: "/img/7.jpg", alt: "alps", class: "object-cover" },
        { src: "/img/8.jpg", alt: "museum", class: "object-cover" },
      ],
      validator: (slidesToValidate) => {
        let isValid = true;

        for (const slides of slidesToValidate) {
          if (!("src" in slides) || !("alt" in slides)) {
            isValid = false;
            break;
          }
        }

        return isValid;
      },
    },
    swiperOption: {
      type: Object,
      default: () => ({
        lazy: true,
        pagination: {
          el: ".swiper-pagination",
        },
      }),
    },
  },
};
</script>

<style>
.swiper-pagination-bullet {
  background-color: white;
  opacity: 0.4;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: white;
  opacity: 1;
}
</style>
