var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-center items-start"},_vm._l((_vm.pages),function(page){return _c('button',{key:page.page,staticClass:"p-2 w-24 lg:w-32 cursor-pointer",class:{
      'cursor-pointer':
        !_vm.onlyBackwards || (_vm.onlyBackwards && page.page <= _vm.value),
      'cursor-not-allowed': _vm.onlyBackwards && page.page > _vm.value,
    },attrs:{"title":_vm.onlyBackwards && page.page > _vm.value
        ? _vm.$t((_vm.translationNamespace + ".pageOnlyBackwards"))
        : _vm.$t((_vm.translationNamespace + "." + (page.title)))},on:{"click":function($event){return _vm.$emit('input', page.page)}}},[_c('span',{staticClass:"inline-block w-full h-1",class:{
        'bg-primary-900':
          page.page !== _vm.value &&
          (!_vm.onlyBackwards || (_vm.onlyBackwards && page.page <= _vm.value)),
        'bg-gray-400':
          page.page !== _vm.value && _vm.onlyBackwards && page.page > _vm.value,
        'bg-secondary-500': page.page === _vm.value,
      }}),_vm._v(" "+_vm._s(_vm.$t((_vm.translationNamespace + "." + (page.title))))+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }