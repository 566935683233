import { render, staticRenderFns } from "./PagesModal.vue?vue&type=template&id=afdec600&scoped=true&"
import script from "./PagesModal.vue?vue&type=script&lang=js&"
export * from "./PagesModal.vue?vue&type=script&lang=js&"
import style0 from "./PagesModal.vue?vue&type=style&index=0&id=afdec600&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "afdec600",
  null
  
)

export default component.exports