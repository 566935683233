<template>
  <div v-if="hasFileToDisplay" class="flex items-center w-full">
    <div class="inline-block">
      <div v-if="isDisplayable" class="h-20 w-20 px-2 py-2">
        <img :src="src" class="h-full" />
      </div>
      <div v-else-if="isPdf" class="h-20 w-20 px-2 py-2">
        <img src="/img/svg/pdf.svg" class="h-full pdf" />
      </div>
    </div>
    <div class="flex-1 flex flex-wrap p-2">
      <header
        @click="download"
        class="mb-2 h-6 overflow-ellipsis overflow-hidden cursor-pointer hover:underline"
      >
        <strong class="text-sm">{{ file.name }}</strong>
      </header>
      <div v-if="isInProgress" class="border rounded-md w-full border-gray-400">
        <div
          class="h-4 rounded-md bg-secondary-500"
          :style="{ width: `${uploadProgress}%` }"
        />
      </div>
      <div v-else class="w-full">
        {{ humanReadableSize }}
      </div>
    </div>
  </div>
</template>

<script>
import downloadFile, { getFileNameFromHeaders } from "@/utils/downloadFile";
import sizeToHumanReadable from "./helpers/sizeToHumanReadable";
import axios from "axios";

export default {
  props: {
    size: {
      type: String,
    },
    file: {
      type: [File, Object],
    },
    fileName: {
      type: String,
      required: true,
    },
    fileSrcProperty: {
      type: String,
      default: "path",
    },
    fileDownloadPathProperty: {
      type: String,
      default: "downloadPath",
    },
    fileMimeProperty: {
      type: String,
      default: "type",
    },
    fileInProgressProperty: {
      type: String,
      default: "inProgress",
    },
    fileSizeProperty: {
      type: String,
      default: "size",
    },
    uploadProgress: {
      type: Number,
    },
    origin: {
      type: String,
      default: "",
    },
    headers: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    src: "",
  }),
  computed: {
    hasFileToDisplay() {
      return !!this.file;
    },
    isDisplayable() {
      return (
        this.file[this.fileMimeProperty] === "image/jpeg" ||
        this.file[this.fileMimeProperty] === "image/png"
      );
    },
    isPdf() {
      if (!this.hasFileToDisplay) {
        return false;
      }

      return this.file[this.fileMimeProperty] === "application/pdf";
    },
    isInProgress() {
      if (!this.hasFileToDisplay) {
        return false;
      }

      return this.file[this.fileInProgressProperty];
    },
    humanReadableSize() {
      if (!this.hasFileToDisplay) {
        return "0MB";
      }

      return sizeToHumanReadable(this.file[this.fileSizeProperty]);
    },
    fileGetPath() {
      return `${this.origin}${this.file[this.fileSrcProperty]}`;
    },
    fileDownloadPath() {
      return `${this.origin}${this.file[this.fileDownloadPathProperty]}`;
    },
  },
  methods: {
    async fetchProtectedImage() {
      const { data: base64Image } = await axios.get(
        this.fileGetPath,
        this.headers
      );

      this.src = `data:${this.file[this.fileMimeProperty]};base64,${
        base64Image.data[this.fileName]
      }`;
    },
    async download() {
      const downloadFileResponse = await axios.get(this.fileDownloadPath, {
        ...this.headers,
        responseType: "blob",
      });

      downloadFile(
        downloadFileResponse.data,
        getFileNameFromHeaders(downloadFileResponse.headers)
      );
    },
  },
  mounted() {
    if (!this.isPdf && !this.isInProgress && this.hasFileToDisplay) {
      this.fetchProtectedImage();
    }
  },
  updated() {
    if (!this.isPdf && !this.isInProgress && this.hasFileToDisplay) {
      this.fetchProtectedImage();
    }
  },
};
</script>

<style>
.pdf {
  background: #c11e07;
  color: white;
}
</style>
