import Pusher from "pusher-js";
import { mapState } from "vuex";
import { module as authModule } from "@/store/auth";

const pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
});

/**
 * To be used to listen to private user events
 */
const pusherAuthMixin = {
  data: () => ({
    pusher,
    channels: {},
  }),
  computed: {
    ...mapState(authModule, ["user"]),
  },
  methods: {
    subscribe(channelName) {
      const channel = this.pusher.subscribe(channelName);

      this.$set(this.channels, channelName, channel);
    },
    unsubscribeAll() {
      for (const [name, channel] of Object.entries(this.channels)) {
        channel.unbind_all();
        this.pusher.unsubscribe(name);
      }
    },
    unsubscribe(channelName) {
      this.channels[channelName].unbind_all();

      this.pusher.unsubscribe(channelName);
    },
  },
  watch: {
    user(newUser, oldUser) {
      if (newUser) {
        this.subscribe(newUser.email);
      } else {
        this.unsubscribe(oldUser.email);
      }
    },
  },
  mounted() {
    if (this.user) {
      this.subscribe(this.user.email);
    }
  },
  beforeDestroy() {
    this.unsubscribeAll();
  },
};

export default pusherAuthMixin;
