<template>
  <div class="p-2">
    <div
      class="h-full flex flex-col flex-wrap p-2 pb-0"
      :class="{
        'border border-secondary-500 rounded-md justify-between':
          showComment && comment,
        'justify-end': !showComment || !comment,
      }"
    >
      <span
        v-if="showComment && comment"
        class="mb-4 italic text-xs text-secondary-500"
      >
        <font-awesome-icon icon="info-circle" class="fa-sm"></font-awesome-icon>
        {{ $t(`${$options.tPfx}.comment`) }}:
        {{ comment[$i18n.locale] }}
      </span>
      <component
        :is="dynamicQuestionComponent"
        v-bind="dynamicAttributes"
        v-on="dynamicEventHandlers"
        class="w-full"
      >
        <template
          v-if="questionIsSelect || questionIsRadio"
          #no-options="{ search }"
        >
          <i18n :path="`${$options.tPfx}.noMatches`">
            <template #search>
              <em>"{{ search }}"</em>
            </template>
          </i18n>
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import QUESTION_CODE_ENUM from "../helpers/questionCodeEnum";
import QUESTION_TYPE_ENUM from "../helpers/questionTypeEnum";
import STATUS_ENUM from "@/utils/applicationStatusEnum";
import questionTypeToComponent from "../helpers/questionTypeToComponent";
import questionTypeToInputType from "../helpers/questionTypeToInputType";

export const translationPrefix = "view.questionary.questions.question";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      isRequired: "This question is required",
      isTodayOrInThePast: "Date cannot be today or in the past",
      isAfterOtherDate: 'Date cannot be after "{otherQuestion}"',
      isBeforeOtherDate: 'Date cannot be before "{otherQuestion}"',
      noMatches: "No matches for {search}",
      comment: "Comment",
      invalidEmail: "Invalid email",
      invalidPassportNumber: "Passport Number should not have spaces, and it should contain only letters from the English alphabet (Aa-Zz)and numbers(0-9)",
      invalidDurationOfStayTypeC: "The number of days of stay should be a number format and should be up to 30 days",
      pleaseInsertLatinCharacters: "Please use only latin characters",
      passportNumberIsNotAllowedToApplyForVisa: 'This passport number is not allowed to apply for visa'
    },
    al: {
      isRequired: "Kjo pyetje eshte e detyrueshme",
      isTodayOrInThePast: "Nuk mund të jetë data e sotme ose datë e kaluar",
      isAfterOtherDate: 'Data nuk mund të jetë pas "{otherQuestion}"',
      isBeforeOtherDate: 'Data nuk mund të jetë para "{otherQuestion}"',
      noMatches: "Asnjë rezultat për {search}",
      comment: "Koment",
      invalidEmail: "Format i gabuar i emailit",
      invalidPassportNumber: "Pashaporta nuk duhet të ketë hapësira, dhe duhet të përmbajë vetëm shkronja nga alfabeti Anglez(Aa-Zz) dhe numra(0-9)",
      invalidDurationOfStayTypeC: "Numri i ditëve të qëndrimit duhet të jetë në format numër dhe të jetë deri në 30 ditë",
      pleaseInsertLatinCharacters: "Ju lutem përdorni vetëm gërma latine",
      passportNumberIsNotAllowedToApplyForVisa: 'Ky numër pasaporte nuk është i lejuar që të aplikojë për vizë'
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    applicationStatusCode: {
      type: [String],
    },
    question: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    showComment: {
      type: Boolean,
      required: true,
    },
    questionNumber: {
      type: String,
      required: true,
    },
  },
  computed: {
    dynamicQuestionComponent() {
      return questionTypeToComponent(this.question.type);
    },
    inputType() {
      return questionTypeToInputType(this.question.type);
    },
    questionIsSelect() {
      return this.question.type === QUESTION_TYPE_ENUM.select;
    },
    questionIsRadio() {
      return this.question.type === QUESTION_TYPE_ENUM.radio;
    },
    questionIsDate() {
      return this.question.type === QUESTION_TYPE_ENUM.date;
    },
    localizedError() {
      if (!this.question.error) {
        return this.question.error;
      }

      const errorParams = {};

      if (this.question.errorParams.relatedQuestion) {
        errorParams.otherQuestion = this.question.errorParams.relatedQuestion.question[
          this.$i18n.locale
        ];
      }

      console.log(errorParams);

      return this.$t(
        `${this.$options.tPfx}.${this.question.error}`,
        errorParams
      );
    },
    // TODO: This is only required because question seeder does not iclude * in English
    localizedLabel() {
      const translatedLabel = this.question.question[this.$i18n.locale];
      const hasAsterix = translatedLabel.includes("*");

      if (
        this.$i18n.locale === "en" &&
        this.question.isRequired &&
        !hasAsterix
      ) {
        return `${this.questionNumber} ${translatedLabel} *`;
      }

      return `${this.questionNumber} ${translatedLabel}`;
    },
    comment() {
      const comments = this.question.comments;

      if (!comments[0]) {
        return;
      }

      return this.question.comments[0].comment;
    },
    //! Bind all props dynamically based on the question type
    dynamicAttributes() {

      if (this.question.code === QUESTION_CODE_ENUM.visaCategoryGroup) {
        localStorage.setItem("visa_type", this.question.answers[0]?.answer?.en);
      }

      const isDisabled =
        this.disabled ||
        this.question.code === QUESTION_CODE_ENUM.visaCategoryGroup ||
        (this.question.code === QUESTION_CODE_ENUM.countryOfResidence &&
          this.applicationStatusCode === STATUS_ENUM.consularNeedChanges) ||
        (this.question.code === QUESTION_CODE_ENUM.consularRepresentation &&
          this.applicationStatusCode === STATUS_ENUM.consularNeedChanges) ||
        (this.question.code === QUESTION_CODE_ENUM.durationOfStay &&
        localStorage.getItem("visa_type") === 'D');

      if (this.questionIsSelect || this.questionIsRadio) {
        return {
          disabled: isDisabled,
          options: this.question.options || [],
          getOptionLabel: (option) =>
            option.name ? option.name[this.$i18n.locale] : "",
          reduce: (option) => option.name,
          label: this.localizedLabel,
          error: this.localizedError,
          value: this.question.answers[0]
            ? this.question.answers[0].answer
            : null,
        };
      }

      if (this.questionIsDate) {
        return {
          error: this.localizedError,
          initialView: this.question.initialView,
          label: this.localizedLabel,
          disabled: isDisabled,
          minDate: this.question.minDate,
          maxDate: this.question.maxDate,
          disabledDates: this.question.disabledDates,
          value:
            this.question.answers[0] && this.question.answers[0].answer
              ? this.question.answers[0].answer.en
              : "",
        };
      }

      //! For input does not matter which locale, because it is the same
      return {
        disabled: isDisabled,
        type: this.inputType,
        label: this.localizedLabel,
        error: this.localizedError,
        value:
          this.question.answers[0] && this.question.answers[0].answer
            ? this.question.answers[0].answer.en
            : "",
      };
    },
    dynamicEventHandlers() {
      if (this.questionIsSelect || this.questionIsRadio) {
        return {
          input: this.onQuestionAnswered,
          close: this.onAnswerSync,
          clear: this.onAnswerSync,
        };
      }

      if (this.questionIsDate) {
        return {
          input: this.onQuestionAnsweredAndSynchedOnNextTick,
        };
      }

      return {
        input: this.onQuestionAnswered,
        blur: this.onAnswerSync,
      };
    },
  },
  methods: {
    onQuestionAnswered(answer) {
      this.$emit("answered", {
        answer,
        question: this.question,
      });
    },
    onAnswerSync() {
      this.$emit("synched", {
        question: this.question,
      });
    },
    onQuestionAnsweredAndSynchedOnNextTick(answer) {
      this.$emit("answered", {
        answer,
        question: this.question,
      });

      this.$nextTick(() => {
        this.$emit("synched", {
          question: this.question,
        });
      });
    },
  },
};
</script>
