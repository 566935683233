import client from "./client";

export default {
  editProfile(form) {
    return client.post("/me/edit", form);
  },

  register(form) {
    return client.post("/register", form);
  },

  login(form) {
    return client.post("/login", form);
  },

  logout() {
    return client.post("/logout");
  },

  auth() {
    return client.get("/me");
  },
  forgotPassword(form) {
    return client.post("/forgot-password", form);
  }
};
