<template>
  <button
    class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
    @click="$emit('click')"
  >
    <expand :class="showCollapse ? 'hidden' : ''" />
    <collapse :class="showCollapse ? '' : 'hidden'" />
  </button>
</template>

<script>
import Expand from "./NavbarButton/Expand.vue";
import Collapse from "./NavbarButton/Collapse.vue";

export default {
  components: {
    Expand,
    Collapse,
  },
  props: {
    showCollapse: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
</style>