<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section class="px-4 py-12">
      <loader
        v-if="isLoadingApplications"
        :loading="isLoadingApplications"
        size="1.5rem"
        margin="4px"
      />

      <article
        v-if="!isLoadingApplications && !applications.length"
        class="border border-gray-400 rounded-md p-4 md:p-8 bg-white"
      >
        <div>{{ $t(`${$options.tPfx}.noApplications`) }}</div>
      </article>
      <article
        v-else-if="!isLoadingApplications && applications.length"
        class="border border-gray-400 rounded-md p-2 sm:p-4 md:p-8 bg-white"
      >
        <t-table
          :headers="[
            { text: $t(`${$options.tPfx}.trackingNumber`) },
            { text: $t(`${$options.tPfx}.visaType`) },
            { text: $t(`${$options.tPfx}.status`) },
            { text: $t(`${$options.tPfx}.actions`), className: 'text-center' },
          ]"
          :data="applications"
          :responsive="true"
          :responsive-breakpoint="768"
        >
          <template
            #tbody="{ tbodyClass, trClass, tdClass, renderResponsive, data }"
          >
            <template v-if="renderResponsive">
              <tbody
                v-for="row in data"
                :key="row.id"
                :class="tbodyClass"
                :id="`application-${row.trackingNumber}`"
              >
                <tr :class="trClass">
                  <th class="text-sm font-semibold text-gray-600 uppercase">
                    {{ $t(`${$options.tPfx}.trackingNumber`) }}
                  </th>
                  <td :class="[tdClass, 'relative']">
                    <router-link
                      class="text-secondary-500 hover:underline"
                      :to="{
                        name: 'Questionary',
                        params: { applicationId: row.id },
                      }"
                    >
                      {{ row.trackingNumber }}
                    </router-link>
                  </td>
                  <td rowspan="3" colspan="2" class="p-2">
                    <div class="flex flex-col">
                      <span
                        class="mb-2 text-sm font-semibold text-gray-600 uppercase"
                      >
                        {{ $t(`${$options.tPfx}.actions`) }}
                      </span>
                      <application-actions class="mx-auto" :application="row" />
                    </div>
                  </td>
                </tr>
                <tr :class="trClass">
                  <th class="text-sm font-semibold text-gray-600 uppercase">
                    {{ $t(`${$options.tPfx}.visaType`) }}
                  </th>
                  <td :class="[tdClass, 'td-overflow']">
                    {{ row.visaType.title[$i18n.locale] }}
                  </td>
                </tr>
                <tr :class="trClass">
                  <th class="p-2 text-sm font-semibold text-gray-600 uppercase">
                    {{ $t(`${$options.tPfx}.status`) }}
                  </th>
                  <td class="px-3 py-4">
                    <span
                      class="rounded-full py-2 px-4"
                      :class="
                        statusToTailwindClasses(
                          row.status.code,
                          row.status.name[$i18n.locale]
                        )
                      "
                    >
                      {{ row.status.name[$i18n.locale].replace("/", " / ") }}
                    </span>
                  </td>
                </tr>
              </tbody>
              <tfoot class="h-4"></tfoot>
            </template>
          </template>
          <template #row="{ trClass, tdClass, row }">
            <tr :class="trClass" :id="`application-${row.trackingNumber}`">
              <td :class="tdClass">
                <router-link
                  class="text-secondary-500 hover:underline"
                  :to="{
                    name: 'Questionary',
                    params: { applicationId: row.id },
                  }"
                >
                  {{ row.trackingNumber }}
                </router-link>
              </td>
              <td :class="tdClass">
                {{ row.visaType.title[$i18n.locale] }}
              </td>
              <td :class="tdClass">
                <span
                  class="rounded-full whitespace-no-wrap"
                  :class="
                    statusToTailwindClasses(
                      row.status.code,
                      row.status.name[$i18n.locale]
                    )
                  "
                >
                  {{ row.status.name[$i18n.locale].replace("/", " / ") }}
                </span>
              </td>
              <td class="p-2">
                <application-actions :application="row" />
              </td>
            </tr>
          </template>
        </t-table>
      </article>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { Application } from "@/api";
import pusherAuthMixin from "@/mixins/pusherAuthMixin";
import ApplicationActions from "./Applications/ApplicationActions.vue";
import _statusToTailwindClasses from "./Applications/helpers/statusToTailwindClasses";

export const translationPrefix = "view.applications";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      trackingNumber: "Tracking Number",
      visaType: "Visa Type",
      status: "Status",
      actions: "Actions",
      noApplications: "There are no applications yet",
    },
    al: {
      trackingNumber: "Numri i gjurmimit",
      visaType: "Lloji i Vizës",
      status: "Statusi",
      actions: "Veprime",
      noApplications: "Ju nuk keni bere asnje aplikim",
    },
  },
};

export default {
  tPfx: translationPrefix,
  mixins: [pusherAuthMixin],
  components: {
    ApplicationActions,
  },
  props: {
    scrollToTrackingNumber: [Number, String],
  },
  data: () => ({
    isLoadingApplications: false,
    applications: [],
  }),
  methods: {
    async loadApplications() {
      this.isLoadingApplications = true;
      try {
        const { data: applicationsResponse } = await Application.all();

        this.applications = applicationsResponse.data.applications;

        if (this.scrollToTrackingNumber) {
          this.$nextTick(() => {
            this.$scrollTo(`#application-${this.scrollToTrackingNumber}`);
            this.$wiggle(`#application-${this.scrollToTrackingNumber}`, 600);
          });
        }
      } catch (error) {
        await this.$router.replace({ name: "SomethingWentWrong" });
      } finally {
        this.isLoadingApplications = false;
      }
    },
    changeApplicationStatus(applicationId, status) {
      const application = this.applications.find((application) => {
        return application.id == applicationId;
      });

      if (application) {
        application.status = status;
      }
    },
    listenToProcessedPaymentEvents() {
      const userPersonalChannel = this.channels[this.user.email];

      if (!userPersonalChannel) {
        return;
      }

      userPersonalChannel.bind("paymentProcessed", (paymentProcessed) => {
        const applicationId = paymentProcessed.applicationId;
        const status = paymentProcessed.status;

        this.changeApplicationStatus(applicationId, status);
      });
    },
    statusToTailwindClasses(statusCode, statusString) {
      return _statusToTailwindClasses(statusCode, statusString);
    },
  },
  mounted() {
    this.loadApplications();
    this.listenToProcessedPaymentEvents();
  },
};
</script>


<style>
</style>