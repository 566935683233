export const module = 'flash';

export const mutations = {
	setMessage: 'setMessage',
};

export const actions = {
	show: 'show',
	dismiss: 'dismiss',
}

/** @type {import('vuex').Module} */
const flashModule = {
	namespaced: true,
	state: () => ({
		message: null,
	}),
	getters: {
		isShowingMessage: ({ message }) => !!message,
	},
	mutations: {
		[mutations.setMessage]: (state, message) => state.message = message,
	},
	actions: {
		[actions.show]: ({ commit }, message) => {
			commit(mutations.setMessage, message);
		},
		[actions.dismiss]: ({ commit }) => {
			commit(mutations.setMessage, null);
		},
	}
}

export default flashModule;