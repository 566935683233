<template>
  <div class="flex justify-center items-start">
    <button
      v-for="page in pages"
      :key="page.page"
      @click="$emit('input', page.page)"
      :title="
        onlyBackwards && page.page > value
          ? $t(`${translationNamespace}.pageOnlyBackwards`)
          : $t(`${translationNamespace}.${page.title}`)
      "
      :class="{
        'cursor-pointer':
          !onlyBackwards || (onlyBackwards && page.page <= value),
        'cursor-not-allowed': onlyBackwards && page.page > value,
      }"
      class="p-2 w-24 lg:w-32 cursor-pointer"
    >
      <span
        class="inline-block w-full h-1"
        :class="{
          'bg-primary-900':
            page.page !== value &&
            (!onlyBackwards || (onlyBackwards && page.page <= value)),
          'bg-gray-400':
            page.page !== value && onlyBackwards && page.page > value,
          'bg-secondary-500': page.page === value,
        }"
      ></span>
      {{ $t(`${translationNamespace}.${page.title}`) }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    selectedPageIndex: {
      type: Number,
      required: true,
    },
    translationNamespace: {
      type: String,
      required: true,
    },
    onlyBackwards: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
</style>