import './setup';
import Vue from 'vue'
import VueClickOutside from 'v-click-outside';
import PortalVue from 'portal-vue';
import VueScrollTo from 'vue-scrollto';
import VueSweetalert2 from 'vue-sweetalert2';
import VueTailwind from 'vue-tailwind';
import TTable from 'vue-tailwind/dist/t-table';
import TDatepicker from 'vue-tailwind/dist/t-datepicker';
import AlbanianDatepicker from 'vue-tailwind/dist/l10n/sq';
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n';
import { tableTheme, datepickerTheme } from './vue-tailwind';
import VueWiggle from './utils/VueWiggle';

import DefaultLayout from '@l/Default.vue';
import TaperedSection from '@c/TaperedSection.vue';
import DefaultFillHeight from '@c/DefaultFillHeight.vue';
import TextInput from '@c/TextInput.vue';
import SelectInput from '@c/SelectInput.vue';
import FileInput from '@c/FileInput.vue';
import DateInput from '@c/DateInput.vue';
import VuexFlash from '@c/VuexFlash.vue';
import LoadingButton from '@c/LoadingButton.vue';
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';
import Loader from '@c/Loader.vue';
import { Cropper } from 'vue-advanced-cropper'

import './assets/main.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './icons.js';
import 'vue-advanced-cropper/dist/style.css';

Vue.use(VueClickOutside);
Vue.use(PortalVue);
Vue.use(VueWiggle);
Vue.use(VueScrollTo, {
  offset: -74, // - (sticky header + 1rem)
});

const vueTailwindSettings = {
  't-table': {
    component: TTable,
    props: {
      ...tableTheme
    },
  },
  't-datepicker': {
    component: TDatepicker,
    props: {
      ...datepickerTheme,
      locales: {
        al: AlbanianDatepicker,
      },
      lang: i18n.locale,
    },
  },
};

const sweetAlert2Setings = {
  confirmButtonColor: '#EE0000',
  cancelButtonColor: '#ECC94B',
};

Vue.use(VueTailwind, vueTailwindSettings)
Vue.use(VueSweetalert2, sweetAlert2Setings);
Vue.component('default-layout', DefaultLayout);
Vue.component('tapered-section', TaperedSection);
Vue.component('default-fill-height', DefaultFillHeight);
Vue.component('text-input', TextInput);
Vue.component('select-input', SelectInput);
Vue.component('file-input', FileInput);
Vue.component('date-input', DateInput);
Vue.component('vuex-flash', VuexFlash);
Vue.component('loading-button', LoadingButton);
Vue.component('sync-loader', SyncLoader);
Vue.component('loader', Loader);
Vue.component('cropper', Cropper);

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
