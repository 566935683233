<template funcional>
  <tapered-section class="px-4 flex flex-wrap">
    <div
      class="mx-auto xl:ml-12 my-12 border rounded-md border-gray-400 max-w-2xl xl:max-w-lg p-8 bg-white"
    >
      <header class="mb-4">
        <h2>
          {{ $parent.$t(`${$options.tPfx}.header`) }}
        </h2>
      </header>
      <p class="mb-2">{{ $parent.$t(`${$options.tPfx}.generalInfo`) }}</p>
    </div>
    <div
      class="mx-auto xl:mr-12 my-12 border rounded-md border-gray-400 max-w-2xl xl:max-w-lg p-8 bg-white"
    >
      <header class="mb-4">
        <h2>
          {{ $parent.$t(`${$options.tPfx}.handledByTheGovernment`) }}
        </h2>
      </header>
      <p class="mb-2">
        {{ $parent.$t(`${$options.tPfx}.duringTheApplication`) }}
      </p>
      <ul class="list-outside list-disc">
        <li class="mb-1 ml-6">
          {{ $parent.$t(`${$options.tPfx}.duringTheApplicationNote1`) }}
        </li>
        <li class="mb-1 ml-6">
          {{ $parent.$t(`${$options.tPfx}.duringTheApplicationNote2`) }}
        </li>
        <li class="mb-1 ml-6">
          {{ $parent.$t(`${$options.tPfx}.duringTheApplicationNote3`) }}
        </li>
        <li class="mb-1 ml-6">
          {{ $parent.$t(`${$options.tPfx}.duringTheApplicationNote4`) }}
        </li>
      </ul>
    </div>
  </tapered-section>
</template>

<script>
export const translationPrefix = "view.apply.eVisaInformation";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      header:
        "e-Visa is an online application system developed by the albanian government to pre-screen travelers wishing to visit Albania.",
      generalInfo:
        "Applicants have full access to the e-Visa application system. They must complete the e-Visa process before traveling to Albania by air, land or sea.",
      timeDuration:
        "e-Visa is valid for up to two years from the date of issue or until the date on which the passport expires (whichever comes first). After Travel Authorization expires, applications are welcome to re-apply and renew their e-Visa for another two year period.",
      handledByTheGovernment:
        "The e-Visa application process can be completed entirely online and is operated by Albania Government.",
      duringTheApplication: "During the e-Visa application process:",
      duringTheApplicationNote1:
        "Applicants are required to enter biographical information about themselves",
      duringTheApplicationNote2:
        "They will also need to answer questions about VWP eligibility",
      duringTheApplicationNote3:
        "People with dual nationality should choose the nationality according to the passport to be used for the travel",
      duringTheApplicationNote4:
        "Travel Authorization is also required by children who do not yet require a ticket of their own to travel",
    },
    al: {
      header:
        "e-Visa është një sistem aplikimi online i ndërtuar nga qeveria shqiptare për të filtruar udhëtarët që dëshirojnë të vizitojnë Shqipërinë.",
      generalInfo:
        "Aplikantët kanë akses të plotë në sistemin e aplikimit e-Visa. Ata duhet të plotësojnë procesin e e-Visa përpara se të udhëtojne drejt Shqipërisë nëpërmjet ajrit, tokës ose detit.",
      timeDuration:
        "e-Visa është e vlefshme për dy vjet nga data e lëshimit ose deri në datën e skadimit të pasaportës(aplikohet data më e afërt nga të dyja). Pas skadimit të autorizimit për udhëtim, aplikantët janë të mirëpritur të ri-aplikojnë dhe të rinovojnë vizën e tyre për një peridhë tjetër dy vjeçare.",
      handledByTheGovernment:
        " Procesi i aplikimit e-Visa mund të përfundojë i gjithi online dhe mbikqyret nga qeveria shqiptare.",
      duringTheApplication: "Gjatë procesit të aplikimit:",
      duringTheApplicationNote1:
        "Aplikantët janë te detyruar te japin informacion biografik për veten",
      duringTheApplicationNote2:
        "Ata gjithashtu duhet t’iu përgjigjen pyetjeve rreth VWP",
      duringTheApplicationNote3:
        "Aplikantët me dy nacionalitete duhet të zgjedhin nacionalitetin e pasaportës që do të perdorin në udhëtim",
      duringTheApplicationNote4:
        "Autorizimi për udhëtim është i detyrueshëm edhe për fëmiijët të cilët nuk kanë nevojë për biletë që të udhëtojnë",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>

<style>
</style>