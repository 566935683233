<template>
  <div class="mx-auto max-w-md">
    <div class="rounded-md bg-white">
      <pages-modal
        v-show="modalIsOpen"
        @input="$emit('input', $event)"
        @close="modalIsOpen = false"
        :value="value"
        :pages="pages"
        :translation-namespace="translationNamespace"
        :only-backwards="onlyBackwards"
      />
      <div class="border-2 rounded-md border-primary-900">
        <button
          @click="conditionallyOpenModal(showOpenModalInTheBegining)"
          class="block p-2 w-full rounded-t-sm text-primary-900 text-center cursor-pointer"
          :class="{ 'h-10 no-further-options': !showOpenModalInTheBegining }"
        >
          {{ showOpenModalInTheBegining ? "..." : "" }}
        </button>
        <button
          @click="$emit('input', page.page)"
          v-for="page in pagesToRender"
          :key="page.page"
          :class="{
            'bg-secondary-500 text-white': page.page === value,
            'text-primary-900': page.page !== value,
            'bg-gray-300 cursor-not-allowed':
              onlyBackwards && page.page > value,
          }"
          class="block p-2 w-full text-center cursor-pointer"
        >
          {{ $t(`${translationNamespace}.${page.title}`) }}
        </button>
        <button
          @click="conditionallyOpenModal(showOpenModalInTheEnd)"
          class="block p-2 w-full rounded-b-sm text-primary-900 text-center cursor-pointer"
          :class="{ 'h-10 no-further-options': !showOpenModalInTheEnd }"
        >
          {{ showOpenModalInTheEnd ? "..." : "" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PagesModal from "./PagesMobile/PagesModal.vue";

export default {
  components: { PagesModal },
  props: {
    pages: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    selectedPageIndex: {
      type: Number,
      required: true,
    },
    translationNamespace: {
      type: String,
      required: true,
    },
    onlyBackwards: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    modalIsOpen: false,
  }),
  computed: {
    showOpenModalInTheBegining() {
      return this.selectedPageIndex - 2 >= 0;
    },
    showOpenModalInTheEnd() {
      return this.pages.length - this.selectedPageIndex - 3 >= 0;
    },
    pagesToRender() {
      if (this.selectedPageIndex === 0) {
        return [this.pages[0], this.pages[1], this.pages[2]];
      }

      if (this.selectedPageIndex === this.pages.length - 1) {
        const lastPos = this.selectedPageIndex;

        return [
          this.pages[lastPos - 2],
          this.pages[lastPos - 1],
          this.pages[lastPos],
        ];
      }

      const currPos = this.selectedPageIndex;

      return [
        this.pages[currPos - 1],
        this.pages[currPos],
        this.pages[currPos + 1],
      ];
    },
  },
  methods: {
    conditionallyOpenModal(shouldOpen) {
      if (shouldOpen) {
        this.modalIsOpen = true;
      }
    },
  },
};
</script>

<style scoped>
.no-further-options {
  background: repeating-linear-gradient(
    45deg,
    #718096,
    #e2e8f0 10px,
    #718096 10px,
    #e2e8f0 20px
  );
}
</style>