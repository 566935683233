<template>
  <div class="mb-4">
    <router-link
      class="block text-right md:text-left text-secondary-500 hover:underline cursor-pointer"
      :to="{ name: 'Applications' }"
    >
      {{ $t(`${$options.tPfx}.backToApplicationsList`) }}
    </router-link>
    <router-link
      class="block text-right md:text-left text-secondary-500 hover:underline cursor-pointer"
      :to="{ name: 'Questionary', params: { applicationId } }"
    >
      {{ $t(`${$options.tPfx}.reviewApplication`) }}
    </router-link>
  </div>
</template>

<script>
export const translationPrefix =
  "view.payment.applicationLinks.uploadProofOfPayment";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      backToApplicationsList: "Back to the list of applications",
      reviewApplication: "Review your application",
    },
    al: {
      backToApplicationsList: "Kthehuni tek lista e aplikimeve",
      reviewApplication: "Rishikoni aplikimin tuaj",
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    applicationId: {
      type: [Number, String],
      required: true,
    },
  },
};
</script>

<style>
</style>