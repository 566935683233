<template functional>
  <div
    v-bind="data.attrs"
    :class="[data.class, data.staticClass]"
    class="fill-default-layout-height"
  >
    <div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.fill-default-layout-height {
  min-height: calc(100vh - 530px);
}
</style>