import QUESTION_TYPE_ENUM from "./questionTypeEnum";

const questionTypeToComponent = (questionType) => {
  switch (questionType) {
    case QUESTION_TYPE_ENUM.date:
      return 'date-input'
    case QUESTION_TYPE_ENUM.textarea: // TODO
    case QUESTION_TYPE_ENUM.input:
      return 'text-input'
    case QUESTION_TYPE_ENUM.radio: // TODO
    case QUESTION_TYPE_ENUM.select:
      return 'select-input'
  }
};

export default questionTypeToComponent;
