<template>
  <div class="p-2 sm:px-0">
    <router-link
      v-for="(link, i) in linksWithLoggedInChecked"
      :key="i"
      :to="link.to"
      @click.native="$emit('navigate')"
      class="menu"
    >
      {{ $t(`${$options.tPfx}.${link.text}`) }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { module as authModule } from "@/store/auth";

export const translationPrefix = "layout.default.navbar.menus";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      home: "Home",
      apply: "Apply",
      track: "Track",
      applications: "Applications",
    },
    al: {
      home: "Kryefaqja",
      apply: "Apliko",
      track: "Gjurmo",
      applications: "Aplikimet",
    },
  },
};

export default {
  tPfx: translationPrefix,
  data: () => ({
    links: [
      { text: "home", to: { name: "Home" } },
      { text: "apply", to: { name: "Apply" } },
      { text: "track", to: { name: "Track" } },
    ],
  }),
  computed: {
    ...mapGetters(authModule, ["isLoggedIn"]),
    linksWithLoggedInChecked() {
      let additionalLinks = [];

      if (this.isLoggedIn) {
        additionalLinks = [
          //{ text: "applications", to: { name: "Applications" } },
        ];
      }

      return [...this.links, ...additionalLinks];
    },
  },
};
</script>