<template>
  <tapered-section class="my-12 px-4 flex flex-wrap">
    <header class="w-full text-center my-4">
      <h2>
        {{ $t(`${$options.tPfx}.phases`) }}
      </h2>
    </header>
    <first-phase />
    <second-phase />
    <notes />
  </tapered-section>
</template>

<script>
import FirstPhase from "./ProcessPhases/FirstPhase.vue";
import Notes from "./ProcessPhases/Notes.vue";
import SecondPhase from "./ProcessPhases/SecondPhase.vue";

export const translationPrefix = "view.apply.processPhases";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      phases: "Application phases",
    },
    al: {
      phases: "Fazat e aplikimit",
    },
  },
};

export default {
  components: { FirstPhase, SecondPhase, Notes },
  tPfx: translationPrefix,
};
</script>

<style>
</style>