<template>
  <article class="border rounded-md border-gray-400 p-4 md:p-8 bg-white">
    <header class="w-full mb-4">
      <h2 class="text-center">{{ $t(`${$options.tPfx}.documents`) }}</h2>
    </header>
    <section class="flex flex-wrap items-end">
      <document
        v-for="(documentCategory, index) of documentCategories"
        :key="documentCategory.id"
        :index="index + 1"
        :application-id="applicationId"
        :document-category="documentCategory"
        :disabled="disabled"
        :show-comment="showComments"
        @uploaded="$emit('document-uploaded', $event)"
        @deleted="$emit('document-deleted', $event)"
        @size-error="$emit('document-size-error', $event)"
        @type-error="$emit('document-type-error', $event)"
        class="w-full lg:w-1/2"
      />
    </section>
  </article>
</template>

<script>
import Document from "./Documents/Document.vue";

export const translationPrefix = "view.questionary.documents";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      documents: "Upload documents",
    },
    al: {
      documents: "Ngarkoni dokumentat",
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: { Document },
  props: {
    applicationId: {
      type: [Number, String],
      required: true,
    },
    documentCategories: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    showComments: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
</style>
