<template functional>
  <portal to="after-page-title">
    <div :title="props.title" class="inline ml-2">
      <font-awesome-icon icon="info-circle" />
    </div>
    <span class="ml-3">({{props.visaLabel}})</span>
  </portal>
</template>

<script>
export default {
  props: {
    title: String,
    visaLabel: String,
  },
};
</script>

<style>
</style>
