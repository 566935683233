<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section class="py-24 px-4">
      <loader
          v-if="isLoading"
          :loading="isLoading"
          size="1.5rem"
          height="6rem"
      />
      <div class="flex justify-center">
        <article
          class="rounded-md border border-gray-400 p-12 max-w-lg w-full space-y-8 bg-white"
        >
          <header>
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {{ $t(`${$options.tPfx}.signInTitle`) }}
            </h2>
          </header>
          <form class="mt-8 space-y-6">
            <input type="hidden" name="remember" value="true" />
            <section class="rounded-md shadow-sm -space-y-px">
              <text-input
                  v-model="form.email"
                  :label="$t(`${$options.tPfx}.email`)"
                  :placeholder="$t(`${$options.tPfx}.email`)"
                  :mb="0"
                  id="email-address"
                  name="email"
                  labelClass="sr-only"
                  inputClass="rounded-b-none rounded-t-md"
                  required
              />
              <text-input
                  v-model="form.password"
                  :placeholder="$t(`${$options.tPfx}.password`)"
                  :label="$t(`${$options.tPfx}.password`)"
                  :mb="0"
                  id="password"
                  name="password"
                  labelClass="sr-only"
                  inputClass="rounded-t-none rounded-b-md"
                  type="password"
                  required
              />
            </section>

            <section class="flex items-center justify-between">
              <div class="flex items-center">
                <input
                    id="remember_me"
                    type="checkbox"
                    class="h-4 w-4 text-secondary-600 focus:ring-secondary-500 border-gray-400 rounded"
                />
                <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                  {{ $t(`${$options.tPfx}.rememberMe`) }}
                </label>
              </div>

              <div class="text-sm text-right">
                <a
                    @click="forgotPassword"
                    class="font-medium text-secondary-500 hover:text-secondary-700 cursor-pointer"
                >
                  {{ $t(`${$options.tPfx}.forgotPassword`) }}
                </a>
              </div>
            </section>

            <section>
              <button
                  type="submit"
                  class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-secondary-500 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                  @click.prevent="login"
              >
              <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: lock-closed -->
                <svg
                    class="h-5 w-5 text-secondary-400 group-hover:text-secondary-200"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                  <path
                      fill-rule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clip-rule="evenodd"
                  />
                </svg>
              </span>
                {{ $t(`${$options.tPfx}.signIn`) }}
              </button>
            </section>
          </form>
        </article>
      </div>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { mapActions } from "vuex";
import { actions as authActions, module as authModule } from "@/store/auth";
import { actions, module as flashModule } from "@/store/flash";
import DefaultFillHeight from "@/components/DefaultFillHeight.vue";
import {User} from "@/api";

export const translationPrefix = "view.login";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      signInTitle: "Sign in to your account",
      email: "Email address",
      password: "Password",
      rememberMe: "Remember me",
      forgotPassword: "Forgot your password?",
      signIn: "Sign in",
      wrongLoginCredentials: "Invalid email or password!",
      forgotPasswordSuccess: "Please check your email.",
      forgotPasswordValidationError: "Email not found! Please check your credentials.",
      forgotPasswordFailedLine1: "Something went wrong!",
      forgotPasswordFailedLine2: "Please try again later!",
    },
    al: {
      signInTitle: "Kyçuni në llogarinë tuaj",
      email: "Adresa email",
      password: "Fjalëkalimi",
      rememberMe: "Më mbaj mend",
      forgotPassword: "Harruat fjalëkalimin?",
      signIn: "Kyçu",
      wrongLoginCredentials: "Email ose fjalëkalimi nuk është i saktë!",
      forgotPasswordSuccess: "Ju lutem kontrolloni email-in tuaj.",
      forgotPasswordValidationError: "Email nuk u gjet! Ju lutem kontrolloni të dhënat.",
      forgotPasswordFailedLine1: "Diçka shkoi keq!",
      forgotPasswordFailedLine2: "Provoni përsëri më vonë!",
    },
  },
};

export default {
  components: { DefaultFillHeight },
  tPfx: translationPrefix,
  beforeRouteLeave(_to, _from, next) {
    this.dispatchDismissFlash();
    next();
  },
  props: {
    redirectToOnLogin: String,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        device_name: "browser",
      },
      isLoading: false,
      errors: {},
    };
  },
  methods: {
    ...mapActions(authModule, {
      dispatchAuthenticate: authActions.authenticate,
    }),
    ...mapActions(flashModule, {
      dispatchDismissFlash: actions.dismiss,
      dispatchShowFlash: actions.show,
    }),
    async login() {
      try {
        await this.dispatchAuthenticate(this.form);

        const target = this.redirectToOnLogin || { name: "Home" };

        await this.$router.push(target);
      } catch (error) {
        if (error.response.status === 422) {
          await this.dispatchShowFlash({
            class:
              "sm:block md:inline-block border-secondary-500 bg-secondary-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
            iconClass: "hover:text-secondary-500",
            text: `${this.$options.tPfx}.wrongLoginCredentials`,
            textClass: "avoid-warp",
          });
        } else {
          await this.$router.push({ name: "SomethingWentWrong" });
        }
      }
    },
    async forgotPassword() {
      this.isLoading = true;
      try {
        await User.forgotPassword({email: this.form.email});

        await this.dispatchShowFlash({
          class:
              "sm:block md:inline-block border-green-500 bg-green-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
          iconClass: "hover:text-green-500",
          text: [
            `${this.$options.tPfx}.forgotPasswordSuccess`,
          ],
          textClass: ["avoid-warp", "avoid-wrap"],
        });
      } catch (error) {
        if (error.response.status === 422) {
          return await this.dispatchShowFlash({
            class:
                "sm:block md:inline-block border-red-500 bg-red-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
            iconClass: "hover:text-red-500",
            text: [
              `${this.$options.tPfx}.forgotPasswordValidationError`,
            ],
            textClass: ["avoid-warp", "avoid-wrap"],
          });
        }

        await this.dispatchShowFlash({
          class:
              "sm:block md:inline-block border-red-500 bg-red-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
          iconClass: "hover:text-red-500",
          text: [
            `${this.$options.tPfx}.forgotPasswordFailedLine1`,
            `${this.$options.tPfx}.forgotPasswordFailedLine2`,
          ],
          textClass: ["avoid-warp", "avoid-wrap"],
        });
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
