<template>
  <div>
    <loader
      v-if="isLoadingPaymentDetail"
      :loading="isLoadingPaymentDetail"
      size="1.5rem"
      margin="4px"
    />
    <article
      v-else
      class="border border-gray-400 rounded-md p-4 md:p-8 flex flex-wrap items-start bg-white"
    >
      <div class="w-full md:w-1/2 p-4 lg:p-8">
        <header class="mb-4">
          <h3 class="text-center">
            {{ $t(`${$options.tPfx}.waitingForPayment`) }}
          </h3>
        </header>
        <table class="w-full">
          <tbody class="divide-y-2">
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.amount`) }}
              </th>
              <td class="text-right">{{ paymentDetails.amount }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.accountHolder`) }}
              </th>
              <td class="text-right">{{ paymentDetails.accountHolder }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.bank`) }}
              </th>
              <td class="text-right">{{ paymentDetails.bank }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                IBAN
              </th>
              <td class="text-right">{{ paymentDetails.accountIBAN }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.accountNumber`) }}
              </th>
              <td class="text-right">{{ paymentDetails.accountNumber }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.swiftCode`) }}
              </th>
              <td class="text-right">{{ paymentDetails.swiftCode }}</td>
            </tr>
            <tr>
              <th
                  class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.institutionCode`) }}
              </th>
              <td class="text-right">{{ paymentDetails.institutionCode }}</td>
            </tr>
            <tr>
              <th
                  class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.economicAccountCode`) }}
              </th>
              <td class="text-right">{{ paymentDetails.economicAccountCode }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.institutionCode`) }}
              </th>
              <td class="text-right">{{ paymentDetails.institutionCode }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.economicAccountCode`) }}
              </th>
              <td class="text-right">{{ paymentDetails.economicAccountCode }}</td>
            </tr>
            <tr>
              <th
                class="text-left text-sm font-semibold text-gray-600 uppercase"
              >
                {{ $t(`${$options.tPfx}.referenceNumber`) }}
              </th>
              <td class="text-right">{{ paymentDetails.referenceNumber }}</td>
            </tr>
            <tr>
              <td colspan="2" class="pt-4 text-sm text-secondary-500 italic">
                {{ $t(`${$options.tPfx}.attention`) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="w-full md:w-1/2 pt-16 px-4 pb-4 lg:px-8 lg:pb-8">
        <application-links :application-id="applicationId" />
        <div>
          <file-input
            deletable
            accept="application/pdf, image/jpeg, image/png"
            file-name="proofOfPayment"
            :max-size="31457280"
            :disabled="disabled"
            :label="$t(`${$options.tPfx}.proofOfPayment`)"
            :upload-hint="$t(`${$options.tPfx}.uploadHint`)"
            :upload-url="uploadUrl"
            :delete-url="deleteUrl"
            :origin="documentOrigin"
            :headers="authorizationHeader"
            :reduce-response="(response) => response.data.data.proofOfPayment"
            :error="localizedError"
            :value="proofOfPayment"
            @input="onDocumentAdded"
            @upload-error="toDo"
            @deleted="onDocumentRemoved"
            @delete-error="toDo"
            @file-size-error="fileError = 'maxSizeError'"
            @file-type-error="fileError = 'onlyValidFormat'"
            class="mb-2"
          />
        </div>
        <button
          @click="finalizePayment"
          :disabled="disabled"
          class="button button-secondary w-full md:w-auto"
        >
          {{ $t(`${$options.tPfx}.finalizePayment`) }}
        </button>
      </div>
    </article>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { module as authModule } from "@/store/auth";
import { Application } from "@/api";
import ApplicationLinks from "./ApplicationLinks.vue";

export const translationPrefix = "view.payment.uploadProofOfPayment";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      waitingForPayment: "We are waiting for your transfer",
      amount: "Amount",
      accountHolder: "Account holder",
      bank: "Bank",
      accountNumber: "Account number",
      swiftCode: "Swift code",
      referenceNumber: "Reference number",
      proofOfPayment: "Payment receipt",
      uploadHint: "Select or drop payment receipt",
      onlyValidFormat: "Only pdf, jpeg or png format is accepted",
      maxSizeError: "Max size is 30MB",
      isRequired: "Payment receipt is required",
      localizedFileName: "Proof of Payment",
      finalizePayment: "Finalize payment",
      institutionCode: "Institution code",
      economicAccountCode: "Economic account code",
      attention:
        "Please include your ID/Passport number and Reference number in the transaction",
    },
    al: {
      waitingForPayment: "Ne po presim pagesën tuaj",
      amount: "Shuma",
      accountHolder: "Mbajtësi i llogarisë",
      bank: "Banka",
      accountNumber: "Numri i llogarisë",
      swiftCode: "Kodi Swift",
      referenceNumber: "Numri i referencës",
      proofOfPayment: "Mandati i pagesës",
      uploadHint: "Zgjidh ose tërhiq mandatin e pagesës",
      onlyValidFormat: "Pranohet vetëm formati pdf, jpeg ose png",
      maxSizeError: "Madhësia maksimale është 30MB",
      isRequired: "Mandati i pagesës është i detyruar",
      localizedFileName: "Mandati i pagesës",
      finalizePayment: "Finalizo pages",
      institutionCode: "Kodi i institucionit",
      economicAccountCode: "Kodi i llogarisë ekonomike",
      attention:
        "Ju lutem përfshini numrin tuaj të ID ose Pashaportës dhe numrin e refrenës në transaction",
    },
  },
};

export default {
  components: { ApplicationLinks },
  tPfx: translationPrefix,
  props: {
    applicationId: {
      type: [String, Number],
      required: true,
    },
    paymentIsSucessful: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isLoadingPaymentDetail: false,
    paymentDetails: {},
    fileError: null,
  }),
  computed: {
    ...mapState(authModule, ["token"]),
    authorizationHeader() {
      return {
        Authorization: `Bearer ${this.token}`,
      };
    },
    disabled() {
      return (
        this.paymentIsSucessful
      );
    },
    documentOrigin() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    uploadUrl() {
      return `${this.documentOrigin}/me/applications/${this.applicationId}/payment`;
    },
    deleteUrl() {
      return `${this.documentOrigin}/me/applications/${this.applicationId}/payment`;
    },
    localizedError() {
      if (!this.fileError) {
        return null;
      }

      return this.$t(`${this.$options.tPfx}.${this.fileError}`);
    },
    proofOfPayment() {
      if (!this.paymentDetails.proofOfPayment) {
        return null;
      }

      const { name, ...file } = this.paymentDetails.proofOfPayment;
      file.name = name[this.$i18n.locale];

      return file;
    },
  },
  methods: {
    async handleLoadPaymentDetailErrors(error) {
      switch (error.response.status) {
        case 404:
          return this.$router.replace({ name: "NotFound" });
        case 500:
          return this.$router.replace({ name: "SomethingWentWrong" });
        case 409:
        case 403:
          return this.$router.push({ name: "Applications" });
      }
    },
    async loadPaymentDetails() {
      this.isLoadingPaymentDetail = true;

      try {
        const {
          data: paymentDetailsResponse,
        } = await Application.getPaymentDetails(this.applicationId);

        this.paymentDetails = paymentDetailsResponse.data.paymentDetails;
      } catch (error) {
        await this.handleLoadPaymentDetailErrors(error);
      } finally {
        this.isLoadingPaymentDetail = false;
      }
    },
    // TODO: Implete logic for handling upload and delete failure
    toDo() {
      console.log("upload or delete failed");
    },
    onDocumentAdded(proofOfPayment) {
      this.fileError = null;

      this.paymentDetails.proofOfPayment = proofOfPayment;
    },
    onDocumentRemoved() {
      this.paymentDetails.proofOfPayment = null;
    },
    async finalizePayment() {
      if (!this.proofOfPayment) {
        this.fileError = "isRequired";
        return;
      }

      await Application.finalizePayment(this.applicationId);
      await this.$router.replace({ name: "Applications" });
    },
  },
  async mounted() {
    await this.loadPaymentDetails();
  },
};
</script>

<style>
</style>
