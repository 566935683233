import store from '@/store';
import { module as authModule } from '@/store/auth';

export default {
	onFulfilled: (config) => {
		const token = store.state[authModule].token;

		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	onRejected: (err) => Promise.reject(err),
}