<template>
  <section
    class="pr-2"
    :class="documentCategories.length > 9 ? 'pl-8' : 'pl-6'"
  >
    <ol class="list-decimal list-outside">
      <li class="mb-2">
        {{ $t(`${$options.tPfx}.onlineForm`) }}
      </li>
      <li
        v-for="documentCategory in documentCategories"
        :key="documentCategory.id"
        class="mb-2"
      >
        {{ documentCategory.title[$i18n.locale] }}
      </li>
    </ol>
  </section>
</template>

<script>
export const translationPrefix = "view.apply.visas.visaTypes.visaType.documentCategory";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      onlineForm: "Visa application form, completed by the applicant, which is available online free of charge (the application form is completed online during the application, is generated and downloaded by the sistem)",
    },
    al: {
      onlineForm: "Formulari i aplikimit për vizë, i plotësuar nga aplikuesi, i cili gjenerohet online pa pagesë (formulari plotësohet online gjatë aplikimit dhe gjenerohet e shkarkohet nga sistemi)",
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    documentCategories: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>