const withDelay = (to, _from, savedPosition) => {
  if (to.hash) {
    return new Promise((resolve) => {
      setTimeout(() => resolve({ selector: to.hash, offset: { x: 0, y: 74 }, behavior: 'smooth' }), to.query.scrollDelay);
    });
  } else if (savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => resolve({ ...savedPosition, behavior: 'smooth' }), to.query.scrollDelay);
    });
  }

  return new Promise((resolve) => {
    setTimeout(() => resolve({ x: 0, y: 0, behavior: 'smooth' }), to.query.scrollDelay);
  });
}

const withoutDelay = (to, _from, savedPosition) => {
  if (to.hash) {
    return {
      selector: to.hash,
      behavior: 'smooth',
    }
  } else if (savedPosition) {
    return { ...savedPosition, behavior: 'smooth' };
  }

  return { x: 0, y: 0, behavior: 'smooth' }
}

const scrollBehavior = (to, _from, savedPosition) => {
  if (to.query.scrollDelay) {
    return withDelay(to, _from, savedPosition);
  }

  return withoutDelay(to, _from, savedPosition);
}

export default scrollBehavior;
