<template functional>
  <router-link :to="props.to">
    <article class="w-64 md:w-56 lg:w-64 min-h-80 flex flex-col">
      <header class="py-2 bg-primary-900 text-center text-white rounded-lg">
        <h2>
          {{ props.title }}
        </h2>
      </header>
      <section
        class="flex-1 flex flex-col justify-center py-4 bg-white text-center rounded-lg"
      >
        <img
          :src="props.imageSrc"
          :alt="`${props.title} Icon`"
          class="mx-auto"
        />
        <p class="mt-6 mb-2 text-lg font-bold">
          {{ props.description }}
        </p>
      </section>
    </article>
  </router-link>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
      default: "#",
      validator: () => true, // TODO: Validate vue router object
    },
  },
};
</script>