<template>
  <div class="p-2">
    <div
      class="h-full flex flex-col flex-wrap p-2"
      :class="{
        'border border-secondary-500 rounded-md justify-between':
          showComment && comment,
        'justify-end': !showComment || !comment,
      }"
    >
      <span
        v-if="showComment && comment"
        class="mb-4 italic text-xs text-secondary-500"
      >
        <font-awesome-icon icon="info-circle" class="fa-sm"></font-awesome-icon>
        {{ $t(`${$options.tPfx}.comment`) }}:
        {{ comment[$i18n.locale] }}
      </span>
      <file-input
        deletable
        show-description
        :disabled="disabled"
        :description="capitalizedDescription"
        :has-invitation-document="questionCodesWithInviteDocument.includes(documentCategory.code)"
        :label="localizedLabel"
        :upload-hint="$t(`${$options.tPfx}.uploadHint`)"
        :accept="accept"
        :max-size="documentCategory.maxSize"
        :upload-url="uploadUrl"
        :delete-url="deleteUrl"
        :origin="documentOrigin"
        :headers="authorizationHeader"
        :additional-post-data="additionalPostData"
        :reduce-response="(response) => response.data.data.document"
        :error="localizedError"
        :value="document"
        :close="$t(`${$options.tPfx}.close`)"
        :confirm="$t(`${$options.tPfx}.confirm`)"
        :cropImage="documentCategory.id === 5"
        @input="onDocumentAdded"
        @upload-error="toDo"
        @deleted="onDocumentRemoved"
        @delete-error="toDo"
        @file-size-error="onDocumentSizeError"
        @file-type-error="onDocumentTypeError"
        file-name="document"
      />
    </div>
  </div>
</template>

<script>
import mime from "mime-types";
import { mapState } from "vuex";
import { module as authModule } from "@/store/auth";
import sizeToHumanReadable from "../helpers/sizeToHumanReadable";
import QUESTION_CODE_ENUM from "@v/Questionary/helpers/questionCodeEnum";

export const translationPrefix = "view.questionary.documents.document";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      uploadHint: "Select or drop file to upload",
      documentInvalidType:
        "File type {type} is invalid. Only {acceptReadable} is allowed. | File type is invalid. Only {acceptReadable} are allowed.",
      documentInvalidSize:
        "Document size is invalid. Max size is {maxSizeReadable}.",
      isRequired: "Document is required",
      close: 'Close',
      confirm: 'Confirm',
      isRequiredUnder18:
        "Parental or legal guardian approval is required for minors under 18 years old",
      comment: "Comment",
    },
    al: {
      uploadHint: "Zgjidh ose tërhiq dokument",
      documentInvalidType:
        "Lloji i dokumentit {type} nuk është i vlefshëm. Lejohet vetëm lloji {acceptReadable} | Lejohet vetëm llojet {acceptReadable}.",
      documentInvalidSize:
        "Madhësia e dokumentit nuk është e vlefshme. Madhësia maksimale është {maxSizeReadable}.",
      isRequired: "Dokumenti nuk mund të jetë bosh",
      close: 'Mbyll',
      confirm: 'Konfirmo',
      isRequiredUnder18:
        "Deklarata e pëlqimit të prindërit ose kujdestarit ligjor është e detyrueshme për fëmijët nën 18 vjeç",
      comment: "Koment",
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    applicationId: {
      type: [Number, String],
      required: true,
    },
    documentCategory: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    showComment: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      questionCodesWithInviteDocument: [
        QUESTION_CODE_ENUM.visaTypeAHostQuestionCode,
        QUESTION_CODE_ENUM.invitationFromTheScientificSubject,
        QUESTION_CODE_ENUM.invitationFromHostInstitution,
        QUESTION_CODE_ENUM.invitationFromHostInstitutionWhenNecessary,
      ]
    }
  },
  computed: {
    ...mapState(authModule, ["token"]),
    allowedTypes() {
      return this.documentCategory.allowedTypes;
    },
    humanReadableMaxSize() {
      return sizeToHumanReadable(this.documentCategory.maxSize, 0);
    },
    accept() {
      return this.documentCategory.allowedTypes.reduce(
        (concatedStrings, type) =>
          concatedStrings
            ? `${concatedStrings}, ${mime.types[type]}`
            : mime.types[type],
        ""
      );
    },
    authorizationHeader() {
      return {
        Authorization: `Bearer ${this.token}`,
      };
    },
    documentOrigin() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    uploadUrl() {
      return `${this.documentOrigin}/me/applications/${this.applicationId}/documents`;
    },
    deleteUrl() {
      const documentId = this.documentCategory.documents[0]
        ? this.documentCategory.documents[0].id
        : null;

      if (!documentId) {
        return "";
      }

      return `${this.documentOrigin}/me/applications/${this.applicationId}/documents/${documentId}`;
    },
    additionalPostData() {
      return {
        documentCategoryId: this.documentCategory.id,
      };
    },
    typeErrorText() {
      const count = this.allowedTypes.length;
      const type = this.allowedTypes.join(", ");

      return this.$tc(`${this.$options.tPfx}.documentInvalidType`, count, {
        type,
      });
    },
    document() {
      if (!this.documentCategory.documents[0]) {
        return null;
      }

      // Change document name to category, so that it makes more sense for user
      const documentToBind = this.documentCategory.documents[0];
      documentToBind.name = this.documentCategory.title[this.$i18n.locale];

      return documentToBind;
    },
    localizedError() {
      if (!this.documentCategory.error) {
        return null;
      }

      return this.$tc(
        `${this.$options.tPfx}.${this.documentCategory.error}`,
        this.documentCategory.errorCount,
        this.documentCategory.errorParams
      );
    },
    localizedLabel() {
      let translatedLabel = this.documentCategory.title[this.$i18n.locale];

      const hasAsterix = translatedLabel.includes("*");
      if (this.documentCategory.isRequired && !hasAsterix) {
        translatedLabel = `${translatedLabel} *`;
      }

      return `${this.index} ${translatedLabel}`;
    },
    capitalizedDescription() {
      const description = this.documentCategory.description[this.$i18n.locale];

      return description.charAt(0).toUpperCase() + description.slice(1);
    },
    comment() {
      const comments = this.documentCategory.comments;

      if (!comments[0]) {
        return;
      }

      return this.documentCategory.comments[0].comment;
    },
  },
  methods: {
    toDo() {
      console.log("error handling when api calls fail!");
    },

    async onDocumentAdded(document) {
      this.$emit("uploaded", {
        document,
        documentCategory: this.documentCategory,
      });
    },

    onDocumentRemoved() {
      this.$emit("deleted", {
        documentCategory: this.documentCategory,
      });
    },

    onDocumentSizeError(error) {
      this.$emit("size-error", {
        error,
        count: 1,
        documentCategory: this.documentCategory,
      });
    },
    onDocumentTypeError(error) {
      this.$emit("type-error", {
        error,
        count: this.allowedTypes.length,
        documentCategory: this.documentCategory,
      });
    },
  },
};
</script>

<style>
</style>
