<template>
  <div class="inline-flex w-full">
    <inline-user-profile :user="user" @sign-out="logout" class="md:hidden" />
    <notifications class="hidden md:block" />
    <dropdown-user-profile
      :user="user"
      @sign-out="logout"
      class="hidden md:block"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actions, module as authModule } from "@/store/auth";

import InlineUserProfile from "./UserProfile/InlineUserProfile.vue";
import DropdownUserProfile from "./UserProfile/DropdownUserProfile.vue";
import Notifications from "./UserProfile/Notifications.vue";

export default {
  components: {
    InlineUserProfile,
    DropdownUserProfile,
    Notifications,
  },
  computed: {
    ...mapState(authModule, ["user"]),
  },
  methods: {
    ...mapActions(authModule, {
      dispatchSignOut: actions.signOut,
      dispatchForceSignOut: actions.forceSignOut,
    }),
    async logout() {
      try {
        await this.dispatchSignOut();
      } catch (error) {
        await this.dispatchForceSignOut();
      }

      const atHomeView = this.$route.matched.some(
        ({ name }) => name === "Home"
      );
      const routeRequiresAuth = this.$route.meta.authOnly;

      if (!atHomeView && routeRequiresAuth) {
        this.$router.push({ name: "Home" });
      }
    },
  },
};
</script>
