import client from "./client";

export default {
  createOrUpdate(applicationId, form) {
    return client.post(`/me/applications/${applicationId}/answers`, form);
  },

  delete(applicationId, answerId) {
    return client.delete(`/me/applications/${applicationId}/answers/${answerId}`);
  }
};
