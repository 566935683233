<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
const defaultLayout = "default";

export default {
  computed: {
    layout() {
      return `${this.$route.meta.layout || defaultLayout}-layout`;
    },
  },
  methods: {
    async notificationsRequestPermission() {
      if ("Notification" in window && Notification.permission === "default") {
        await Notification.requestPermission();
      }
    },
  },
  async mounted() {
    await this.notificationsRequestPermission();
  },
};
</script>