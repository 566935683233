var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"p-2 border-t border-gray-700 sm:px-0 md:border-0"},[(!_vm.parent.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Login';
}))?_c('router-link',{staticClass:"menu md:hidden",attrs:{"to":{ name: 'Login' }},nativeOn:{"click":function($event){return _vm.listeners.navigate($event)}}},[_vm._v(" "+_vm._s(_vm.parent.$t(((_vm.$options.tPfx) + ".login")))+" ")]):_vm._e(),(!_vm.parent.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Register';
}))?_c('router-link',{staticClass:"menu md:hidden",attrs:{"to":{ name: 'Register' }},nativeOn:{"click":function($event){return _vm.listeners.navigate($event)}}},[_vm._v(" "+_vm._s(_vm.parent.$t(((_vm.$options.tPfx) + ".register")))+" ")]):_vm._e(),(!_vm.parent.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Login';
}))?_c('router-link',{staticClass:"hidden md:mx-2 md:button md:button-secondary",attrs:{"to":{ name: 'Login' }},nativeOn:{"click":function($event){return _vm.listeners.navigate($event)}}},[_vm._v(" "+_vm._s(_vm.parent.$t(((_vm.$options.tPfx) + ".login")))+" ")]):_vm._e(),(!_vm.parent.$route.matched.some(function (ref) {
	var name = ref.name;

	return name === 'Register';
}))?_c('router-link',{staticClass:"hidden md:mx-2 md:button md:button-primary",attrs:{"to":{ name: 'Register' }},nativeOn:{"click":function($event){return _vm.listeners.navigate($event)}}},[_vm._v(" "+_vm._s(_vm.parent.$t(((_vm.$options.tPfx) + ".register")))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }