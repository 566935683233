export const tableTheme = {
  classes: {
    table: 'w-full divide-y-4 md:divide-y divide-gray-300 shadow-sm border-gray-200 border',
    thead: '',
    theadTr: '',
    theadTh: 'px-3 py-2 font-semibold text-left bg-gray-100 border-b',
    tbody: 'bg-white divide-y divide-gray-100',
    tr: '',
    td: 'px-3 py-2',
    tfoot: '',
    tfootTr: '',
    tfootTd: '',
  }
}

export const datepickerTheme = {
  fixedClasses: {
    navigator: 'flex',
    navigatorViewButton: 'flex items-center',
    navigatorViewButtonIcon: 'flex-shrink-0 h-5 w-5',
    navigatorViewButtonBackIcon: 'flex-shrink-0 h-5 w-5',
    navigatorLabel: 'flex items-center py-1',
    navigatorPrevButtonIcon: 'h-6 w-6 inline-flex',
    navigatorNextButtonIcon: 'h-6 w-6 inline-flex',

    inputWrapper: 'relative',
    viewGroup: 'inline-flex flex-wrap',
    view: 'w-64',
    calendarDaysWrapper: 'grid grid-cols-7',
    calendarHeaderWrapper: 'grid grid-cols-7',
    monthWrapper: 'grid grid-cols-4',
    yearWrapper: 'grid grid-cols-4',

    clearButton: 'flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6',
    clearButtonIcon: 'fill-current h-3 w-3',
  },
  classes: {
    // Dropdown related classes
    wrapper: 'flex flex-col',
    dropdownWrapper: 'relative z-10',
    dropdown: 'origin-top md:origin-top-left absolute rounded-md shadow bg-white overflow-hidden mt-1',
    enterClass: '',
    enterActiveClass: 'transition ease-out duration-100 transform opacity-0 scale-95',
    enterToClass: 'transform opacity-100 scale-100',
    leaveClass: 'transition ease-in transform opacity-100 scale-100',
    leaveActiveClass: '',
    leaveToClass: 'transform opacity-0 scale-95 duration-75',

    // Wrapper for inline calendar
    inlineWrapper: '',
    inlineViews: 'rounded-md bg-white border mt-1 inline-flex',

    // Text input related classes
    inputWrapper: '',
    input: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-gray-300 border-gray-400 rounded-md focus:border-secondary-500 focus:ring-2 focus:ring-secondary-500 focus:outline-none focus:ring-opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed',
    clearButton: 'group hover:bg-secondary-100 rounded-md transition duration-100 ease-in-out text-gray-600',
    clearButtonIcon: 'group-hover:text-secondary-500',

    // Picker views
    viewGroup: '',
    view: '',

    // Navigator
    navigator: 'pt-2 px-3',
    navigatorViewButton: 'transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-secondary-100',
    navigatorViewButtonIcon: 'fill-current text-gray-400',
    navigatorViewButtonBackIcon: 'fill-current text-gray-400',
    navigatorViewButtonMonth: 'text-gray-700 font-semibold',
    navigatorViewButtonYear: 'text-gray-500 ml-1',
    navigatorViewButtonYearRange: 'text-gray-500 ml-1',
    navigatorLabel: 'py-1',
    navigatorLabelMonth: 'text-gray-700 font-semibold',
    navigatorLabelYear: 'text-gray-500 ml-1',
    navigatorPrevButton: 'group transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-100 rounded-full p-1 ml-2 ml-auto disabled:bg-gray-100 disabled:cursor-not-allowed',
    navigatorNextButton: 'group transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-secondary-100 rounded-full p-1 -mr-1 disabled:bg-gray-100 disabled:cursor-not-allowed',
    navigatorPrevButtonIcon: 'text-gray-400 disabled:text-gray-400 group-hover:text-secondary-500',
    navigatorNextButtonIcon: 'text-gray-400 disabled:text-gray-400 group-hover:text-secondary-500',

    // Calendar View
    calendarWrapper: 'px-3 pt-2',
    calendarHeaderWrapper: '',
    calendarHeaderWeekDay: 'uppercase text-xs text-gray-500 w-8 h-8 flex items-center justify-center',
    calendarDaysWrapper: '',
    calendarDaysDayWrapper: 'w-full h-8 flex flex-shrink-0 items-center',

    // Day item
    otherMonthDay: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-secondary-100 text-secondary-300 disabled:text-gray-400 disabled:cursor-not-allowed',
    emptyDay: '',
    inRangeFirstDay: 'text-sm bg-secondary-500 text-white w-full h-8 rounded-l-full',
    inRangeLastDay: 'text-sm bg-secondary-500 text-white w-full h-8 rounded-r-full',
    inRangeDay: 'text-sm bg-secondary-200 w-full h-8 disabled:text-gray-400 disabled:cursor-not-allowed',
    selectedDay: 'text-sm rounded-full w-8 h-8 mx-auto bg-secondary-500 text-white disabled:text-gray-400 disabled:cursor-not-allowed',
    activeDay: 'text-sm rounded-full bg-secondary-100 w-8 h-8 mx-auto disabled:text-gray-400 disabled:cursor-not-allowed',
    highlightedDay: 'text-sm rounded-full bg-secondary-200 w-8 h-8 mx-auto disabled:text-gray-400 disabled:cursor-not-allowed',
    day: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-secondary-100 disabled:text-gray-400 disabled:cursor-not-allowed',
    today: 'text-sm rounded-full w-8 h-8 mx-auto hover:bg-secondary-100 disabled:text-gray-400 disabled:cursor-not-allowed border border-secondary-500',

    // Months View
    monthWrapper: 'px-3 pt-2',
    selectedMonth: 'text-sm rounded w-full h-12 mx-auto bg-secondary-500 text-white',
    activeMonth: 'text-sm rounded w-full h-12 mx-auto bg-secondary-100',
    month: 'text-sm rounded w-full h-12 mx-auto hover:bg-secondary-100',

    // Years View
    yearWrapper: 'px-3 pt-2',
    year: 'text-sm rounded w-full h-12 mx-auto hover:bg-secondary-100',
    selectedYear: 'text-sm rounded w-full h-12 mx-auto bg-secondary-500 text-white',
    activeYear: 'text-sm rounded w-full h-12 mx-auto bg-secondary-100',
  },
  variants: {
    error: {
      input: 'block w-full px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border border-yellow-500 rounded-md focus:border-secondary-500 focus:ring-2 focus:ring-secondary-500 focus:outline-none focus:ring-opacity-50 disabled:bg-gray-100 disabled:cursor-not-allowed',
      clearButton: 'group hover:bg-yellow-200 rounded-md transition duration-100 ease-in-out text-gray-600',
      clearButtonIcon: 'group-hover:text-yellow-500',
    }
  }
};
