<template functional>
  <router-link :to="{ name: 'Track' }">
    <button
        class="rounded-md px-6 py-4 bg-secondary-500 font-bold text-dark focus:outline-none focus:border focus:border-gray-700"
        :class="[data.class, data.staticClass]"
    >
      {{ parent.$t(`${$options.tPfx}.track`) }}
    </button>
  </router-link>
</template>

<script>
export const translationPrefix = "component.track_button";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      track: "Track",
    },
    al: {
      track: "Gjurmo",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>
