<template>
  <div class="bg-white">
    <tapered-section class="flex flex-col lg:flex-row flex-wrap">
      <header class="w-full py-6 text-center">
        <h1 class="text-secondary-500 underline">
          {{ $t(`${$options.tPfx}.whyEVisa`) }}
        </h1>
      </header>
      <div class="w-full lg:w-1/2 flex justify-center">
        <ul class="triangle-list inline-block text-center lg:text-left">
          <li class="text-xl font-bold px-8 sm:px-0">
            {{ $t(`${$options.tPfx}.instantApproval`) }}
          </li>
          <li class="text-xl font-bold px-8 sm:px-0">
            {{ $t(`${$options.tPfx}.secureAndEfficient`) }}
          </li>
        </ul>
      </div>
      <div class="w-full lg:w-1/2 flex justify-center">
        <ul class="triangle-list inline-block text-center lg:text-left">
          <li class="text-xl font-bold px-8 sm:px-0">
            {{ $t(`${$options.tPfx}.24/7`) }}
          </li>
          <li class="text-xl font-bold px-8 sm:px-0">
            {{ $t(`${$options.tPfx}.visaTypes`) }}
          </li>
          <li class="text-xl font-bold px-8 sm:px-0">
            {{ $t(`${$options.tPfx}.ecoFriendly`) }}
          </li>
        </ul>
      </div>
      <footer
        v-html="$t(`${$options.tPfx}.eVisaService`)"
        class="w-full mt-4 p-8 text-xl text-center"
      ></footer>
    </tapered-section>
  </div>
</template>

<script>
import TaperedSection from "@c/TaperedSection.vue";

export const translationPrefix = "view.home.whyEVisa";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      whyEVisa: "Why e-Visa?",
      instantApproval: "Instant approval for all countries (see below)",
      secureAndEfficient: "Secure and efficent application submission",
      "24/7": "24/7 fast online application & simplified forms",
      visaTypes: "Visas for tourism, business, study & transit",
      ecoFriendly: "User friendly and eco friendly (paper less)",
      eVisaService: `
				We process your e-Visa Albania application with efficency and accurancy
        <br />
        The visitors receive complete access to e-visa service and grants them
				24/7 access
			`,
    },
    al: {
      whyEVisa: "Pse e-Visa?",
      instantApproval: "E aprovuar nga të gjitha shtetet (shiko më poshtë)",
      secureAndEfficient: "Aplikim i sigurt dhe efiçent",
      "24/7": "Aplikim i shpejtë online për 24/7 & forma të thjeshta",
      visaTypes: "Viza për turizëm, biznes, studim dhe kalim",
      ecoFriendly:
        "Lehtësisht e përdorshme dhe ekologjike (pa letër të printuar)",
      eVisaService: `
				Ne e proçesojmë aplikimin tuaj për vizë tek e-Visa Shqipëri me efikasitet dhe korrektësi.
				<br />
				Vizitorët marrin akses të plotë të shërbimit e-visa 24/7.
			`,
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: {
    TaperedSection,
  },
};
</script>

<style scoped>
ul.triangle-list > li {
  margin: 0.5rem 0;
}

@media only screen and (min-width: 1024px) {
  ul.triangle-list > li {
    margin: 0rem 0;
  }

  ul.triangle-list > li::before {
    content: "";
    border-color: transparent red;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative;
  }
}
</style>