<template functional>
  <section
    v-bind="data.attrs"
    :class="[data.class, data.staticClass]"
    class="max-w-screen-xl mx-auto sm:px-6 lg:px-8"
  >
    <slot />
  </section>
</template>

<script>
export default {};
</script>