<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section
      class="px-4 pb-4"
      :class="{ 'pt-8': !isShowingMessage, 'pt-32': isShowingMessage }"
    >
      <method-select
        :selected-method="selectedMethod"
        @method-selected="conditionalSelectMethod"
      />
    </tapered-section>
    <tapered-section class="px-4 pt-4 pb-8">
      <upload-proof-of-payment
        v-show="isProofOfPayment"
        :application-id="applicationId"
        :payment-is-sucessful="disableUpload"
      />
      <pay-online
        v-show="isPayOnline"
        :application-id="applicationId"
        @payment-success="disableUpload = true"
      />
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { mapGetters } from "vuex";
import { module as flashModule } from "@/store/flash";
import MethodSelect from "./Payment/MethodSelect.vue";
import PayOnline from "./Payment/PayOnline.vue";
import UploadProofOfPayment from "./Payment/UploadProofOfPayment.vue";

export const translationPrefix = "view.payment";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {},
    al: {},
  },
};
export default {
  components: {
    UploadProofOfPayment,
    PayOnline,
    MethodSelect,
  },
  props: {
    applicationId: {
      type: [String, Number],
      required: true,
    },
  },
  //! Alway load proofOfPayment first.
  //!  If application is paid server returns 403 and client is redirected
  data: () => ({
    selectedMethod: "proofOfPayment",
    disableUpload: false,
  }),
  computed: {
    ...mapGetters(flashModule, ["isShowingMessage"]),
    isProofOfPayment() {
      return this.selectedMethod === "proofOfPayment";
    },
    isPayOnline() {
      return this.selectedMethod === "payOnline";
    },
  },
  methods: {
    conditionalSelectMethod(method) {
      if (method === "proofOfPayment" && this.disableUpload) {
        return;
      }

      this.selectedMethod = method;
    },
  },
};
</script>

<style>
</style>