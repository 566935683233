<template functional>
  <div
    :style="{
      height: props.height,
    }"
    :class="[data.class, data.staticClass]"
    class="flex justify-center items-center w-full"
  >
    <div class="inline-block">
      <sync-loader
        :loading="props.loading"
        :color="props.color"
        :size="props.size"
        :margin="props.margin"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#EE0000", //secondary-500
    },
    size: {
      type: String,
      default: "1rem",
    },
    margin: {
      type: String,
      default: "2px",
    },
    height: {
      type: String,
      default: "16rem",
    },
  },
};
</script>