var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"absolute top-0 right-0 bottom-0 left-0 z-5 flex justify-center items-center"},[_c('font-awesome-icon',{staticClass:"mr-2",class:{
      'text-gray-400': !_vm.props.isDraggingFiles && !_vm.props.hasError,
      'text-yellow-500': !_vm.props.isDraggingFiles && _vm.props.hasError,
      'text-secondary-500': _vm.props.isDraggingFiles,
    },attrs:{"icon":"file-upload","size":"3x"}}),_c('span',{staticClass:"ml-2",class:{
      'text-gray-400': !_vm.props.isDraggingFiles && !_vm.props.hasError,
      'text-yellow-500': !_vm.props.isDraggingFiles && _vm.props.hasError,
      'text-secondary-500': _vm.props.isDraggingFiles,
    }},[_vm._v(" "+_vm._s(_vm.props.hint)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }