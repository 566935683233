<template>
  <div
    class="bg-gray-200"
    :class="isShowingMessage ? 'pt-24 sm:pt-16 pb-8' : 'py-8'"
  >
    <e-visa-information class="mb-8" />
    <exemptions class="mb-8" />
    <visas class="mb-8" />
    <process-phases class="mb-8" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { actions as flashActions, module as flashModule } from "@/store/flash";
import { module as authModule } from "@/store/auth";
import { Application } from "@/api";

import EVisaInformation from "./Apply/EVisaInformation.vue";
import Exemptions from "./Apply/Exemptions.vue";
import ProcessPhases from "./Apply/ProcessPhases.vue";
import Visas from "./Apply/Visas.vue";
import STATUS_ENUM from "@/utils/applicationStatusEnum";

export const translationPrefix = "view.apply";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      applicationsInDraft:
        'You have an unfinalized "{visaType}" application. Do you wish to continue?',
    },
    al: {
      applicationsInDraft:
        'Ju keni një aplikacion për "{visaType}" të pafinalizuar. Dëshironi ta vazhdoni?',
    },
  },
};

export default {
  tPfx: translationPrefix,
  beforeRouteLeave(_to, _from, next) {
    this.dispatchDismissFlash();
    next();
  },
  components: { EVisaInformation, Visas, ProcessPhases, Exemptions },
  computed: {
    ...mapGetters(flashModule, ["isShowingMessage"]),
    ...mapGetters(authModule, ["isLoggedIn"]),
  },
  methods: {
    ...mapActions(flashModule, {
      dispatchDismissFlash: flashActions.dismiss,
    }),
    async checkForDraftsApplications() {
      const { data: applicationsDraftResponse } = await Application.all(
        STATUS_ENUM.draft
      );
      const applicationsInDraft = applicationsDraftResponse.data.applications;

      if (applicationsInDraft.length) {
        const draftApplication = applicationsInDraft[0];

        const { isConfirmed } = await this.$swal({
          title: this.$t(`${this.$options.tPfx}.applicationsInDraft`, {
            visaType: draftApplication.visaType.title[this.$i18n.locale],
          }),
          showCancelButton: true,
        });

        if (isConfirmed) {
          this.$router.push({
            name: "Questionary",
            params: { applicationId: draftApplication.id },
          });
        }

        return isConfirmed;
      }

      return false;
    },
  },
  async mounted() {
    if (this.isLoggedIn) {
      this.checkForDraftsApplications();
    }
  },
};
</script>

<style>
</style>
