<template>
  <div v-click-outside="hideMenu" class="ml-3 relative">
    <div class="group inline-flex items-center">
      <button
        @click="showNotificationsMenu = !showNotificationsMenu"
        class="relative p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700"
        aria-label="Notifications"
      >
        <span
          v-show="numberOfNotifications"
          class="absolute top-0 right-0 rounded-full p-px bg-secondary-500 text-white leading-none opacity-75 group-hover:opacity-100"
        >
          {{ numberOfNotifications }}
        </span>
        <svg
          class="h-6 w-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
      </button>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="showNotificationsMenu"
        class="origin-top-right absolute z-20 right-0 mt-2 w-48 rounded-md shadow-lg"
      >
        <ul
          class="rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <li
            v-for="(notification, index) in notifications"
            :key="index"
            :class="{
              'border-b': index !== numberOfNotifications - 1,
            }"
            class="flex"
          >
            <component
              :is="notification.link ? 'router-link' : 'span'"
              :to="notification.link"
              :class="{
                'rounded-tl-md': index === 0,
                'rounded-bl-md': index === numberOfNotifications - 1,
              }"
              class="inline-block p-2 flex-1 hover:bg-secondary-300"
            >
              {{ notification.text[$i18n.locale] }}
            </component>
            <div class="group flex border-l text-gray-600">
              <button
                @click="removeNotification(index)"
                :class="{
                  'rounded-tr-md': index === 0,
                  'rounded-br-md': index === numberOfNotifications - 1,
                }"
                class="p-2 hover:bg-secondary-300"
              >
                <font-awesome-icon
                  icon="times"
                  class="fa-xs text-gray-600 group-hover:text-black"
                ></font-awesome-icon>
              </button>
            </div>
          </li>
          <li class="p-2" v-show="!numberOfNotifications">
            {{ $t(`${$options.tPfx}.noNewNotifications`) }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import pusherAuthMixin from "@/mixins/pusherAuthMixin";

export const translationPrefix =
  "layout.default.navbar.userProfile.notifications";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      noNewNotifications: "No notifications",
    },
    al: {
      noNewNotifications: "Ska njoftime",
    },
  },
};

export default {
  tPfx: translationPrefix,
  mixins: [pusherAuthMixin],
  data: () => ({
    showNotificationsMenu: false,
    notifications: [],
  }),
  computed: {
    numberOfNotifications() {
      return this.notifications.length;
    },
  },
  methods: {
    hideMenu() {
      this.showNotificationsMenu = false;
    },
    removeNotification(index) {
      this.notifications.splice(index, 1);
    },
    listenToProcessedPaymentEvents() {
      const userPersonalChannel = this.channels[this.user.email];

      if (!userPersonalChannel) {
        return;
      }

      userPersonalChannel.bind("paymentProcessed", (paymentProcessed) => {
        const notification = {
          text: paymentProcessed.notification,
          link: { name: "Applications" },
        };

        this.notifications.push(notification);

        if ("Notification" in window && Notification.permission === "granted") {
          new Notification(notification.text[this.$i18n.locale]);
        }
      });
    },
  },
  mounted() {
    if (this.user) {
      this.listenToProcessedPaymentEvents();
    }
  },
};
</script>

