var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-fill-height',{staticClass:"bg-gray-200 pt-32 pb-8",attrs:{"id":"verify-visas"}},[(_vm.isLoadingVisa)?_c('loader',{attrs:{"loading":_vm.isLoadingVisa,"size":"1.5rem","margin":"4px"}}):(_vm.visa)?_c('tapered-section',{staticClass:"px-4"},[_c('article',{staticClass:"border rounded-md border-gray-400 p-4 md:p-8 bg-white"},[_c('section',{staticClass:"flex flex-wrap"},[_c('header',{staticClass:"w-full mb-4"},[_c('h2',{staticClass:"text-center"},[_vm._v("TË DHËNAT E VISËS | VISA DATA")])]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'E VLEFSHME PËR | VALID FOR | VALABLE POUR',
                value: _vm.visa.valid_for
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'NGA | FROM | DU',
                value: _vm.visa.start_date
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'DERI | UNTIL | AU',
                value: _vm.visa.end_date
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'TIPI VIZËS | TYPE OF VISA | TYPE DE VISA',
                value: _vm.visa.visa_type
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'NUMRI I HYRJEVE | NUMBER OF ENTRIES | NOMBRE D\'ENTREES',
                value: _vm.visa.number_of_entries + ''
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'AFATI I QËNDRIMIT | DURATION OF STAY | DUREE DE SEJOUR',
                value: _vm.visa.duration_of_stay + ' ' + _vm.visa.duration_of_stay_unit
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'LËSHUAR NË | ISSUED IN | DELIVERE A',
                value: _vm.visa.issued_in
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'MË | ON | LE',
                value: _vm.visa.issued_on
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'NUMRI I PASAPORTËS | NUMBER OF PASSPORT | NUMERO DE PASSEPORT',
                value: _vm.visa.applicant_passport_number
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'MBIEMËR, EMËR | SURNAME, NAME | NOM, PRENOM',
                value: _vm.visa.applicant_last_name + ', ' + _vm.visa.applicant_first_name
              },false))],1)]),_c('div',{staticClass:"p-2 w-full md:w-1/2"},[_c('div',{staticClass:"h-full flex flex-col flex-wrap p-2 pb-0 justify-end"},[_c('text-input',_vm._b({tag:"component",staticClass:"w-full"},'component',{
                disabled: true,
                type: 'input',
                label: 'SHËNIME | REMARKS | REMARQUES',
                value: _vm.visa.remarks
              },false))],1)]),_c('div',{staticClass:"w-full"},[_c('img',{attrs:{"src":_vm.visa.base64Img}})])])])]):(_vm.noVisa)?_c('tapered-section',{staticClass:"px-4"},[_c('article',{staticClass:"border rounded-md border-gray-400 p-4 md:p-8 bg-white"},[_c('section',{staticClass:"flex flex-wrap"},[_c('header',{staticClass:"w-full mb-4"},[_c('h2',{staticClass:"text-center text-red-700"},[_vm._v("NUK KA TË DHËNA | NO DATA")])])])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }