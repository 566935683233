const ONE_GB = 1073741824;
const ONE_MB = 1048576;
const ONE_KB = 1024;

const sizeToHumanReadable = (size, precision = 2) => {
  // GB
  if (size > ONE_GB) {
    return `${(size / ONE_GB).toFixed(precision)}GB`;
  }

  if (size > ONE_MB) {
    return `${(size / ONE_MB).toFixed(precision)}MB`;
  }

  if (size > ONE_KB) {
    return `${(size / ONE_KB).toFixed(precision)}KB`;
  }

  return `${size}B`;
};

export default sizeToHumanReadable;
