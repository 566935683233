<template>
  <default-fill-height class="bg-gray-200">
    <tapered-section
      class="px-4"
      :class="{ 'py-32': isShowingMessage, 'py-12': !isShowingMessage }"
    >
      <article
        class="border border-gray-400 rounded-md p-4 md:p-8 flex flex-wrap items-start bg-white"
      >
        <form
          @submit.prevent="checkStatusByTrackingNumber"
          class="mx-auto w-full md:w-1/3"
        >
          <text-input
            v-model="trackingNumber"
            :label="$t(`${$options.tPfx}.trackingNumber`)"
            :placeholder="$t(`${$options.tPfx}.trackingNumber`)"
            id="tracking-number"
            name="tracking-number"
            required
          />
          <button type="submit" class="w-full button button-secondary">
            {{ $t(`${$options.tPfx}.track`) }}
          </button>
        </form>
      </article>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { Application } from "@/api";
import { actions, module as flashModule } from "@/store/flash";
import { module as authModule } from "@/store/auth";

export const translationPrefix = "view.track";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      noApplication: "No application was found with this tracking number!",
      applicationFound:
        'Application #{trackingNumber} is in status "{status}"!',
      trackingNumber: "Tracking number",
      track: "Track",
    },
    al: {
      noApplication: "Nuk u gjet asnjë aplikimin me këtë numër gjurmimi!",
      applicationFound:
        'Aplikimi #{trackingNumber} është në statusin "{status}"!',
      trackingNumber: "Numri i gjurmimit",
      track: "Gjurmo",
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    preFilledTrackingNumber: {
      type: [Number, String],
    },
  },
  data() {
    return {
      trackingNumber: this.preFilledTrackingNumber,
    };
  },
  computed: {
    ...mapGetters(flashModule, ["isShowingMessage"]),
    ...mapGetters(authModule, ["isLoggedIn"]),
    ...mapState(authModule, ["user"]),
  },
  methods: {
    ...mapActions(flashModule, {
      dispatchDismissFlash: actions.dismiss,
      dispatchShowFlash: actions.show,
    }),
    async checkStatusByTrackingNumber() {
      try {
        const { data: statusCheckResponse } = await Application.track(
          this.trackingNumber
        );

        const application = statusCheckResponse.data.application;

        if (this.isLoggedIn && application.userId === this.user.id) {
          await this.$router.push({
            name: "Applications",
            query: {
              trackingNumber: this.trackingNumber,
            },
          });

          return;
        }

        this.dispatchDismissFlash();

        this.$swal(
          this.$t(`${this.$options.tPfx}.applicationFound`, {
            trackingNumber: this.trackingNumber,
            status:
              statusCheckResponse.data.application.status.name[
                this.$i18n.locale
              ],
          })
        );
      } catch (error) {
        await this.dispatchShowFlash({
          class:
            "sm:block md:inline-block border-secondary-500 bg-secondary-200 max-w-lg mx-auto sm:max-w-auto sm:min-w-lg",
          iconClass: "hover:text-secondary-500",
          text: `${this.$options.tPfx}.noApplication`,
          textClass: "avoid-warp",
        });
      }
    },
  },
  async mounted() {
    if (this.trackingNumber) {
      await this.checkStatusByTrackingNumber();
    }
  },
  beforeRouteLeave(_to, _from, next) {
    this.dispatchDismissFlash();
    next();
  },
};
</script>

<style>
</style>
