<template functional>
  <div class="p-2 border-t border-gray-700 sm:px-0 md:border-0">
    <!-- Mobile -->
    <router-link
      v-if="!parent.$route.matched.some(({ name }) => name === 'Login')"
      :to="{ name: 'Login' }"
      @click.native="listeners.navigate"
      class="menu md:hidden"
    >
      {{ parent.$t(`${$options.tPfx}.login`) }}
    </router-link>
    <router-link
      v-if="!parent.$route.matched.some(({ name }) => name === 'Register')"
      :to="{ name: 'Register' }"
      @click.native="listeners.navigate"
      class="menu md:hidden"
    >
      {{ parent.$t(`${$options.tPfx}.register`) }}
    </router-link>

    <!-- Desktop -->
    <router-link
      v-if="!parent.$route.matched.some(({ name }) => name === 'Login')"
      :to="{ name: 'Login' }"
      @click.native="listeners.navigate"
      class="hidden md:mx-2 md:button md:button-secondary"
    >
      {{ parent.$t(`${$options.tPfx}.login`) }}
    </router-link>
    <router-link
      v-if="!parent.$route.matched.some(({ name }) => name === 'Register')"
      :to="{ name: 'Register' }"
      @click.native="listeners.navigate"
      class="hidden md:mx-2 md:button md:button-primary"
    >
      {{ parent.$t(`${$options.tPfx}.register`) }}
    </router-link>
  </div>
</template>

<script>
export const translationPrefix = "layout.default.navbar.loginOrRegister";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      login: "Log in",
      register: "Register",
    },
    al: {
      login: "Kyçu",
      register: "Regjistrohu",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>