import Vue from 'vue'
import Vuex from 'vuex'
import auth, { module as authModule } from './auth';
import flash, { module as flashModule } from './flash';

Vue.use(Vuex)

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: {
		[authModule]: auth,
		[flashModule]: flash,
	}
})
