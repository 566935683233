<template>
  <main>
    <carousel />
    <visa-application-process />
    <why-e-visa />
  </main>
</template>

<script>
import Carousel from "./Home/Carousel.vue";
import VisaApplicationProcess from "./Home/VisaApplicationProcess.vue";
import WhyEVisa from "./Home/WhyEVisa.vue";

export const translationPrefix = "view.tmp_pass";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      tempPassTitle: "Temporary password!",
      tempPassBody: "Password you logged in is temporary. Please update you password",
    },
    al: {
      tempPassTitle: "Fjalëkalim i përkohshëm",
      tempPassBody: "Fjalëkalimi me të cilin u futet në sistem është i përkohshëm. Ju lutem përditësoni fjalëkalim tuaj.",
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: {
    Carousel,
    VisaApplicationProcess,
    WhyEVisa,
  },
  async mounted() {
    const is_temp = localStorage.getItem('is_temp');
    if (is_temp) {
      const { isConfirmed } = await this.$swal({
        title: this.$t(`${this.$options.tPfx}.tempPassTitle`),
        html: this.$t(`${this.$options.tPfx}.tempPassBody`),
      });

      if (isConfirmed) {
        localStorage.removeItem('is_temp');
      }
    }
  }
};
</script>
