<template>
  <section class="relative flex justify-center px-4">
    <div class="w-full absolute top-8 px-4 text-center">
      <div
        class="relative px-10 py-4 rounded-md border-2 text-center"
        v-if="message"
        :class="message.class"
      >
        <template v-if="Array.isArray(message.text)">
          <span
            v-for="(text, index) in message.text"
            :key="text"
            :class="message.textClass ? message.textClass[index] : ''"
          >
            {{
              $t(text, message.textParam ? message.textParam[index] : undefined)
            }}
          </span>
        </template>
        <template v-else>
          <span :class="message.textClass">
            {{ $t(message.text, message.textParam) }}
          </span>
        </template>

        <span
          class="dismiss-icon-position px-2 text-gray-500 font-bold cursor-pointer"
          :class="message.iconClass"
          @click="dispatchDismissFlash"
        >
          x
        </span>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actions, module as flashModule } from "@/store/flash";

export default {
  computed: {
    ...mapState(flashModule, ["message"]),
  },
  methods: {
    ...mapActions(flashModule, {
      dispatchDismissFlash: actions.dismiss,
    }),
  },
};
</script>

<style scoped>
.dismiss-icon-position {
  @apply absolute;
  top: calc(50% - 12px);
  @apply right-4;
}
</style>