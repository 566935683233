export const translationObjectIsValid = (translationObject) => {
  const hasValidPrefix = ["string", "undefined"].includes(typeof translationObject.pfx);

  const hasValidTranslations = typeof translationObject.t === "object";

  return hasValidPrefix && hasValidTranslations;
}

export const transformTranslationObjectToNestable = (translationObject) => {
  if (!translationObjectIsValid) {
    throw new Error('Translation object not defined correctly');
  }

  return [...translationObject.pfx.split('.'), translationObject.t];
}

export const nest = (nested, [nthIteration, ...rest], valueProp, originalObject) => {
  if (rest.length === 1) {
    nested[nthIteration] = rest[0][valueProp];

    return originalObject;
  }

  if (!originalObject) {
    originalObject = nested;
  }

  if (!nested[nthIteration]) {
    // TODO: problem might be here!
    nested[nthIteration] = {};
  }

  return nest(
    nested[nthIteration],
    rest,
    valueProp,
    originalObject,
  );
}

/** 
 *  TODO: Potential bug with how nested objects are build
 *  TODO: Case when object is already nested bellow, removes nested layers
 */
export const buildLocale = (locale, translationObjects) => {
  let localeObject = {};

  for (const translationObject of translationObjects) {
    const nestableTranslationObject = transformTranslationObjectToNestable(translationObject);

    nest(localeObject, nestableTranslationObject, locale);
  }

  return localeObject;
}

export default buildLocale;