<template>
  <footer
    class="flex flex-wrap justify-between border rounded-md border-gray-400 md:p-12 bg-white"
  >
    <button
      v-show="!isFirstPage"
      @click="$emit('back')"
      class="m-6 md:m-0 md:mr-auto py-2 px-8 w-full md:w-auto button button-secondary"
    >
      <font-awesome-icon icon="arrow-left" class="mr-2" />
      {{ $t(`${$options.tPfx}.back`) }}
    </button>
    <button
      v-show="!isLastPage"
      @click="$emit('next')"
      :disabled="nextButtonIsDisabled"
      class="m-6 md:m-0 md:ml-auto py-2 px-8 w-full md:w-auto button button-secondary"
    >
      {{ $t(`${$options.tPfx}.next`) }}
      <font-awesome-icon icon="arrow-right" class="ml-2" />
    </button>
    <loading-button
      v-if="isLastPage && !isLocked"
      :is-loading="isFinalizing"
      @click="$emit('finalize')"
      class="m-6 md:m-0 md:ml-auto py-2 px-8 w-full md:w-auto button button-secondary"
    >
      {{ $t(`${$options.tPfx}.finalize`) }}
      <font-awesome-icon icon="arrow-right" class="ml-2" />
    </loading-button>
  </footer>
</template>

<script>
export const translationPrefix = "view.questionary.pageControls";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      next: "Next",
      back: "Back",
      finalize: "Finalize",
    },
    al: {
      next: "Tjetra",
      back: "Mbrapa",
      finalize: "Përfundo",
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    value: {
      type: Number,
      required: true,
    },
    isFirstPage: {
      type: Boolean,
      required: true,
    },
    isLastPage: {
      type: Boolean,
      required: true,
    },
    isLocked: {
      type: Boolean,
      required: true,
    },
    nextButtonIsDisabled: {
      type: Boolean,
      required: true,
    },
    isFinalizing: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
</style>