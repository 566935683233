<template>
  <section
    class="fixed top-0 right-0 bottom-0 left-0 flex items-center p-8 backdrop-blur z-40"
    @click="closeModalOnBackdropClick"
    ref="backdrop"
  >
    <div
      class="border rounded-md border-secondary-500 p-8 w-full bg-white shadow-xl"
    >
      <div class="mb-8 border-2 rounded-md border-primary-900">
        <button
          v-for="page in pages"
          :key="page.page"
          :class="{
            'bg-secondary-500 text-white': page.page === value,
            'text-primary-900': page.page !== value,
            'bg-gray-300 cursor-not-allowed':
              onlyBackwards && page.page > value,
          }"
          @click="emitInputAndClose(page.page)"
          class="page block p-2 w-full text-center cursor-pointer"
        >
          {{ $t(`${translationNamespace}.${page.title}`) }}
        </button>
      </div>
      <button @click="$emit('close')" class="w-full button button-secondary">
        {{ $t(`${translationNamespace}.close`) }}
      </button>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    pages: {
      type: Array,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    translationNamespace: {
      type: String,
      required: true,
    },
    onlyBackwards: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    emitInputAndClose(pageToEmit) {
      this.$emit("input", pageToEmit);
      this.$emit("close");
    },
    closeModalOnBackdropClick(event) {
      if (event.srcElement === this.$refs.backdrop) {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.backdrop-blur {
  backdrop-filter: blur(4px);
}

.page:first-child {
  @apply rounded-t-sm;
}

.page:last-child {
  @apply rounded-b-sm;
}
</style>