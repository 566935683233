const CONSULAR_REPRESENTATIONS_EMAILS = {
    defaultConsular: 'Visa@mfa.gov.al',
    cairo: 'Visa@mfa.gov.al',
    paris: 'embassy.paris@mfa.gov.al',
    madrid: 'embassy.madrid@mfa.gov.al',
    brasilia: 'embassy.brasilia@mfa.gov.al',
    riyadh: 'embassy.riyadh@mfa.gov.al',
    athens: 'embassy.athens@mfa.gov.al',
    tokyo: 'embassy.tokyo@mfa.gov.al',
    canberra: 'embassy.canberra@mfa.gov.al',
    vienna: 'embassy.vienna@mfa.gov.al',
    ankara: 'embassy.ankara@mfa.gov.al',
    beijing: 'embassy.beijing@mfa.gov.al',
    moscow: 'embassy.moscow@mfa.gov.al',
    sofia: 'embassy.sofia@mfa.gov.al',
    copenhagen: 'embassy.copenhagen@mfa.gov.al',
    honoraryConsulateNewDelhi: 'Visa@mfa.gov.al',
    abuDhabi: 'embassy.UAE@mfa.gov.al',
    warsaw: 'embassy.warsaw@mfa.gov.al',
    stockholm: 'embassy.stockholm@mfa.gov.al',
    munich: 'consulate.munich@mfa.gov.al',
    berlin: 'embassy.berlin@mfa.gov.al',
    ioannina: 'Visa@mfa.gov.al',
    thessaloniki: 'Visa@mfa.gov.al',
    hague: 'embassy.hague@mfa.gov.al',
    budapest: 'embassy.budapest@mfa.gov.al',
    istanbul: 'consulate.istanbul@mfa.gov.al',
    london: 'embassy.london@mfa.gov.al',
    rome: 'embassy.rome@mfa.gov.al',
    vatican: 'embassy.vatican@mfa.gov.al',
    bari: 'consulate.bari@mfa.gov.al',
    milan: 'consulate.milan@mfa.gov.al',
    telAviv: 'embassy.telaviv@mfa.gov.al',
    ottawa: 'Visa@mfa.gov.al',
    doha: 'Embassy.Doha@mfa.gov.al',
    pristina: 'embassy.pristina@mfa.gov.al',
    zagreb: 'embassy.zagreb@mfa.gov.al',
    kuwaitCity: 'embassy.kuwait@mfa.gov.al',
    brussels: 'mbassy.brussels@mfa.gov.al',
    podgorica: 'embassy.podgorica@mfa.gov.al',
    skopje: 'embassy.skopje@mfa.gov.al',
    washington: 'embassy.washington@mfa.gov.al',
    newYork: 'mission.newyork@mfa.gov.al',
    bucharest: 'embassy.bucharest@mfa.gov.al',
    prague: 'embassy.prague@mfa.gov.al',
    beograd: 'embassy.belgrade@mfa.gov.al',
    greenwich: 'Visa@mfa.gov.al',
    bratislava: 'embassy.bratislava@mfa.gov.al',
    ljubljana: 'embassy.ljubljana@mfa.gov.al',
    bern: 'embassy.bern@mfa.gov.al',
    australia: 'embassy.canberra@mfa.gov.al',
    brazil: 'Visa@mfa.gov.al',
    honoraryConsulateMumbai: 'Visa@mfa.gov.al',
    albanianMFA: 'Visa@mfa.gov.al',
    qatar: 'Visa@mfa.gov.al',
}

export default CONSULAR_REPRESENTATIONS_EMAILS;