import QUESTION_CODE_ENUM from './questionCodeEnum';
import { parseISO, isAfter, isBefore, isToday, isPast } from 'date-fns';
import validateEmail from './validateEmail';
import validatePassportNumber from './validatePassportNumber';
import validateDurationOfStayTypeC from "@v/Questionary/helpers/validateDurationOfStayTypeC";

function validateDateOfArrival(question) {
  const dateOfArrivalQuestion = question;

  if (this.questionHasAnswer(dateOfArrivalQuestion)) {
    const currentDateOfArrival = parseISO(dateOfArrivalQuestion.answers[0].answer.en);

    if (isPast(currentDateOfArrival) || isToday(currentDateOfArrival)) {
      dateOfArrivalQuestion.error = 'isTodayOrInThePast';

      return false;
    }

    const dateOfDepartureQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.dateOfDeparture);

    if (this.questionHasAnswer(dateOfDepartureQuestion)) {
      const currentDateOfDeparture = parseISO(dateOfDepartureQuestion.answers[0].answer.en);

      if (isAfter(currentDateOfArrival, currentDateOfDeparture)) {
        dateOfArrivalQuestion.error = 'isAfterOtherDate';
        dateOfArrivalQuestion.errorParams.relatedQuestion = dateOfDepartureQuestion;

        return false;
      }
    }
  }

  return true;
}

function validateDateOfDeparture(question) {
  const dateOfDepartureQuestion = question;

  if (this.questionHasAnswer(dateOfDepartureQuestion)) {
    const currentDateOfDeparture = parseISO(dateOfDepartureQuestion.answers[0].answer.en);

    if (isPast(currentDateOfDeparture) || isToday(currentDateOfDeparture)) {
      dateOfDepartureQuestion.error = 'isTodayOrInThePast';

      return false;
    }

    const dateOfArrivalQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.dateOfArrival);

    if (this.questionHasAnswer(dateOfArrivalQuestion)) {
      const currentDateOfArrival = parseISO(dateOfArrivalQuestion.answers[0].answer.en);

      if (isBefore(dateOfDepartureQuestion, currentDateOfArrival)) {
        dateOfDepartureQuestion.error = 'isBeforeOtherDate';
        dateOfDepartureQuestion.errorParams.relatedQuestion = dateOfArrivalQuestion;

        return false;
      }
    }
  }

  return true;
}

function validatePassportDateOfIssue(question) {
  const passportDateOfIssueQuestion = question;
  const passportDateOfExpiryQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.passportDateOfExpiry);

  if (
    this.questionHasAnswer(passportDateOfIssueQuestion) &&
    this.questionHasAnswer(passportDateOfExpiryQuestion)
  ) {
    const currentPassportIssueDate = parseISO(passportDateOfIssueQuestion.answers[0].answer.en);
    const currentPassportExpiryDate = parseISO(passportDateOfExpiryQuestion.answers[0].answer.en);

    if (isAfter(currentPassportIssueDate, currentPassportExpiryDate)) {
      passportDateOfIssueQuestion.error = 'isAfterOtherDate';
      passportDateOfIssueQuestion.errorParams.relatedQuestion = passportDateOfExpiryQuestion;

      return false;
    }
  }

  return true;
}

function validatePassportDateOfExpiry(question) {
  const passportDateOfExpiryQuestion = question;

  if (this.questionHasAnswer(passportDateOfExpiryQuestion)) {
    const currentPassportExpiryDate = parseISO(passportDateOfExpiryQuestion.answers[0].answer.en);

    if (isPast(currentPassportExpiryDate) || isToday(currentPassportExpiryDate)) {
      passportDateOfExpiryQuestion.error = "isTodayOrInThePast";

      return false;
    }

    const passportDateOfIssueQuestion = this.getQuestionByCode(QUESTION_CODE_ENUM.passportDateOfIssue);

    if (this.questionHasAnswer(passportDateOfIssueQuestion)) {
      const currentPassportIssueDate = parseISO(passportDateOfIssueQuestion.answers[0].answer.en);

      if (isBefore(currentPassportExpiryDate, currentPassportIssueDate)) {
        passportDateOfIssueQuestion.error = 'isAfterOtherDate';
        passportDateOfIssueQuestion.errorParams.relatedQuestion = passportDateOfExpiryQuestion;

        return false;
      }
    }
  }

  return true;
}

function validateEmailQuestion(question) {
  const emailQuestion = question;

  if (this.questionHasAnswer(emailQuestion)) {
    const email = emailQuestion.answers[0].answer.en

    const validEmail = validateEmail(email)
    if (!validEmail) {
      question.error = 'invalidEmail';

      return false;
    }
  }

  return true;
}

function validatePassportNumberQuestion(question) {
  const passportNumberQuestion = question;

  if (this.questionHasAnswer(passportNumberQuestion)) {
    const passportNumber = passportNumberQuestion.answers[0].answer.en

    const validPassportNumber = validatePassportNumber(passportNumber)
    if (!validPassportNumber) {
      question.error = 'invalidPassportNumber';

      return false;
    }
  }

  return true
}

function validateDurationOfStayTypeCQuestion(question, visaTypeCategory) {
  const durationOfStayQuestion = question;

  if (this.questionHasAnswer(durationOfStayQuestion)) {
    const durationOfStay = durationOfStayQuestion.answers[0].answer.en

    const validDurationOfStay = validateDurationOfStayTypeC(durationOfStay, visaTypeCategory)
    if (!validDurationOfStay) {
      question.error = 'invalidDurationOfStayTypeC'
      return false;
    }
  }

  return true
}

export default {
  validateDateOfArrival,
  validateDateOfDeparture,
  validatePassportDateOfIssue,
  validatePassportDateOfExpiry,
  validateEmailQuestion,
  validatePassportNumberQuestion,
  validateDurationOfStayTypeCQuestion
};
