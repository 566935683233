export const sortByStringKeyAsc = (keyCallback = item => item) => (a, b) => {
  const aProp = keyCallback(a);
  const bProp = keyCallback(b);

  if (aProp < bProp) {
    return -1;
  }
  if (aProp > bProp) {
    return 1;
  }
  return 0;
}

export const sortByStringKeyDesc = (keyCallback = item => item) => (a, b) => {
  const aProp = keyCallback(a);
  const bProp = keyCallback(b);

  if (aProp > bProp) {
    return -1;
  }
  if (aProp < bProp) {
    return 1;
  }
  return 0;
}

export const sortByStringKey = (order, keyCallback = item => item) => {
  const allowedOrders = ['ASC', 'asc', 'DESC', 'desc'];
  if (!allowedOrders.includes(order)) {
    throw new Error(`Order ${order} is not valid, plese use one of the following: ${allowedOrders.join(', ')}`)
  }

  if (order === 'ASC' || order === 'asc') {
    return sortByStringKeyAsc(keyCallback);
  }

  return sortByStringKeyDesc(keyCallback);
}