<template>
  <default-fill-height
      class="bg-gray-200 pt-32 pb-8"
      id="verify-visas"
  >
    <loader
        v-if="isLoadingVisa"
        :loading="isLoadingVisa"
        size="1.5rem"
        margin="4px"
    />
    <tapered-section v-else-if="visa" class="px-4">
      <article class="border rounded-md border-gray-400 p-4 md:p-8 bg-white">
        <section class="flex flex-wrap">
          <header class="w-full mb-4">
            <h2 class="text-center">TË DHËNAT E VISËS | VISA DATA</h2>
          </header>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'E VLEFSHME PËR | VALID FOR | VALABLE POUR',
                  value: visa.valid_for
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'NGA | FROM | DU',
                  value: visa.start_date
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'DERI | UNTIL | AU',
                  value: visa.end_date
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'TIPI VIZËS | TYPE OF VISA | TYPE DE VISA',
                  value: visa.visa_type
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'NUMRI I HYRJEVE | NUMBER OF ENTRIES | NOMBRE D\'ENTREES',
                  value: visa.number_of_entries + ''
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'AFATI I QËNDRIMIT | DURATION OF STAY | DUREE DE SEJOUR',
                  value: visa.duration_of_stay + ' ' + visa.duration_of_stay_unit
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'LËSHUAR NË | ISSUED IN | DELIVERE A',
                  value: visa.issued_in
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'MË | ON | LE',
                  value: visa.issued_on
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'NUMRI I PASAPORTËS | NUMBER OF PASSPORT | NUMERO DE PASSEPORT',
                  value: visa.applicant_passport_number
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'MBIEMËR, EMËR | SURNAME, NAME | NOM, PRENOM',
                  value: visa.applicant_last_name + ', ' + visa.applicant_first_name
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="p-2 w-full md:w-1/2">
            <div class="h-full flex flex-col flex-wrap p-2 pb-0 justify-end">
              <component
                :is="'text-input'"
                v-bind="{
                  disabled: true,
                  type: 'input',
                  label: 'SHËNIME | REMARKS | REMARQUES',
                  value: visa.remarks
                }"
                class="w-full"
              />
            </div>
          </div>
          <div class="w-full">
            <img :src="visa.base64Img">
          </div>

        </section>
      </article>
    </tapered-section>
    <tapered-section v-else-if="noVisa" class="px-4">
      <article class="border rounded-md border-gray-400 p-4 md:p-8 bg-white">
        <section class="flex flex-wrap">
          <header class="w-full mb-4">
            <h2 class="text-center text-red-700">NUK KA TË DHËNA | NO DATA</h2>
          </header>
        </section>
      </article>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import {mapActions} from "vuex";
import { Visa } from "@/api";
import {actions as flashActions, module as flashModule} from "@/store/flash";
import scrollToResponsive from "@/utils/scrollToResponsive";

export default {
  beforeRouteLeave(_to, _from, next) {
    this.dispatchDismissFlash();
    next();
  },
  data: () => ({
    uuid: null,
    visa: null,
    isLoadingVisa: false,
    noVisa: false,
  }),
  methods: {
    scrollToResponsive,
    ...mapActions(flashModule, {
      dispatchShowFlash: flashActions.show,
      dispatchDismissFlash: flashActions.dismiss,
    }),
    async handleErrorResponse(error) {
      switch (error.response.status) {
        case 404:
          return await this.$router.push({
            name: "Home",
          });
        case 403:
          return await this.$router.replace({
            name: "Home",
          });
      }
    },
    async loadVisa() {
      try {
        this.isLoadingVisa = true;

        const { data } = await Visa.verify(
            this.uuid
        );

        this.visa = data.data.visa;

        this.isLoadingVisa = false;
      } catch (error) {
        await this.handleErrorResponse(error);
        this.isLoadingVisa = false;
        this.noVisa = true;
      }
    },
  },
  async mounted() {
    this.uuid = this.$route.params.uuid;
    await this.loadVisa();
  },
};
</script>

<style>
</style>
