<template functional>
  <svg
    stroke="currentColor"
    fill="none"
    viewBox="0 0 24 24"
    class="block h-6 w-6"
    :class="[data.class, data.staticClass]"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
</template>

<script>
export default {};
</script>