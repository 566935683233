var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideMenu),expression:"hideMenu"}],staticClass:"ml-3 relative"},[_c('div',{staticClass:"group inline-flex items-center"},[_c('button',{staticClass:"relative p-1 border-2 border-transparent text-gray-400 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700",attrs:{"aria-label":"Notifications"},on:{"click":function($event){_vm.showNotificationsMenu = !_vm.showNotificationsMenu}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.numberOfNotifications),expression:"numberOfNotifications"}],staticClass:"absolute top-0 right-0 rounded-full p-px bg-secondary-500 text-white leading-none opacity-75 group-hover:opacity-100"},[_vm._v(" "+_vm._s(_vm.numberOfNotifications)+" ")]),_c('svg',{staticClass:"h-6 w-6",attrs:{"stroke":"currentColor","fill":"none","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"}})])])]),_c('transition',{attrs:{"enter-active-class":"transition ease-out duration-100","enter-class":"transform opacity-0 scale-95","enter-to-class":"transform opacity-100 scale-100","leave-active-class":"transition ease-in duration-75","leave-class":"transform opacity-100 scale-100","leave-to-class":"transform opacity-0 scale-95"}},[(_vm.showNotificationsMenu)?_c('div',{staticClass:"origin-top-right absolute z-20 right-0 mt-2 w-48 rounded-md shadow-lg"},[_c('ul',{staticClass:"rounded-md bg-white shadow-xs",attrs:{"role":"menu","aria-orientation":"vertical","aria-labelledby":"user-menu"}},[_vm._l((_vm.notifications),function(notification,index){return _c('li',{key:index,staticClass:"flex",class:{
            'border-b': index !== _vm.numberOfNotifications - 1,
          }},[_c(notification.link ? 'router-link' : 'span',{tag:"component",staticClass:"inline-block p-2 flex-1 hover:bg-secondary-300",class:{
              'rounded-tl-md': index === 0,
              'rounded-bl-md': index === _vm.numberOfNotifications - 1,
            },attrs:{"to":notification.link}},[_vm._v(" "+_vm._s(notification.text[_vm.$i18n.locale])+" ")]),_c('div',{staticClass:"group flex border-l text-gray-600"},[_c('button',{staticClass:"p-2 hover:bg-secondary-300",class:{
                'rounded-tr-md': index === 0,
                'rounded-br-md': index === _vm.numberOfNotifications - 1,
              },on:{"click":function($event){return _vm.removeNotification(index)}}},[_c('font-awesome-icon',{staticClass:"fa-xs text-gray-600 group-hover:text-black",attrs:{"icon":"times"}})],1)])],1)}),_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.numberOfNotifications),expression:"!numberOfNotifications"}],staticClass:"p-2"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".noNewNotifications")))+" ")])],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }