<template>
  <default-fill-height class="bg-gray-200">
    <loader :loading="true" size="1.5rem" margin="4px" />
  </default-fill-height>
</template>

<script>
import { mapGetters } from "vuex";
import { module as authModule } from "@/store/auth";
import { Application } from "@/api";
import STATUS_ENUM from "@/utils/applicationStatusEnum";

export const translationPrefix = "view.createApplicationRedirecter";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      applicationsInDraft:
        'You have an unfinalized "{visaType}" application. Do you wish to continue?',
    },
    al: {
      applicationsInDraft:
        'Ju keni një aplikacion për "{visaType}" të pafinalizuar. Dëshironi ta vazhdoni?',
    },
  },
};

export default {
  tPfx: translationPrefix,
  props: {
    visaTypeId: {
      type: [String, Number],
    },
  },
  computed: {
    ...mapGetters(authModule, ["isLoggedIn"]),
  },
  methods: {
    async checkForDraftsApplications() {
      const { data: applicationsDraftResponse } = await Application.all(
        STATUS_ENUM.draft
      );
      const applicationsInDraft = applicationsDraftResponse.data.applications;

      if (applicationsInDraft.length) {
        const draftApplication = applicationsInDraft[0];

        const { isConfirmed } = await this.$swal({
          title: this.$t(`${this.$options.tPfx}.applicationsInDraft`, {
            visaType: draftApplication.visaType.title[this.$i18n.locale],
          }),
          showCancelButton: true,
        });

        if (isConfirmed) {
          this.$router.push({
            name: "Questionary",
            params: { applicationId: draftApplication.id },
          });
        }

        return isConfirmed;
      }

      return false;
    },
    async createDraftApplication() {
      try {
        const { data: applicationResponse } = await Application.create({
          visaTypeId: this.visaTypeId,
        });
        const applicationId = applicationResponse.data.application.id;

        await this.$router.replace({
          name: "Questionary",
          params: { applicationId },
        });
      } catch {
        await this.$router.replace({ name: "SomethingWentWrong" });
        await this.$scrollTo("#navbar", 300);
      }
    },
  },
  async mounted() {
    if (!this.visaTypeId) {
      return await this.$router.replace({
        name: "Apply",
        hash: "#visa-types",
      });
    }

    if (this.isLoggedIn) {
      const continueDraft = await this.checkForDraftsApplications();

      if (!continueDraft) {
        await this.createDraftApplication();
      }
    }
  },
};
</script>

<style>
</style>
