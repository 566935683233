<template>
  <div>
    <label
      v-if="label"
      class="block mb-1 whitespace-nowrap"
      :class="dynamicLabelClass"
      :for="id"
    >
      {{ label }}
    </label>
    <input
      :id="id"
      ref="input"
      v-bind="$attrs"
      class="appearance-none relative block border rounded-md border-gray-400 px-3 py-2 w-full placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 focus:z-10"
      :class="dynamicInputClass"
      :type="type"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur')"
    />
    <div v-if="error" class="leading-none text-yellow-500">{{ error }}</div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `text-input-${uuidv4()}`;
      },
    },
    type: {
      type: String,
      default: "text",
    },
    value: String,
    label: [String, undefined],
    labelClass: [String, Object, undefined],
    inputClass: [String, Object, undefined],
    error: [String, undefined],
    mb: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    dynamicInputClass() {
      if (typeof this.inputClass === "object") {
        return {
          "has-error": this.error,
          [`mb-${this.mb}`]: !this.error,
          ...this.inputClass,
        };
      }

      return `${this.error ? "has-error" : `mb-${this.mb}`} ${this.inputClass}`;
    },
    dynamicLabelClass() {
      if (typeof this.labelClass === "object") {
        return {
          "has-error": this.error,
          ...this.labelClass,
        };
      }

      return `${this.error ? "has-error" : ""} ${this.labelClass}`;
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
  },
};
</script>
