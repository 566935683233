<template>
  <nav id="navbar" class="bg-primary-900 sticky top-0 z-30">
    <tapered-section class="flex flex-wrap items-center">
      <div class="flex w-full md:w-auto p-2 sm:px-0 md:items-center">
        <div class="mr-auto md:mr-0 flex items-center">
          <div class="flex-shrink-0">
            <router-link
                :to="{ name: 'Home' }"
                @click.native="$emit('navigate')"
            >
              <img
                  class="h-8 w-56"
                  src="/img/logos/main.png"
                  alt="Workflow logo"
              />
            </router-link>
          </div>
        </div>

        <div class="ml-auto flex md:hidden">
          <navbar-button
            @click="showMenus = !showMenus"
            :show-collapse="showMenus"
          />
        </div>
      </div>

      <div
        class="w-full md:flex md:flex-row md:items-center md:w-auto md:ml-auto"
        :class="showMenus ? 'flex flex-col' : 'hidden'"
      >
        <user-profile v-if="isLoggedIn" />

        <login-or-register v-else @navigate="showMenus = false" />

        <locale-select class="m-4 md:my-2 md:mr-0 md:ml-4" />
      </div>
    </tapered-section>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import { module as authModule } from "@/store/auth";

import UserProfile from "./Navbar/UserProfile.vue";
import NavbarButton from "./Navbar/NavbarButton.vue";
import TaperedSection from "../../components/TaperedSection.vue";
import LocaleSelect from "./Navbar/LocaleSelect.vue";
import LoginOrRegister from "./Navbar/LoginOrRegister.vue";

export default {
  components: {
    UserProfile,
    NavbarButton,
    TaperedSection,
    LocaleSelect,
    LoginOrRegister,
  },
  data: () => ({
    showMenus: false,
  }),
  computed: {
    ...mapGetters(authModule, ["isLoggedIn"]),
  },
};
</script>
