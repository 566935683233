import client from "./client";

export default {
  list() {
    return client.get('/visa-types');
  },

  questions(visaTypeId) {
    return client.get(`/visa-types/${visaTypeId}/questions`);
  }
};
