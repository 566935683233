<template>
  <div>
    <navbar />

    <e-title />

    <vuex-flash />

    <slot></slot>

    <e-footer />
  </div>
</template>

<script>
import EFooter from "./Default/EFooter.vue";
import Navbar from "./Default/Navbar.vue";
import ETitle from "./Default/ETitle.vue";

export default {
  components: {
    Navbar,
    EFooter,
    ETitle,
  },
};
</script>