<template>
  <div>
    <label
      v-if="label"
      class="block mb-1 whitespace-nowrap"
      :class="dynamicLabelClass"
      :for="id"
    >
      {{ label }}
    </label>
    <t-datepicker
      ref="datepicker"
      :variant="error ? 'error' : undefined"
      :week-start="1"
      :lang="$i18n.locale"
      :date-format="dateFormat"
      :user-format="userFormat"
      :initial-view="initialView"
      :class="dynamicInputClass"
      :clearable="!disabled"
      :disabled="disabled"
      :min-date="minDate"
      :max-date="maxDate"
      :disabled-dates="disabledDates"
      v-model="date"
      @shown="calendarIsShown = true"
      @hidden="calendarIsShown = false"
      @blur="$emit('blur')"
    />
    <div v-if="error" class="leading-none text-yellow-500">{{ error }}</div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    id: {
      type: String,
      default() {
        return `date-input-${uuidv4()}`;
      },
    },
    dateFormat: {
      type: String,
      default: "Y-m-d",
    },
    userFormat: {
      type: String,
      default: "j F Y",
    },
    disabled: [Boolean],
    value: String,
    minDate: [Date, String],
    maxDate: [Date, String],
    disabledDates: [Date, Array, Function, String],
    initialView: [String], // Only day,month,year
    label: [String, undefined],
    labelClass: [String, Object, undefined],
    inputClass: [String, Object, undefined],
    error: [String, undefined],
    mb: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      calendarIsShown: false,
    };
  },
  computed: {
    dynamicInputClass() {
      if (typeof this.inputClass === "object") {
        return {
          "has-error": this.error,
          [`mb-${this.mb}`]: !this.error,
          ...this.inputClass,
        };
      }

      return `${this.error ? "has-error" : `mb-${this.mb}`} ${this.inputClass}`;
    },
    dynamicLabelClass() {
      if (typeof this.labelClass === "object") {
        return {
          "has-error": this.error,
          ...this.labelClass,
        };
      }

      return `${this.error ? "has-error" : ""} ${this.labelClass}`;
    },
    date: {
      get() {
        return this.value;
      },
      set(newDate) {
        if (newDate !== this.value) {
          this.$emit("input", newDate);
        }
      },
    },
  },
  mounted() {
    this.$refs.datepicker.$refs.trigger.$on("clear", () => {
      if (this.calendarIsShown) {
        this.$refs.datepicker.doHide();
      }
      this.$nextTick(() => this.$emit("clear"));
    });
  },
};
</script>

<style>
</style>