//! Must be function to preserve Vue this context
function scrollToResponsive(mobileSelector, duration = 300, webSelector = '#page-title') {
  if (window.innerWidth < 768) {
    this.$scrollTo(mobileSelector, duration);
  } else {
    this.$scrollTo(webSelector, duration);
  }
}

export default scrollToResponsive;
