<template>
  <li class="mx-2 mt-2" :class="{ 'mb-8': isExpanded }">
    <header
      class="group flex items-center border rounded-md cursor-pointer p-4 mb-2"
      :class="{
        'border-gray-400 hover:border-secondary-500': !isExpanded,
        'border-secondary-500 bg-secondary-300': isExpanded,
      }"
      @click="isExpanded = !isExpanded"
    >
      <span class="mr-auto">
        {{ visaType.title[$i18n.locale] }} ({{ visaType.categoryGroup }})
      </span>
      <span class="ml-4">
        <font-awesome-icon
          icon="chevron-down"
          class="group-hover:text-secondary-500"
          :rotation="isExpanded ? 180 : undefined"
        />
      </span>
    </header>
    <div v-show="isExpanded">
      <document-categories :document-categories="visaType.documentCategories" />

      <button
        :disabled="applyButtonIsDisabled"
        @click="apply"
        class="mt-4 block button button-secondary w-full md:w-auto md:mx-auto lg:mx-0 p-8 md:px-24 py-2"
      >
        {{ $t(`${$options.tPfx}.apply`) }}
      </button>
    </div>
  </li>
</template>

<script>
import VueRouter from "vue-router";
import { mapActions, mapGetters } from "vuex";
import { Application } from "@/api";
import { module as authModule } from "@/store/auth";
import { actions as flashActions, module as flashModule } from "@/store/flash";
import DocumentCategories from "./VisaType/DocumentCategories.vue";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

export const translationPrefix = "view.apply.visas.visaTypes.visaType";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      apply: "Apply",
      createApplicationFailedLine1: "Something went wrong!",
      createApplicationFailedLine2: "Please try again later!",
      dearApplicant: 'To all concerned,',
      followInstructions: 'The Republic of Albania has changed its procedures for some Type D visas to foreigners by way of the Unique Permit that is now issued. ' +
          'All foreign citizens must apply via the e-Albania Portal and follow the instructions for the "Application for Residence/Unique Permit"\n' +
          'In the following link: ',
       beforeVisaIsMade: 'This procedure must be completed before an application for a visa can be made.',
      afterConfirmationFromAkpa: 'After the approval of the Unique Permit by AKPA, you will receive an email with the relevant instructions to be followed for the visa application via',
      continueWithApplication: 'and only then can you continue with the rest of the procedures of your application.',
      thankYou: 'Thank You',
    },
    al: {
      apply: "Apliko",
      createApplicationFailedLine1: "Diçka shkoi keq!",
      createApplicationFailedLine2: "Provoni përsëri më vonë!",
      dearApplicant: 'I/E nderuar qytetar,',
      followInstructions: 'Lutemi ndiqni udhëzimet për procedurën e aplikimit paraprakisht "Aplikim për leje qëndrimi/ unike / Residence/ Unique permit" në portalin e-albania',
      beforeVisaIsMade: 'para se të aplikoni për vizë D për motivin e kërkuar.',
      afterConfirmationFromAkpa: 'Vetëm pas miratimit të lejes unike nga AKPA do të merrni emailin me udhëzimet përkatëse për llogarine tuaj në plaformën',
      continueWithApplication: 'dhe mund të vijoni me aplikimin.',
      thankYou: 'Faleminderit!'
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: { DocumentCategories },
  props: {
    visaType: {
      type: Object,
      required: true,
    },
    isElectronic: {
      type: Boolean,
      default: true,
    }
  },
  data: () => ({
    applyButtonIsDisabled: false,
    isExpanded: false,
  }),
  computed: {
    ...mapGetters(authModule, ["isLoggedIn"]),
  },
  methods: {
    ...mapActions(flashModule, {
      dispatchShowFlash: flashActions.show,
    }),
    async showCreateApplicationFailedError() {
      await this.dispatchShowFlash({
        class:
          "sm:block md:inline-block mx-auto border-secondary-500 bg-secondary-200 max-w-2xl xl:max-w-lg md:min-w-2xl",
        iconClass: "hover:text-secondary-500",
        text: [
          `${this.$options.tPfx}.createApplicationFailedLine1`,
          `${this.$options.tPfx}.createApplicationFailedLine2`,
        ],
        textClass: ["avoid-warp", "avoid-wrap"],
      });
    },
    async apply() {
      if (!this.isLoggedIn) {
        try {
          await this.$router.push({
            name: "CreateApplicationRedirecter",
            query: { visaTypeId: this.visaType.id },
          });
        } catch (e) {
          if (!isNavigationFailure(e, NavigationFailureType.redirected)) {
            throw e;
          }
        }

        return;
      }

      this.applyButtonIsDisabled = true;

      try {
        const { data: applicationResponse } = await Application.create({
          visaTypeId: this.visaType.id,
          isElectronic: this.isElectronic
        });

        const displayFerNotification = applicationResponse.data.display_fer_notification

        if (displayFerNotification) {
            await this.$swal({
                html: '<div style="text-align: left; margin-bottom: 10px">' + this.$t(`${this.$options.tPfx}.dearApplicant`) + '</div>' +
                    '<div style="text-align: left; margin-bottom: 10px;"> ' + this.$t(`${this.$options.tPfx}.followInstructions`) +
                    '<br><a style="color: #187cbc" href="https://e-albania.al/eAlbaniaServices/DPPSH/15388/DPPSH_15388_n4_s1_web.aspx?service_code=15388"> (https://e-albania.al/eAlbaniaServices/DPPSH/15388/DPPSH_15388_n4_s1_web.aspx?service_code=15388) </a>' +
                    '' + this.$t(`${this.$options.tPfx}.beforeVisaIsMade`) + '</div>' +
                    '<div style="text-align: left; margin-bottom: 10px; font-weight: bold">' + this.$t(`${this.$options.tPfx}.afterConfirmationFromAkpa`) +
                    ' <a style="color: #187cbc" href="https://e-visa.al/">https://e-visa.al/</a>' + this.$t(`${this.$options.tPfx}.continueWithApplication`) +
                    '</div>' +
                    '<div style="text-align: left">' + this.$t(`${this.$options.tPfx}.thankYou`) + '</div>',
                width: '1000px'
            });
            return;
        }

        const applicationId = applicationResponse.data.application.id;

        this.$router.push({ name: "Questionary", params: { applicationId } });
      } catch {
        await this.showCreateApplicationFailedError();
        await this.$scrollTo("#navbar", 300);
      } finally {
        this.applyButtonIsDisabled = false;
      }
    },
  },
};
</script>

<style>
</style>
