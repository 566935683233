var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-fill-height',{staticClass:"bg-gray-200"},[_c('tapered-section',{staticClass:"flex justify-center py-32 px-4"},[_c('article',{staticClass:"rounded-md border border-gray-400 p-12 max-w-lg lg:max-w-2xl w-full space-y-8 bg-white"},[_c('header',[_c('h2',{staticClass:"mt-6 text-center text-3xl font-extrabold text-gray-900"},[_c('span',{staticClass:"title-line-1"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".registerTitleLine1")))+" ")]),_c('span',{staticClass:"title-line-2"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".registerTitleLine2")))+" ")])])]),_c('form',{staticClass:"mt-8 space-y-6",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('section',[_c('header',{staticClass:"mb-4"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".personalInformation")))+" ")])]),_c('div',{staticClass:"flex flex-wrap"},[_c('text-input',{staticClass:"mb-4 w-full lg:w-1/3 lg:pr-2",attrs:{"label":_vm.$t(((_vm.$options.tPfx) + ".firstName")),"placeholder":_vm.$t(((_vm.$options.tPfx) + ".firstName")),"error":_vm.errors.firstName && _vm.errors.firstName.length
                  ? _vm.errors.firstName[0]
                  : null,"id":"first-name","name":"first-name","required":""},on:{"input":function($event){return _vm.clearErrors('firstName')}},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c('text-input',{staticClass:"mb-4 w-full lg:w-1/3 lg:px-1",attrs:{"label":_vm.$t(((_vm.$options.tPfx) + ".middleName")),"placeholder":_vm.$t(((_vm.$options.tPfx) + ".middleName")),"error":_vm.errors.middleName && _vm.errors.middleName.length
                  ? _vm.errors.middleName[0]
                  : null,"id":"middle-name","name":"middle-name"},on:{"input":function($event){return _vm.clearErrors('middleName')}},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}}),_c('text-input',{staticClass:"mb-4 w-full lg:w-1/3 lg:pl-2",attrs:{"label":_vm.$t(((_vm.$options.tPfx) + ".lastName")),"placeholder":_vm.$t(((_vm.$options.tPfx) + ".lastName")),"error":_vm.errors.lastName && _vm.errors.lastName.length
                  ? _vm.errors.lastName[0]
                  : null,"id":"last-name","name":"last-name","required":""},on:{"input":function($event){return _vm.clearErrors('lastName')}},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}})],1),_c('div',[_c('select-input',{attrs:{"id":"nationality","name":"nationality","type":"text","loading":_vm.isLoadingNationalityOptions,"options":_vm.nationalityOptions,"get-option-label":function (option) { return option.name[_vm.$i18n.locale]; },"error":_vm.errors.nationalityId && _vm.errors.nationalityId.length
                  ? _vm.errors.nationalityId[0]
                  : null,"label":_vm.$t(((_vm.$options.tPfx) + ".nationality")),"placeholder":_vm.$t(((_vm.$options.tPfx) + ".nationality")),"required":""},on:{"input":function($event){return _vm.clearErrors('nationalityId')},"search":_vm.loadNationalities},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
                  var search = ref.search;
return [_c('i18n',{attrs:{"path":((_vm.$options.tPfx) + ".noMatchingNationalities")},scopedSlots:_vm._u([{key:"search",fn:function(){return [_c('em',[_vm._v("\""+_vm._s(search)+"\"")])]},proxy:true}],null,true)})]}}]),model:{value:(_vm.form.nationality),callback:function ($$v) {_vm.$set(_vm.form, "nationality", $$v)},expression:"form.nationality"}})],1)]),_c('section',[_c('header',{staticClass:"mb-4"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".loginInformation")))+" ")])]),_c('text-input',{staticClass:"mb-4 w-full",attrs:{"label":_vm.$t(((_vm.$options.tPfx) + ".email")),"placeholder":_vm.$t(((_vm.$options.tPfx) + ".email")),"error":_vm.errors.email && _vm.errors.email.length ? _vm.errors.email[0] : null,"id":"email","name":"email","type":"email","required":""},on:{"input":function($event){return _vm.clearErrors('email')}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('div',{staticClass:"flex flex-wrap"},[_c('text-input',{staticClass:"mb-4 w-full lg:w-1/2 lg:pr-2",attrs:{"label":_vm.$t(((_vm.$options.tPfx) + ".password")),"placeholder":_vm.$t(((_vm.$options.tPfx) + ".password")),"error":_vm.errors.password && _vm.errors.password.length
                  ? _vm.errors.password[0]
                  : null,"id":"password","name":"password","type":"password","required":""},on:{"input":function($event){return _vm.clearErrors('password')}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('text-input',{staticClass:"mb-4 w-full lg:w-1/2 lg:pl-2",attrs:{"label":_vm.$t(((_vm.$options.tPfx) + ".passwordConfirmation")),"placeholder":_vm.$t(((_vm.$options.tPfx) + ".passwordConfirmation")),"error":_vm.errors.passwordConfirmation &&
                _vm.errors.passwordConfirmation.length
                  ? _vm.errors.passwordConfirmation[0]
                  : null,"id":"password-confirmation","name":"password-confirmation","type":"password","required":""},on:{"input":function($event){return _vm.clearErrors('passwordConfirmation')}},model:{value:(_vm.form.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.form, "passwordConfirmation", $$v)},expression:"form.passwordConfirmation"}})],1)],1),_c('section',[_c('div',{staticClass:"flex items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.allowSubmit),expression:"form.allowSubmit"}],staticClass:"mr-2 md:mr-4 text-secondary-600 focus:ring-secondary-500 border-gray-400 rounded",attrs:{"id":"allow-submit","name":"allow-submit","type":"checkbox","required":""},domProps:{"checked":Array.isArray(_vm.form.allowSubmit)?_vm._i(_vm.form.allowSubmit,null)>-1:(_vm.form.allowSubmit)},on:{"input":function($event){return _vm.clearErrors('allowSubmit')},"change":function($event){var $$a=_vm.form.allowSubmit,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "allowSubmit", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "allowSubmit", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "allowSubmit", $$c)}}}}),_c('label',{staticClass:"ml-2 block text-sm text-gray-900",attrs:{"for":"allow-submit"}},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".allowSubmit")))+" ")])]),_c('div',{staticClass:"mt-6"},[_c('button',{staticClass:"w-full button button-secondary"},[_vm._v(" "+_vm._s(_vm.$t(((_vm.$options.tPfx) + ".register")))+" ")])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }