import querystring from 'querystring';
import client from "./client";

export default {
	list(qs) {
		let query = '';

		if (qs) {
			query = '?' + querystring.stringify({ qs });
		}

		return client.get(`/countries${query}`);
	}
};
