const QUESTION_CODE_ENUM = {
  countryOfResidence: 1101,
  consularRepresentation: 1102,
  dateOfBirth: 1106,
  currentNationality: 1109,
  residencePermit: 1115,
  passportNumber: 2102,
  passportDateOfIssue: 2104,
  passportDateOfExpiry: 2105,
  dateOfArrival: 3103,
  dateOfDeparture: 3104,
  durationOfStay: 3106,
  visaCategoryGroup: 3109,
  host: 5099,
  hostNameIfCompany: 5100,
  hostName: 5101,
  hostSurname: 5102,
  hostBirthday: 5103,
  hostSex: 5104,
  hostPhone: 5105,
  hostEmail: 5106,
  hostFullAddress: 5107,
  hostPersonalNumber: 5108,
  hostExpensesPaidBy: 5109,
  hostExpensesPaidByDetails: 5110,
  hostMeansOfSupport: 5111,
  hostInsuranceValidUntil: 5112,
  applicantEmail: 6103,
  visaTypeAHostQuestionCode: 10009,
  invitationFromHostInstitution: 10015,
  invitationFromHostInstitutionWhenNecessary: 10043,
  invitationFromTheScientificSubject: 10062,
}

export default QUESTION_CODE_ENUM;
