<template>
  <div>
    <label
      v-if="label"
      class="block mb-1 whitespace-nowrap"
      :class="dynamicLabelClass"
      :for="id"
    >
      {{ label }}
    </label>
    <div
      class="mb-2 text-xs italic"
      :class="{ 'text-yellow-500': !!error }"
      v-if="showDescription && description"
    >
      <div v-html="description"></div>
    </div>
    <strong class="download-information-font" v-if="hasInvitationDocument"> {{ $t(`${$options.tPfx}.invitationFormatLabel`) }} <a href="/documents/format-ftese.docx"> <u>  {{ $t(`${$options.tPfx}.here`) }} </u></a></strong>
    <div
      class="relative flex border rounded-md w-full h-20 focus:outline-none focus:ring-secondary-500 focus:border-secondary-500 focus:z-10 sm:text-sm"
      :class="dynamicInputClass"
    >
      <file-upload-hint
        v-if="showUploadHint"
        :hint="uploadHint"
        :is-dragging-files="isDraggingFiles"
        :has-error="!!error"
      />
      <div class="flex-1 md:flex-none md:w-11/12">
        <file-preview
          :file="fileToRender"
          :origin="origin"
          :upload-progress="progress"
          :file-name="fileName"
          :headers="{ headers }"
          class="h-full"
        />
      </div>
      <div class="flex justify-center items-center w-8 md:w-1/12">
        <button
          :disabled="disabled"
          v-if="fileToRender && !fileToRender.inProgress"
          @click="deleteFile"
        >
          <font-awesome-icon
            icon="trash"
            size="lg"
            class="text-gray-400 cursor-pointer"
            :class="
              disabled
                ? 'hover:text-gray-500 cursor-not-allowed'
                : 'hover:text-secondary-500'
            "
          />
        </button>
      </div>
      <input
        v-bind="$attrs"
        :id="id"
        :accept="accept"
        :disabled="inputIsDisabled"
        :value="null"
        @change="onFileInput"
        @dragenter.prevent="isDraggingFiles = true"
        @dragleave="isDraggingFiles = false"
        @dragover.prevent
        @drop.prevent="onFileDrop"
        ref="input"
        type="file"
        class="absolute top-0 right-0 bottom-0 left-0 block w-full opacity-0 appearance-none cursor-pointer"
        :class="{
          'z-10': !inputIsDisabled,
          '-z-1': inputIsDisabled,
        }"
      />
    </div>
    <div v-if="error" class="leading-none text-yellow-500">{{ error }}</div>

    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-auto my-6 mx-auto max-w-3xl">
        <!--content-->
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <!--body-->
          <div class="relative p-6 flex-auto">
            <cropper
              ref="cropper"
              class="cropper"
              :src="src"
              transitions
              :stencil-props="{ aspectRatio: 4 / 5 }"
              @change="handleCrop"
            />
            <div class="text-right mt-1">
              <button class="mx-2 text-xl" @click="zoom(2)">
                <font-awesome-icon
                  icon="search-plus"
                  class="group-hover:text-secondary-500"
                />
              </button>
              <button class="mx-2 text-xl" @click="zoom(0.5)">
                <font-awesome-icon
                  icon="search-minus"
                  class="group-hover:text-secondary-500"
                />
              </button>
            </div>
          </div>
          <!--footer-->
          <div
            class="flex items-center justify-end p-6 border-t border-solid border-gray-400 rounded-b"
          >
            <button
              class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style="transition: all 0.15s ease"
              @click="resetCropperData"
            >
              {{ close }}
            </button>
            <button
              class="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
              type="button"
              style="transition: all 0.15s ease"
              @click="confirmCrop"
            >
              {{ confirm }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black" />
  </div>
</template>

<script>
import axios from "axios";
import mime from "mime-types";
import { v4 as uuidv4 } from "uuid";
import FilePreview from "./FileInput/FilePreview.vue";
import FileUploadHint from "./FileInput/FileUploadHint.vue";
import sizeToHumanReadable from "./FileInput/helpers/sizeToHumanReadable";

export const translationPrefix = "components.fileInput";

export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      invitationFormatLabel: "THE REQUIRED INVITATION LETTER FORMAT CAN BE DOWNLOADED",
      here: "HERE",
    },
    al: {
      invitationFormatLabel: "FORMATI I KERKUAR I FTESES MUND TE SHKARKOHET",
      here: "KETU",
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: { FilePreview, FileUploadHint },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `file-input-${uuidv4()}`;
      },
    },
    hasInvitationDocument: [Boolean],
    uploadUrl: String,
    deleteUrl: String,
    deletable: [Boolean],
    headers: Object,
    additionalPostData: {
      type: Object,
      default: () => ({}),
    },
    origin: {
      type: String,
      required: true,
    },
    fileName: {
      type: String,
      default: "file",
    },
    reduceResponse: {
      type: Function,
      default: (response) => response.data,
    },
    accept: {
      type: String,
      default: "*",
    },
    maxSize: {
      type: Number,
      default: 4194304,
    },
    uploadHint: {
      type: String,
      default: "Select or Drag file to upload",
    },
    close: {
      type: String,
      default: "Close",
    },
    confirm: {
      type: String,
      default: "Confirm",
    },
    cropImage: {
      type: Boolean,
      default: false,
    },
    showDescription: Boolean,
    description: String,
    disabled: Boolean,
    value: [Object],
    label: [String, undefined],
    labelClass: [String, Object, undefined],
    inputClass: [String, Object, undefined],
    error: [String, undefined],
    mb: {
      type: Number,
      default: 4,
    },
  },
  data: () => ({
    isDraggingFiles: false,
    isDeleting: false,
    progress: 100,
    fileInProgress: null,
    showModal: false,
    src: "",
    fileToUpload: {},
  }),
  computed: {
    dynamicInputClass() {
      if (typeof this.inputClass === "object") {
        return {
          "has-error": this.error,
          "border-gray-400": !this.isDraggingFiles,
          "border-secondary-500": this.isDraggingFiles,
          [`mb-${this.mb}`]: !this.error,
          ...this.inputClass,
        };
      }

      const classesFromError = this.error ? "has-error" : `mb-${this.mb}`;
      const classesFromDragging = this.isDraggingFiles
        ? "border-secondary-500"
        : "border-gray-400";

      return `${classesFromDragging} ${classesFromError} ${this.inputClass}`;
    },
    dynamicLabelClass() {
      if (typeof this.labelClass === "object") {
        return {
          "has-error": this.error,
          ...this.labelClass,
        };
      }

      return `${this.error ? "has-error" : ""} ${this.labelClass}`;
    },
    axiosConfig() {
      return {
        headers: this.headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          this.progress = percentCompleted;
        },
      };
    },
    fileToRender() {
      return this.fileInProgress || this.value;
    },
    inputIsDisabled() {
      return this.disabled || this.fileToRender;
    },
    showUploadHint() {
      return !this.fileToRender;
    },
  },
  methods: {
    validateFileSize(file) {
      if (file.size > this.maxSize) {
        this.$emit("file-size-error", {
          size: file.size,
          maxSize: this.maxSize,
          maxSizeReadable: sizeToHumanReadable(this.maxSize),
        });
        return false;
      }

      return true;
    },
    validateType(file) {
      const mimeType = file.type;

      if (this.accept === "*" || this.accept.includes(mimeType)) {
        return true;
      }

      const parts = this.accept.replace(/ /g, "").split(",");
      const extensions = parts.map((part) => mime.extensions[part]);
      const uniqueEtensions = new Set(extensions);
      const acceptReadable = [...uniqueEtensions].join(",");

      this.$emit("file-type-error", {
        type: file.type,
        accept: this.accept,
        acceptReadable,
      });

      return false;
    },
    validate(file) {
      if (!this.validateFileSize(file)) {
        return false;
      }

      if (!this.validateType(file)) {
        return false;
      }

      return true;
    },
    async uploadFile(file) {
      const formData = new FormData();

      const fileUrl = URL.createObjectURL(file);
      file.path = fileUrl;
      file.inProgress = true;

      formData.append(this.fileName, file);

      for (const [key, value] of Object.entries(this.additionalPostData)) {
        formData.append(key, value);
      }

      try {
        this.$set(this, "fileInProgress", file);

        const response = await axios.post(
          this.uploadUrl,
          formData,
          this.axiosConfig
        );

        const toEmit = this.reduceResponse(response);

        this.$emit("input", toEmit);
      } catch (e) {
        this.$emit("upload-error");
      } finally {
        this.fileInProgress = null;
      }
    },
    async deleteFile() {
      if (!this.deletable || this.isDeleting) {
        return;
      }
      this.isDeleting = true;

      try {
        await axios.delete(this.deleteUrl, this.axiosConfig);

        this.$emit("deleted");
      } catch {
        this.$emit("delete-error");
      } finally {
        this.isDeleting = false;
      }
    },
    async onFileInput() {
      const file = this.$refs.input.files[0];

      if (!this.validate(file)) {
        return;
      }

      if (this.cropImage) {
        this.cropFileModal(file);
      } else {
        this.uploadFile(file);
      }
    },
    onFileDrop(event) {
      this.isDraggingFiles = false;

      const dt = event.dataTransfer;
      const file = dt.files[0];

      if (!this.validate(file)) {
        return;
      }

      if (this.cropImage) {
        this.cropFileModal(file);
      } else {
        this.uploadFile(file);
      }
    },
    cropFileModal(file) {
      this.src = URL.createObjectURL(file);
      this.showModal = true;
    },
    handleCrop({ canvas }) {
      fetch(canvas.toDataURL("image/png"))
        .then((res) => res.blob())
        .then((blob) => {
          this.fileToUpload = new File([blob], "File name", {
            type: "image/png",
          });
        });
    },
    zoom(value) {
      this.$refs.cropper.zoom(value);
    },
    confirmCrop() {
      this.uploadFile(this.fileToUpload);
      this.resetCropperData();
    },
    resetCropperData() {
      this.showModal = false;
      this.src = "";
      this.fileToUpload = {};
    },
  },
};
</script>

<style>
.cropper {
  height: 550px;
  max-width: 550px;
  background: lightgrey;
}
.download-information-font {
  font-size: 14px;
}
</style>
