<template>
  <div class="flex border rounded-md border-secondary-500">
    <button
      @click="changeToEn"
      class="rounded-l-md flex-1 font-bold md:px-2 md:font-normal"
      :class="
        isEn
          ? 'text-white bg-secondary-500'
          : ' text-secondary-500 hover:text-white hover:bg-secondary-900'
      "
    >
      EN
    </button>
    <button
      @click="changeToAl"
      class="rounded-r-md flex-1 font-bold md:px-2 md:font-normal"
      :class="
        isAl
          ? 'text-white bg-secondary-500'
          : ' text-secondary-500 hover:text-white hover:bg-secondary-900'
      "
    >
      AL
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    changeToEn() {
      this.$i18n.locale = "en";
      localStorage.setItem("locale", "en");
    },
    changeToAl() {
      this.$i18n.locale = "al";
      localStorage.setItem("locale", "al");
    },
  },
  computed: {
    isEn() {
      return this.$i18n.locale === "en";
    },
    isAl() {
      return this.$i18n.locale === "al";
    },
  },
};
</script>