import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en';
import al from './al';

Vue.use(VueI18n)

let locale = localStorage.getItem('locale');

if (!locale) {
  locale = 'en';
  localStorage.setItem('locale', locale);
}

const messages = {
  al,
  en,
}

const i18n = new VueI18n({
  locale,
  messages,
});

export default i18n;
