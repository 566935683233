<template>
  <default-fill-height>
    <tapered-section class="flex flex-wrap p-12">
      <header class="mb-12 w-full">
        <h1 class="text-center text-lg md:text-5xl">
          {{ $t(`${$options.tPfx}.notFound`) }}
        </h1>
        <h2 class="text-center text-base md:text-2xl text-secondary-500">
          {{ $t(`${$options.tPfx}.doesNotExist`) }}
        </h2>
      </header>
      <div class="mb-12 flex w-full">
        <div class="max-w-3xl mx-auto flex-1">
          <not-found-error-svg />
        </div>
      </div>
    </tapered-section>
  </default-fill-height>
</template>

<script>
import NotFoundErrorSvg from "@a/img/not-found-error.svg";

export const translationPrefix = "view.notFound";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      notFound: "Not found",
      doesNotExist: "The page you requested doesn't seem to exist...",
    },
    al: {
      notFound: "Nuk u gjet",
      doesNotExist: "Faqja që po kërkoni nuk ekziston...",
    },
  },
};

export default {
  tPfx: translationPrefix,
  components: {
    NotFoundErrorSvg,
  },
};
</script>