<template>
  <main>
    <div
      class="px-4 py-6 sm:px-0 mt-10 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
    >
      <div class="sm:text-center lg:text-left">
        <h2
          class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl"
        >
          This demo is using
          <br class="xl:hidden" />
          <a
            target="_blank"
            class="text-indigo-600 underline hover:text-indigo-500"
            href="https://tailwindcss.com"
          >
            Tailwind CSS
          </a>
        </h2>
        <p
          class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
        >
          All the code present here is part of a free sample from
          <a
            href="https://tailwindui.com"
            class="text-indigo-600 underline hover:text-indigo-500"
          >
            Tailwind UI
          </a>
          but, you don't need it. If you want to add
          <b>Tailwind UI </b>&#32; check the instructions on
          <a
            class="text-indigo-600 underline hover:text-indigo-500"
            href="https://github.com/posva/vite-tailwind-starter/tree/master#vite--tailwind-css-starter"
            ><code>README</code></a
          >. It only takes two steps to add it 🙂.
        </p>
        <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div class="rounded-md shadow">
            <a
              href="#"
              class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-secondary-500 hover:bg-primary-900 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
            >
              Get started
            </a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {};
</script>
