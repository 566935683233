<template>
  <article class="border rounded-md border-gray-400 p-4 md:p-8 bg-white">
    <section
      v-for="(
        [section, questions], sectionIndex
      ) of questionsBySection.entries()"
      :key="section"
      class="flex flex-wrap"
    >
      <header class="w-full mb-4">
        <h2 class="text-center">{{ JSON.parse(section)[$i18n.locale] }}</h2>
      </header>
      <question
        v-for="(question, questionIndex) of questions"
        :application-status-code="applicationStatusCode"
        :question-number="getQuestionNumber(sectionIndex, questionIndex)"
        :key="question.code"
        :question="question"
        :disabled="disabled"
        :show-comment="showComments"
        @answered="$emit('question-answered', $event)"
        @synched="$emit('question-synched', $event)"
        class="w-full md:w-1/2"
      />
    </section>
  </article>
</template>

<script>
import Question from "./Questions/Question.vue";

export default {
  components: { Question },
  props: {
    applicationStatusCode: {
      type: [String],
    },
    questions: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    showComments: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    questionsBySection() {
      const questionSectionMap = new Map();

      for (const question of this.questions) {
        const section = JSON.stringify(question.title);
        const sectionExists = questionSectionMap.has(section);

        if (sectionExists) {
          questionSectionMap.get(section).push(question);
          continue;
        }

        questionSectionMap.set(section, [question]);
      }

      return questionSectionMap;
    },
  },
  methods: {
    getQuestionNumber(sectionIndex, questionIndex) {
      return `${sectionIndex + 1}.${questionIndex + 1}`;
    },
  },
};
</script>