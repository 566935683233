<template>
  <div class="w-full pt-4 pb-3 border-t border-gray-700">
    <div class="flex items-center px-5">
      <div>
        <img
          v-if="hasThumbnail"
          class="h-10 w-10 rounded-full"
          :src="user.photoThumbUrl"
          alt="thumbnail"
        />
        <default-thumbnail v-else class="text-gray-400" />
      </div>
      <div class="ml-3">
        <div class="text-base font-medium leading-none text-white">
          {{ fullName }}
        </div>
        <div class="mt-1 text-sm font-medium leading-none text-gray-400">
          {{ email }}
        </div>
      </div>
    </div>
    <div class="mt-3 px-2">
      <router-link
        :to="{ name: 'Profile' }"
        class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
      >
        {{ $t(`${$options.tPfx}.profile`) }}
      </router-link>
      <router-link
        :to="{ name: 'Applications' }"
        class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
      >
        {{ $t(`${$options.tPfx}.applications`) }}
      </router-link>
      <a
        href="#"
        class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
        @click.prevent="$emit('sign-out')"
      >
        {{ $t(`${$options.tPfx}.signOut`) }}
      </a>
    </div>
  </div>
</template>

<script>
import DefaultThumbnail from "./DefaultThumbnail.vue";

export const translationPrefix =
  "layout.default.navbar.userProfile.inlineUserProfile";

export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      profile: "My profile",
      applications: "Applications",
      signOut: "Sign out",
    },
    al: {
      profile: "Profili im",
      applications: "Aplikimet",
      signOut: "Dil",
    },
  },
};

export default {
  components: { DefaultThumbnail },
  tPfx: translationPrefix,
  props: {
    user: {
      type: Object,
    },
  },
  computed: {
    hasThumbnail() {
      return this.user && this.user.photoThumbUrl;
    },
    email() {
      if (!this.user) {
        return this.$t(`${this.$options.tPfx}.loading`);
      }

      return this.user.email;
    },
    fullName() {
      if (!this.user) {
        return this.$t(`${this.$options.tPfx}.loading`);
      }

      if (this.user.middleName) {
        return `${this.user.firstName} ${this.user.middleName} ${this.user.lastName}`;
      }

      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
};
</script>
