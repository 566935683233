function makeQuestionsNotRequired(questions)
{
    questions.forEach((question) => {
        question.isRequired = 0
        question.question.al = question.question.al.replace(" *" , "")
        question.question.en = question.question.en.replace(" *" , "")
    })
}

function makeQuestionsRequired(questions)
{
    questions.forEach((question) => {
        question.isRequired = 1
        question.question.al += ' *'
        question.question.en += ' *'
    })
}

export default {
    makeQuestionsNotRequired,
    makeQuestionsRequired
}