<template functional>
  <div
    class="xl:mx-12 my-4 border rounded-md border-gray-400 w-full p-8 bg-white"
  >
    <header class="mb-4">
      <h2>
        {{ parent.$t(`${$options.tPfx}.firstPhase`) }}
      </h2>
    </header>
    <ol class="list-outside list-decimal pl-6">
        <i18n :path="`${$options.tPfx}.doneOnline`">
          <template #outsideOfCountry>
            <strong class="text-secondary-500">
              {{ parent.$t(`${$options.tPfx}.outsideOfCountry`) }}
            </strong>
          </template>
        </i18n>
        <header class="mb-2">
          {{ parent.$t(`${$options.tPfx}.verificationByTheConsul`) }}
        </header>
        <ol class="list-outside list-lower-alpha pl-6">
          <li class="mb-1">
            <i18n :path="`${$options.tPfx}.approvedByTheConsul`">
              <template #itIsRegular>
                <strong>
                  {{ parent.$t(`${$options.tPfx}.itIsRegular`) }}
                </strong>
              </template>
            </i18n>
          </li>
          <li class="mb-1">
            <i18n :path="`${$options.tPfx}.hasIrregularties`">
              <template #irregularties>
                <strong>
                  {{ parent.$t(`${$options.tPfx}.irregularties`) }}
                </strong>
              </template>
            </i18n>
          </li>
          <li class="mb-1">
            <i18n :path="`${$options.tPfx}.rejectedByTheConsul`">
              <template #doesNotFulfillTheNecessaryElements>
                <strong>
                  {{ parent.$t(`${$options.tPfx}.doesNotFulfillTheNecessaryElements`) }}
                </strong>
              </template>
            </i18n>
          </li>
        </ol>
    </ol>
  </div>
</template>

<script>
export const translationPrefix = "view.apply.processPhases.firstPhase";
export const translations = {
  pfx: translationPrefix,
  t: {
    en: {
      firstPhase: "First phase",
      doneOnline: "Visa application process is only accepted if it is completed online, and you must ensure that {outsideOfCountry} after payment of the visa fee.",
      outsideOfCountry:
          "You are outside of the territory of the Republic of Albania ",
      verificationByTheConsul:
        "The application is pre-verified by the consul including the application form and uploaded documents and if the application:",
      approvedByTheConsul:
        "{itIsRegular} a notification is sent that the application has been accepted and a visa fee is requested, according to the cost and type of visa requested;",
      approved: "application has been accepted",
      itIsRegular: "is correct,",
      hasIrregularties:
        "{irregularties} it is turned back to the applicant requesting the correct documents to be uploaded according to the instructions sent;",
      irregularties: "has irregularities,",
      rejectedByTheConsul:
        "{doesNotFulfillTheNecessaryElements} for the required visa, the application is rejected according to the legal framework.",
      doesNotFulfillTheNecessaryElements:
          "does not fulfil any of the criteria necessary",
      rejected: "has been refused",
    },
    al: {
      firstPhase: "Faza e parë",
      doneOnline: "Procesi i aplikimit për vizë kryhet vetëm online dhe duke u siguruar që aplikanti është {outsideOfCountry} në momentin e pagesës për tarifën e vizës.",
      outsideOfCountry:
        "jashtë territorit të Republikës së Shqipërisë ",
      verificationByTheConsul:
        "Aplikimi verifikohet paraprakisht nga konsulli duke përfshirë formularin e aplikimit dhe  dokumentet e ngarkuara dhe nëse aplikimi:",
      approvedByTheConsul:
        "{itIsRegular},  i  dërgon  aplikuesit  statusin pranuar  aplikimi dhe  i  përcakton  tarifën  e  pagesës  së vizës, sipas tarifave të përcaktuara",
      itIsRegular: "është  i  rregullt",
      hasIrregularties:
        "{irregularties}, i rikthen shtetasit aplikimin për plotësimin e dokumenteve apo të dhënave sipas udhëzimeve",
      irregularties: "ka parregullsi",
      rejectedByTheConsul:
        "{doesNotFulfillTheNecessaryElements}, të nevojshëm për vizën e kërkuar, i refuzohet aplikimi sipas përcaktimit ligjor",
      doesNotFulfillTheNecessaryElements:
          "nuk përmbush asnjë kriter",
    },
  },
};

export default {
  tPfx: translationPrefix,
};
</script>

<style>
</style>