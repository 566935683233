<template>
  <div>
    <label
      v-if="label"
      class="block mb-1 whitespace-nowrap"
      :class="dynamicLabelClass"
      :for="id"
    >
      {{ label }}
    </label>
    <v-select
      v-bind="$attrs"
      :value="value"
      @input="onInput"
      @search="$emit('search', arguments[0], arguments[1])"
      @close="$emit('close')"
      :id="id"
      :loading="loading"
      :options="options"
      :label="labelTarget"
      :get-option-label="getOptionLabel"
      :get-option-key="getOptionKey"
      :reduce="reduce"
      :class="{ 'has-error': error, 'mb-4': !error }"
      ref="input"
    >
      <template #no-options="{ search, searching, loading }">
        <slot
          name="no-options"
          :search="search"
          :searching="searching"
          :loading="loading"
        />
      </template>
    </v-select>
    <div v-if="error" class="leading-none text-yellow-500">{{ error }}</div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import VSelect from "vue-select";

import "vue-select/dist/vue-select.css";

export default {
  components: {
    VSelect,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default() {
        return `select-input-${uuidv4()}`;
      },
    },
    value: [String, Number, Boolean, Object],
    reduce: {
      type: Function,
      default: (option) => option,
    },
    loading: Boolean,
    options: [Array],
    label: String,
    labelClass: [String, Object, undefined],
    labelTarget: {
      type: String,
      default: "label",
    },
    getOptionLabel: {
      type: Function,
      default: (option) => option,
    },
    getOptionKey: {
      type: Function,
      default(option) {
        if (typeof option === "object" && option.id) {
          return option.id;
        } else {
          try {
            return JSON.stringify(option);
          } catch (e) {
            console.warn(e.getMessage());
            return null;
          }
        }
      },
    },
    error: String,
  },
  computed: {
    dynamicLabelClass() {
      if (typeof this.labelClass === "object") {
        return {
          "has-error": this.error,
          ...this.labelClass,
        };
      }

      return `${this.error ? "has-error" : ""} ${this.labelClass}`;
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    onInput(value) {
      this.$emit("input", value);

      if (!value) {
        this.$emit("clear");
      }
    },
  },
};
</script>

<style>
.vs__search,
.vs__search:focus,
.vs__selected,
.vs__dropdown-option,
.vs__actions {
  @apply m-0;
  @apply px-3;
  @apply py-2;
}

.vs__search,
.vs__search:focus,
.vs__selected {
  @apply border-none;
  @apply text-base;
}

.vs__search::placeholder {
  @apply text-gray-500;
}

.vs__spinner,
.vs__spinner::after {
  width: 1.35rem;
  height: 1.35rem;
}

.vs__selected-options {
  @apply p-0;
}
.vs__dropdown-option--highlight {
  @apply bg-secondary-300;
  @apply text-primary-900;
}

.vs__dropdown-menu {
  @apply rounded-t-none;
  @apply rounded-b-md;
  @apply border-t;
  @apply border-gray-400;
  @apply shadow-none;
  @apply text-base;
}

.vs__dropdown-toggle {
  @apply p-0;
  @apply bg-white;
}

.vs__search::placeholder,
.vs__dropdown-toggle {
  @apply border-gray-400;
  @apply rounded-md;
}

.vs--open .vs__dropdown-toggle {
  @apply border-secondary-500;
  border-bottom-color: transparent;
}
.vs--open .vs__dropdown-menu {
  @apply border-secondary-500;
}

.vs__open-indicator,
.vs__clear {
  @apply text-gray-300;
  @apply cursor-pointer;
  @apply fill-current;
}

.vs__clear:hover,
.vs__open-indicator:hover {
  @apply text-gray-500;
}

.vs--open .vs__open-indicator,
.vs--open .vs__clear {
  @apply text-secondary-500;
}

.v-select.has-error .vs__dropdown-toggle,
.v-select.has-error.vs--open .vs__dropdown-toggle,
.v-select.has-error .vs__dropdown-menu,
.v-select.has-error.vs--open .vs__dropdown-menu {
  @apply border-yellow-500;
}

.v-select.has-error .vs__open-indicator,
.v-select.has-error.vs--open .vs__open-indicator,
.v-select.has-error .vs__clear,
.v-select.has-error.vs--open .vs__clear {
  @apply text-yellow-500;
}
</style>