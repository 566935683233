import mime from 'mime-types';

export const getFileNameFromHeaders = (headers) => {
  const extension = mime.extensions[headers['content-type']][0];
  const contentDispositionHeader = headers["content-disposition"]

  let fileName = `file.${extension}`;

  if (contentDispositionHeader) {
    const fileNameMatch = contentDispositionHeader.match(/filename="(.+)"/);
    if (fileNameMatch.length === 2)
      fileName = fileNameMatch[1];
  }

  return `${fileName}`;
}

const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);
  link.click();

  window.URL.revokeObjectURL(url);
}

export default downloadFile;
